import React from "react";
import {
  Modal,
  ModalBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  ModalHeader,
  Input,
  InputGroup,
} from "reactstrap";
import * as FeatherIcon from "react-feather";
import classnames from "classnames";
import Validation from "./../../../validation/validation";
import Cookies from "./../../../services/cookies";
import Loader from "../../load/loader";
import { withRouter } from "react-router";
import {
  getProfileData,
  updateProfileData,
  updatePassword,
  deleteAccount,
} from "./../../../Store/Actions/EditProfile/editProfileFetch";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  AccountDeletionToast,
  SuccessToast,
  PasswordUpdateToast,
} from "../../Ui_Components/Toasts";
import API from "../../../services/api_endpoint";
import axios from "axios";
class EditProfileModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      firstname: "",
      lastname: "",
      username: "",
      phone: "",
      email: "",
      fax: "",
      address: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
      company_name: "",
      new_password: "",
      confirm_password: "",
      delete: "",
      error_first_name: null,
      error_last_name: null,
      error_new_password: null,
      error_confirm_password: null,
      loading: false,
      ready: false,
      userdata: "",
    };
    this.validation = new Validation();
    this.cookies = new Cookies();
  }

  componentDidMount() {
    this.apiProfileGet();
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  };

  inputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setStateData(name, value);
  };

  setData = (data) => {
    this.setStateData(Object.keys(data)[0], data[Object.keys(data)[0]]);
    return data[Object.keys(data)[0]];
  };

  setStateData = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  validate = (event) => {
    let name = event.target.name;
    const Enum = Object.freeze({
      firstname: 1,
      lastname: 2,
      new_password: 3,
      confirm_password: 4,
    });
    const value = Enum[name];

    switch (value) {
      case 1:
        this.setData(this.validation.validateFirstName(this.state.firstname));
        break;
      case 2:
        this.setData(this.validation.validateLastName(this.state.lastname));
        break;
      case 3:
        this.setData(
          this.validation.validateNewPassword(this.state.new_password)
        );
        break;
      case 4:
        this.setData(
          this.validation.validateConfirmPassword(
            this.state.new_password,
            this.state.confirm_password
          )
        );
        break;
      default:
        break;
    }
  };

  submit = (event) => {
    event.preventDefault();

    let firstname = this.setData(
      this.validation.validateFirstName(this.state.firstname)
    );
    let lastname = this.setData(
      this.validation.validateLastName(this.state.lastname)
    );

    if (firstname === null && lastname === null) {
      this.setState(
        {
          loading: true,
        },
        () => this.apiProfileUpdate(event)
      );
    } else {
      return;
    }
  };

  apiProfileUpdate = (event) => {
    event.preventDefault();
    this.props
      .updateProfileData(this.state.lastname, this.state.firstname)
      .then((response) => {
        if (this.props.userdata?.updatedData?.payload?.success === "true") {
          // SuccessToast("Data Updated");
          this.setState({
            firstname: this.props.userdata.updatedData.payload.data.firstname,
            lastname: this.props.userdata.updatedData.payload.data.lastname,
            loading: false,
          });
          const updateDetails = () => {
            console.log("userId Check--->>>", this.props.userdata.updatedData.payload.data.id);
            axios
              .put(`${API.updateDetails}?auth_key=${this.cookies.getItem("KEY")}`, {
                userId: this.props.userdata.UserData.payload.data[0].id,
                firstName: this.props.userdata.updatedData.payload.data.firstname,
                middleName: '',
                lastName: this.props.userdata.updatedData.payload.data.lastname,
                emailAddress: this.props.userdata.updatedData.payload.data.email,
                mobileNumber: this.props.userdata.updatedData.payload.data.phone,
                deviceId: "",
                deviceType: "web",
                deviceToken: this.cookies.getItem("fcm_token")
              })
              .then((response) => {
                const { data } = response;
                console.log("updateDetailsrrrrrrr =========>>>", data);
                this.cookies.removeItem("forupdatedetails")
                if(data.status === true){
                  SuccessToast("Data Updated");
                }
                
              })
              .catch((err) => {
                console.log(err);
              });
          }
          updateDetails()
        } else {
          alert(this.props.userdata?.updatedData?.payload?.data?.result);
          this.setState({
            loading: false,
          });
        }
      });
  };

  apiProfileGet = () => {
    this.props
      .getProfileData()
      .then((response) => {
        if (this.props.userdata.UserData.payload.data[0]) {
          this.setState({
            firstname: this.props.userdata.UserData.payload.data[0].firstname,
            lastname: this.props.userdata.UserData.payload.data[0].lastname,
            username: this.props.userdata.UserData.payload.data[0].username,
            phone: this.props.userdata.UserData.payload.data[0].phone,
            email: this.props.userdata.UserData.payload.data[0].email,
            fax: this.props.userdata.UserData.payload.data[0].fax,
            address: this.props.userdata.UserData.payload.data[0].address,
            city: this.props.userdata.UserData.payload.data[0].city,
            state: this.props.userdata.UserData.payload.data[0].state,
            country: this.props.userdata.UserData.payload.data[0].country,
            zipcode: this.props.userdata.UserData.payload.data[0].zipcode,
            ready: true,
          });
        } else {
          alert(response.data.result);
          this.cookies.logout();
          window.location.reload();
        }
      })
      .catch((error) => {
        if (error && error.response) {
          if (error.response.status === 400) {
            alert(error.response.data.message);
          } else if (error.response.status === 401) {
            alert(error.response.data.message);
          } else if (error.response.status === 404) {
            alert(error.response.data.message);
          } else if (error.response.status === 500) {
            alert("Internal server error");
          } else {
            alert("Something went wrong. Please try again later.  profile get");
          }
        } else {
          alert("Something went wrong. Please try again later. profile get");
        }
        this.cookies.logout();
        window.location.reload();
      });
  };
  apiChangePassword = () => {
    this.props.updatePassword(this.state.new_password).then((response) => {
      if (
        this.props.userdata.updatedPassword.payload &&
        this.props.userdata.updatedPassword.payload.success === "true"
      ) {
        PasswordUpdateToast();
        this.setState({
          new_password: "",
          confirm_password: "",
        });
      }
      this.setState({
        loading: false,
      });
    });
  };

  apiAccountDelete = (event) => {
    event.preventDefault();
    AccountDeletionToast();
    this.props.deleteAccount().then(() => {
      this.props.history.push("/sign-in");
      this.setState({
        loading: false,
      });
    });
  };

  submitPassword = (event) => {
    event.preventDefault();
    let new_password = this.setData(
      this.validation.validateNewPassword(this.state.new_password)
    );
    let confirm_password = this.setData(
      this.validation.validateConfirmPassword(
        this.state.new_password,
        this.state.confirm_password
      )
    );
    if (new_password === null && confirm_password === null) {
      this.setState(
        {
          loading: true,
        },
        () => this.apiChangePassword()
      );
    } else {
      return;
    }
  };

  render() {
    if (this.state.ready) {
      const { activeTab } = this.state;
      return (
        <div>
          <Modal
            isOpen={this.props.modal}
            toggle={this.props.toggle}
            centered
            className="modal-dialog-zoom"
          >
            <ToastContainer
              position="top-center"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <ModalHeader toggle={this.props.toggle}>
              <FeatherIcon.Edit2 className="mr-2" /> Profile
            </ModalHeader>
            <ModalBody>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      this.toggle("1");
                    }}
                  >
                    Personal
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "5" })}
                    onClick={() => {
                      this.toggle("5");
                    }}
                  >
                    Change Password
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "6" })}
                    onClick={() => {
                      this.toggle("6");
                    }}
                  >
                    Account Delete
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Form onSubmit={this.submit}>
                    <FormGroup>
                      <Label for="firstname">First Name</Label>
                      <InputGroup>
                        <Input
                          type="text"
                          name="firstname"
                          onChange={this.inputChange}
                          onBlur={this.validate}
                          value={this.state.firstname}
                          id="firstname"
                        />
                      </InputGroup>
                      {this.state.error_first_name === null ? (
                        <></>
                      ) : (
                        <span className="error_text">
                          {this.state.error_first_name}
                        </span>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="lastname">Last Name</Label>
                      <InputGroup>
                        <Input
                          type="text"
                          name="lastname"
                          onChange={this.inputChange}
                          onBlur={this.validate}
                          value={this.state.lastname}
                          id="lastname"
                        />
                      </InputGroup>
                      {this.state.error_last_name === null ? (
                        <></>
                      ) : (
                        <span className="error_text">
                          {this.state.error_last_name}
                        </span>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="email">Email</Label>
                      <InputGroup>
                        <Input
                          type="text"
                          name="email"
                          readOnly
                          value={this.state.email}
                          id="email"
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <Label for="phone">Mobile Number</Label>
                      <InputGroup>
                        <Input
                          type="text"
                          name="phone"
                          readOnly
                          value={this.state.phone}
                          id="phone"
                        />
                      </InputGroup>
                    </FormGroup>
                    <ModalFooter>
                      <Button color="primary">SAVE CHANGE</Button>
                    </ModalFooter>
                  </Form>
                </TabPane>
                <TabPane tabId="5">
                  <Form onSubmit={this.submitPassword}>
                    <FormGroup>
                      <Label for="new_password">New Password</Label>
                      <InputGroup>
                        <Input
                          type="password"
                          placeholder="New Password"
                          name="new_password"
                          onChange={this.inputChange}
                          onBlur={this.validate}
                          value={this.state.new_password}
                          id="new_password"
                        />
                      </InputGroup>

                      {this.state.error_new_password === null ? (
                        <></>
                      ) : (
                        <span className="error_text">
                          {this.state.error_new_password}
                        </span>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="confirm_password">Confirm Password</Label>
                      <InputGroup>
                        <Input
                          type="password"
                          placeholder="Confirm Password"
                          name="confirm_password"
                          onChange={this.inputChange}
                          onBlur={this.validate}
                          value={this.state.confirm_password}
                          id="confirm_password"
                        />
                      </InputGroup>
                      {this.state.error_confirm_password === null ? (
                        <></>
                      ) : (
                        <span className="error_text">
                          {this.state.error_confirm_password}
                        </span>
                      )}
                    </FormGroup>

                    <ModalFooter>
                      <Button color="primary">Change Password</Button>
                    </ModalFooter>
                  </Form>
                </TabPane>
                <TabPane tabId="6">
                  <Form onSubmit={this.apiAccountDelete}>
                    <FormGroup>
                      <p>
                        Are you sure you want to delete your SwitchTell account
                        permanently?
                      </p>
                      <Label for="company_name">
                        Please type{" "}
                        <b>
                          <i>delete</i>
                        </b>{" "}
                        to confirm.
                      </Label>
                      <InputGroup>
                        <Input
                          type="text"
                          className="font-italic"
                          autoComplete="off"
                          name="delete"
                          onChange={this.inputChange}
                          value={this.state.delete}
                          id="delete"
                        />
                      </InputGroup>
                    </FormGroup>
                    <ModalFooter>
                      {this.state.delete === "delete" ? (
                        <Button color="primary" className="mr-0">
                          Delete
                        </Button>
                      ) : (
                        <Button color="primary" disabled className="mr-0">
                          Delete
                        </Button>
                      )}
                    </ModalFooter>
                  </Form>
                </TabPane>
              </TabContent>
            </ModalBody>
          </Modal>
          <Loader loading={this.state.loading} />
        </div>
      );
    } else {
      return (
        <></>
        // <div><LoaderPage/></div>>
      );
    }
  }
}

const mapDispatchToProps = {
  getProfileData,
  updateProfileData,
  updatePassword,
  deleteAccount,
};

const mapStateToProps = (state) => {
  return {
    userdata: state.editProfile,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditProfileModal));
