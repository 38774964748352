import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { Autocomplete, Stack, TextField } from "@mui/material";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import * as FeatherIcon from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { selectedChatAction } from "../../../Store/Actions/SelectedChat/selectedChatAction";

const EditGroupForm = ({ friendList, modalToggle, userId, socket, modal, chat }) => {
  const [contactList, setContactList] = useState([]);
  const dispatch = useDispatch();
  const [personName, setPersonName] = useState([...chat?.chat?.participantsIds]);
  const [personDetails, setPersonDetails] = useState([...chat?.chat?.participantsDetails]);

  // Handle the selection of new members in the Autocomplete component
  const handleSelectChange = (event, value) => {
    // Get the phone numbers of the selected members
    let phoneArr = value.map((item) => item?.phone);

    // Update the states with new members
    setPersonDetails([...chat?.chat?.participantsDetails, ...value]);
    setPersonName([...chat?.chat?.participantsIds, ...phoneArr]);
  };

  // Handle the submission of group edit
  const groupEditSubmitHandle = (e) => {
    // Construct the payload for updating the group
    const payload = {
      conversationId: chat?.chat?.conversationId,
      roomId: chat?.chat?.roomId,
      participantsIds: personName,
      adminId: chat?.chat?.initiatedBy,
    };

    const storeUpdatePayload = {
      ...chat?.chat,
      participantsIds: personName,
      participantsDetails: personDetails,
    };

    // Update the Redux store
    dispatch(selectedChatAction({ id: chat?.chat?.conversationId, chat: storeUpdatePayload, friendName: chat?.chat?.roomName }));

    // Emit the 'add-user' event to the server with the payload
    socket?.emit("add-user", payload);

    // Close the modal after updating
    modalToggle();
  };

  useEffect(() => {
    // Filter out already added members from the contact list
    const arr = friendList.filter((item) => !chat?.chat?.participantsIds.includes(item?.phone));
    setContactList(arr);
  }, [friendList, chat?.chat?.participantsIds]);

  // Handle the deletion of a member from the group
  const handleDelete = (deletedPerson) => {
    // Optimistically update the state to remove the member
    const updatedPersonName = personName.filter((id) => id !== deletedPerson.phone);
    const updatedPersonDetails = personDetails.filter((detail) => detail.phone !== deletedPerson.phone);

    setPersonName(updatedPersonName);
    setPersonDetails(updatedPersonDetails);

    // Construct the payload for removing a member
    const payload = {
      userId: userId,
      conversationId: chat?.chat?.conversationId,
      roomId: chat?.chat?.roomId,
      participantsIds: updatedPersonName,
      adminId: chat?.chat?.initiatedBy,
      deleteParticipantId: deletedPerson.phone,
    };

    // Emit the 'remove-member' event to the server
    socket?.emit("remove-member", payload, (response) => {
      if (!response.success) {
        // Revert the state if the deletion fails
        setPersonName((prevNames) => [...prevNames, deletedPerson.phone]);
        setPersonDetails((prevDetails) => [...prevDetails, deletedPerson]);
        console.error(response.message);
      } else {
        // Optionally update the store
        const updatedChat = {
          ...chat?.chat,
          participantsIds: updatedPersonName,
          participantsDetails: updatedPersonDetails,
        };
        dispatch(selectedChatAction({ id: chat?.chat?.conversationId, chat: updatedChat, friendName: chat?.chat?.roomName }));
      }
    });
  };

  return (
    <>
      {contactList && (
        <>
          <Modal className="modal-dialog-zoom" isOpen={modal} toggle={modalToggle} centered>
            <ModalHeader toggle={modalToggle}>
              <FeatherIcon.UserPlus className="mr-2" /> {chat?.chat?.roomName}
            </ModalHeader>
            <ModalBody>
              {personDetails && (
                <Box className="group-member-chip">
                  {userId === chat?.chat?.initiatedBy
                    ? personDetails.map((value) => (
                        <Stack direction="row" spacing={1} key={value?.phone}>
                          <Chip label={value?.fullName ? value?.fullName : value?.firstName ? value?.firstName : value?.phone} onDelete={() => handleDelete(value)} />
                        </Stack>
                      ))
                    : personDetails.map((value) => (
                        <Stack direction="row" spacing={1} key={value?.phone}>
                          <Chip label={value?.fullName ? value?.fullName : value?.firstName ? value?.firstName : value?.phone} />
                        </Stack>
                      ))}
                </Box>
              )}
              {userId === chat?.chat?.initiatedBy && (
                <Box className="group-member-input">
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    options={contactList}
                    getOptionLabel={(value) => (value?.fullName ? value?.fullName : value?.firstName ? value?.firstName : value?.phone)}
                    onChange={handleSelectChange}
                    clearIcon={null}
                    renderInput={(params) => <TextField {...params} variant="standard" label="Group Members" />}
                    disableClearable
                  />
                </Box>
              )}
            </ModalBody>
            <ModalFooter>
              {userId === chat?.chat?.initiatedBy && (
                <Button color="primary" onClick={groupEditSubmitHandle}>
                  Update
                </Button>
              )}
            </ModalFooter>
          </Modal>
        </>
      )}
    </>
  );
};

export default EditGroupForm;
