import axios from "axios";
import API from "../../../services/api_endpoint";
import Cookies from "../../../services/cookies";
import { deleteProfileAction, getProfileAction, updatePasswordAction, updateProfileAction } from "./EditProfileAction";
import https from 'https';

const cookies = new Cookies()

const getProfileData = () => {
    return async (dispatch) => {
        const agent = new https.Agent({
            rejectUnauthorized: false
         });
        await axios.get(`${API.profile_view}?auth_key=${cookies.getItem('KEY')}`, {httpsAgent: agent})
            .then(response => {
                const { data } = response;
                // console.log("profile data ====>>", data.data);
                cookies.setItem("userID", JSON.stringify(data.data[0].id))
                data.data[0].dids && cookies.setItem("dids",JSON.stringify(data.data[0].dids))
                // dispatch(getProfileData())
                // console.log("data  - true- > ", data)
                if(cookies.getItem("forcreateuser") === "true"){
                    // console.log("is now login true starte")
                    const createUser = (optionBodyd) => {
                        const optionBody = optionBodyd
                        axios
                          .post(`${API.createUser}?auth_key=${cookies.getItem("KEY")}`, optionBody)
                          .then((response) => {
                            const { data } = response;
                            // console.log("CreateUserrrrrrrr =========>>>", data);
                            cookies.removeItem("forcreateuser")
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      }
                      const optionBodyd = {
                        userId:data.data[0].id,
                        firstName:data.data[0].firstname,
                        middleName:'',
                        lastName:data.data[0].lastname,
                        emailAddress:data.data[0].email,
                        mobileNumber:data.data[0].phone,
                        deviceId: "",
                        deviceType: "web",
                        deviceToken: cookies.getItem("fcm_token")
                      }
                      createUser(optionBodyd)
                }
                dispatch(getProfileAction({ type: 'GET_PROFILE', payload: { ...data } }))
            })
            .catch((error) => {
                if (error && error.response) {
                    if (error.response.status === 400) {
                        alert(error.response.data.message);

                    }
                    else if (error.response.status === 401) {
                        alert(error.response.data.message);
                    }
                    else if (error.response.status === 404) {
                        alert(error.response.data.message);
                    }
                    else if (error.response.status === 500) {
                        alert("Internal server error");
                    }
                    else {
                        alert("Something went wrong. Please try again later profile data");
                    }
                }
                else {
                    alert("Something went wrong. Please try again later profile data");
                    cookies.logout();
                }

            }
            )
    }
}

const updateProfileData = (lastname, firstname) => {
    return async (dispatch) => {
        await axios.post(`${API.profile_update}?auth_key=${cookies.getItem('KEY')}&action=edit&lastname=${lastname}&firstname=${firstname}`)
            .then(response => {
                const { data } = response;
                dispatch(updateProfileAction({ type: 'UPDATE_PROFILE', payload: { ...data } }))
                dispatch(getProfileData())     // updating profile data whenever user data changed or edited 
            })
            .catch((error) => {
                this.setState({
                    loading: false
                })
                if (error && error.response) {
                    if (error.response.status === 400) {
                        alert(error.response.data.message);
                    }
                    else if (error.response.status === 401) {
                        alert(error.response.data.message);
                    }
                    else if (error.response.status === 404) {
                        alert(error.response.data.message);
                    }
                    else if (error.response.status === 500) {
                        alert("Internal server error");
                    }
                    else {
                        alert("Something went wrong. Please try again later. updateprofiledata");
                    }
                }
                else {
                    alert("Something went wrong. Please try again later. updateprofiledata");
                }
            }
            )
    }
}

const updatePassword = (new_Password) => {
    return async (dispatch) => {
        await axios.post(`${API.password_update}&auth_key=${cookies.getItem('KEY')}&uipass=${new_Password}`)
            .then(response => {
                const { data } = response;
                //    console.log(response)
                dispatch(updatePasswordAction({ type: 'UPDATE_PASSWORD', payload: { ...data } }))
            })
            .catch((error) => {
                if (error && error.response) {
                    if (error.response.status === 400) {
                        alert(error.response.data.message);
                    }
                    else if (error.response.status === 401) {
                        alert(error.response.data.message);
                    }
                    else if (error.response.status === 404) {
                        alert(error.response.data.message);
                    }
                    else if (error.response.status === 500) {
                        alert("Internal server error");
                    }
                    else {
                        alert("Something went wrong. Please try again later.");
                    }
                }
                else {
                    alert("Something went wrong. Please try again later.");
                }
            }
            )
    }
}

const deleteAccount = () => {

    return async (dispatch) => {
        await axios.post(`${API.account_delete}?auth_key=${cookies.getItem('KEY')}`)
            .then(response => {
                if (response.data.success === "true") {
                    cookies.logout()
                }
                dispatch(deleteProfileAction({ type: 'DELETE_PROFILE' }))
            })
            .catch(err => {
                console.log(err)
            })
    }
}

export { getProfileData, updateProfileData, updatePassword, deleteAccount }