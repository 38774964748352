// import firebase from "firebase";
import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyAiBJqVj2DJmVkUXUCnFUDjvygASVdzVLE",
  authDomain: "switchtell-7bfb6.firebaseapp.com",
  databaseURL: "https://switchtell-7bfb6.firebaseio.com",
  projectId: "switchtell-7bfb6",
  storageBucket: "switchtell-7bfb6.appspot.com",
  messagingSenderId: "468656273485",
  appId: "1:468656273485:web:d32fac7966613f0a7c8404"
};

// Initialize Firebase
  firebase.initializeApp(firebaseConfig);

export default firebase;