import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Validation from "../../../validation/validation";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, Form, FormGroup, Label, Input } from "reactstrap";
import * as FeatherIcon from "react-feather";
import Cookies from "../../../services/cookies";
import { SuccessToast } from "../../Ui_Components/Toasts";
import { ToastContainer } from "react-toastify";
import API from "../../../services/api_endpoint";
import axios from "axios";

function AddContactModal(props) {
  const [modal, setModal] = useState(false);
  const cookies = new Cookies();
  const { editProfile } = useSelector((state) => state);

  const [userData, setuserData] = useState({
    name: "",
    contact: "",
    email: "",
    dId: "",
  });

  const [nameError, setnameError] = useState("");
  const [numberError, setnumberError] = useState("");
  const [EmailError, setEmailError] = useState("");

  const modalToggle = () => {
    setuserData({
      name: "",
      contact: "",
      email: "",
      dId: "",
    });
    setnameError(true);
    setnumberError(true);
    setEmailError(true);
    setModal(!modal);
    setAdd(true);
  };

  const [add, setAdd] = useState(true);

  useEffect(() => {
    if (add === false) {
      setModal(false);
    }
  }, [add]);

  const AddData = () => {
    if (
      add &&
      Validation.validateFullName(userData.name, setnameError) &&
      Validation.validateMobileNumber(userData.contact, setnumberError) &&
      Validation.validateEmail(userData.email, setEmailError)
    ) {
      checkFCMToken(userData.contact);
    }
  };

  const checkFCMToken = (number) => {
    axios
      .get(`${API.getfcmbynumber}?auth_key=${cookies.getItem("KEY")}&number=${number}`)
      .then((response) => {
        if (response.data.fcm) {
          contactSync();
        } else {
          alert("The number that you have entered is not registered with Switchtell. Kindly check the number or invite the number to register");
        }
      })
      .catch((err) => {
        console.log("Error fetching FCM token:", err);
        alert("Error checking number. Please try again.");
      });
  };

  const contactSync = () => {
    const options = {
      userId: editProfile?.UserData?.payload?.data[0]?.id,
      contactsList: [
        {
          contactName: userData.name,
          contactNumber: userData.contact,
          email: userData.email,
          dId: userData.dId,
        },
      ],
    };
    axios
      .post(`${API.contactSync}?auth_key=${cookies.getItem("KEY")}`, options)
      .then((response) => {
        SuccessToast("Contact Added Successfully");
        props.setIsSync(!props.isSync);
        setModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const tooltipToggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <button className="btn btn-outline-light" onClick={modalToggle} id="Tooltip-Add-Friend">
        <FeatherIcon.UserPlus />
      </button>

      <Tooltip placement="bottom" isOpen={tooltipOpen} target={"Tooltip-Add-Friend"} toggle={tooltipToggle}>
        Add Contact
      </Tooltip>
      <Modal className="modal-dialog-zoom" isOpen={modal} toggle={modalToggle} centered>
        <ModalHeader toggle={modalToggle}>
          <FeatherIcon.UserPlus className="mr-2" /> Add Contact
        </ModalHeader>
        <ModalBody className="addContactModalBody">
          <Form>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input type="text" value={userData.name || ""} onChange={(e) => setuserData({ ...userData, name: e.target.value })} name="name" id="name" />
              {nameError === true ? <></> : <span className="error_text">{nameError}</span>}
            </FormGroup>
            <FormGroup>
              <Label for="number">Contact Number</Label>
              <Input
                type="number"
                value={userData.contact || ""}
                maxLength="12"
                onChange={(e) => setuserData({ ...userData, contact: e.target.value })}
                name="number"
                id="contactNumber"
              />
              {numberError === true ? <></> : <span className="error_text">{numberError}</span>}
            </FormGroup>
            <FormGroup>
              <Label for="email">Email address</Label>
              <Input type="text" value={userData.email || ""} onChange={(e) => setuserData({ ...userData, email: e.target.value })} name="email" id="email" />
              {EmailError === true ? <></> : <span className="error_text">{EmailError}</span>}
            </FormGroup>
            <FormGroup>
              <Label for="email">DID Number</Label>
              <Input type="text" value={userData.dId || ""} onChange={(e) => setuserData({ ...userData, dId: e.target.value })} name="dId" id="dId" />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => AddData()}>
            Add
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AddContactModal;
