import React, { useState } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const MessageType = ({ message, setImgShow, handleOpen }) => {
  function is_url(str) {
    const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (regexp.test(str)) {
      return true;
    } else {
      return false;
    }
  }
  function isURL(string) {
    let res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if (res) {
      return res.map((item, i) => {
        let index = string.indexOf(item);
        let secondIndex = string?.indexOf(res[i + 1]);

        let dublicate = res.filter((dub) => {
          return dub === item;
        });

        // if (dublicate.length > 1) {
        //   console.log("indexof ", string.slice(string.indexOf(item, item?.length + index)), " === ", dublicate?.length);
        // }

        return (
          <>
            {i > 0 ? (
              <>
                {" "}
                <a style={{ color: "#8ab4f8" }} href={is_url(item) && (item.includes("https") || item.includes("http") ? item : `http://${item}`)} target="_blank">
                  {string.slice(index, item.length + index)}
                </a>{" "}
                {secondIndex ? " " + string.slice(item.length + index, secondIndex) : " " + string.slice(item.length + index)}
              </>
            ) : (
              <>
                {string.slice(0, index)}
                <a style={{ color: "#8ab4f8" }} href={is_url(item) && (item.includes("https") || item.includes("http") ? item : `http://${item}`)} target="_blank">
                  {string.slice(index, item.length + index)}
                </a>
                {" " + res[i + 1] != undefined ? string.slice(item.length + index, secondIndex) : string.slice(item.length + index)}
              </>
            )}
          </>
        );
      });
      // return <span>{index>0 && <>{string.slice(0,index)}</>}<a style={{color:"#8ab4f8"}} href={res} target="_blank">{string.slice(index,res[0].length+index)}</a>{" "+string.slice(res[0].length+index)}</span>
    } else {
      return <span>{string}</span>;
    }
  }

  const linkClick = (src) => {
    let a = document.createElement("a");
    a.href = src;
    a.target = "_blank";
    a.click();
  };

  if (message?.messageType === "text") {
    return <div className="message-content message-file">{isURL(message.message)}</div>;
  } else if (message?.messageType === "image") {
    return (
      <>
        <div className="imagetypeview">
          <img
            onClick={() => {
              setImgShow({
                status: true,
                src: message?.attachmentUrl || "",
              });
              handleOpen();
            }}
            src={message?.attachmentUrl || ""}
            width="120"
            height="120"
            alt={`${message.messageTime}`}
          />
        </div>
      </>
    );
  } else if (message?.messageType === "video") {
    return (
      <div className="videotypeview">
        <video controls className="uploading-video" src={message.attachmentUrl} width={160} height={160} />
      </div>
    );
  } else if (message?.messageType === "audio") {
    return (
      <div className="videotypeview">
        <audio controls className="uploading-video" src={message.attachmentUrl} width={160} height={160} />
      </div>
    );
  } else if (message?.messageType === "file") {
    return (
      <div className="filetypeview">
        <a target="_blank" onClick={() => linkClick(message.attachmentUrl)}>
          <FileDownloadIcon /> <span>{message?.fileOriginalName || "Download"}</span>
        </a>
      </div>
    );
  } else {
    return <>...</>;
  }
  return <></>;
};

export default MessageType;
