import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Tooltip, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import * as FeatherIcon from "react-feather";
import EditProfileModal from "./Modals/EditProfileModal";
import { profileAction } from "../../Store/Actions/Profile/profileAction";
import SettingsModal from "./Modals/SettingsModal";
import WomenAvatar5 from "../../assets/img/women_avatar5.jpg";
import { mobileProfileAction } from "../../Store/Actions/Mobile/mobileProfileAction";
import Cookies from "../../services/cookies";
import PaymentModal from "./Modals/PaymentModal";
import RentModal from "./Modals/RentModal";
import { navigationItems } from "./NavigationItems";
import NavigationItemView from "./NavigationItemView";

function Navigation() {
  const cookies = new Cookies();

  const dispatch = useDispatch();
  const [userMenuTooltipOpen, setUserMenuTooltipOpen] = useState(false);
  const [editTooltipOpen, seteditTooltipOpen] = useState(false);
  const [viewTooltipOpen, setviewTooltipOpen] = useState(false);
  const [settingTooltipOpen, setsettingTooltipOpen] = useState(false);
  const [creditTooltipOpen, setcreditTooltipOpen] = useState(false);
  const [rentTooltipOpen, setrentTooltipOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [darkSwitcherTooltipOpen, setDarkSwitcherTooltipOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [rentModalOpen, setRentModalOpen] = useState(false);

  const userMenuToggle = () => {
    return !dropdownOpen && setUserMenuTooltipOpen(!userMenuTooltipOpen);
  };
  const editToggle = (e) => {
    if (e.target.id === "Edit-profile") {
      return !editModalOpen && seteditTooltipOpen(!editTooltipOpen);
    }
    if (e.target.id === "View-profile") {
      return setviewTooltipOpen(!viewTooltipOpen);
    }
    if (e.target.id === "Settings") {
      return !settingsModalOpen && setsettingTooltipOpen(!settingTooltipOpen);
    }
    if (e.target.id === "Credit") {
      return !paymentModalOpen && setcreditTooltipOpen(!creditTooltipOpen);
    }

    if (e.target.id === "Rent") {
      return !rentModalOpen && setrentTooltipOpen(!rentTooltipOpen);
    } else {
      editModalOpen && seteditTooltipOpen(false);
      setviewTooltipOpen(false);
      setsettingTooltipOpen(false);
      setcreditTooltipOpen(false);
      setrentTooltipOpen(false);
    }
    return;
  };

  const toggle = () =>
    setDropdownOpen((prevState) => {
      setUserMenuTooltipOpen(false);
      return !prevState;
    });

  const darkSwitcherTooltipToggle = () => setDarkSwitcherTooltipOpen(!darkSwitcherTooltipOpen);

  const darkSwitcherToggle = (e) => {
    e.preventDefault();
    document.body.classList.toggle("dark");
  };

  const editModalToggle = () => {
    seteditTooltipOpen(false);
    return setEditModalOpen(!editModalOpen);
  };

  const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  const settingsModalToggle = () => {
    setsettingTooltipOpen(false);
    return setSettingsModalOpen(!settingsModalOpen);
  };

  const paymentModalToggle = () => {
    setcreditTooltipOpen(false);
    return setPaymentModalOpen(!paymentModalOpen);
  };

  const rentModalToggle = () => {
    setrentTooltipOpen(false);
    return setRentModalOpen(!rentModalOpen);
  };

  const profileActions = () => {
    setviewTooltipOpen(false);
    dispatch(profileAction(true));
    dispatch(mobileProfileAction(true));
  };

  const DropDownItemList = [
    {
      id: "Edit-profile",
      options: ["Edit profile", editModalToggle, editTooltipOpen, editToggle],
    },
    {
      id: "View-profile",
      options: ["View Profile", profileActions, viewTooltipOpen, editToggle],
    },
    {
      id: "Settings",
      options: ["Settings", settingsModalToggle, settingTooltipOpen, editToggle],
    },
    {
      id: "Credit",
      options: ["Credit Balance", paymentModalToggle, creditTooltipOpen, editToggle],
    },
    {
      id: "Rent",
      options: ["Rent/Subscribe a Virtual Number", rentModalToggle, rentTooltipOpen, editToggle],
    },
  ];

  return (
    <nav className="navigation">
      <EditProfileModal modal={editModalOpen} toggle={editModalToggle} />
      <SettingsModal modal={settingsModalOpen} setSettingsModalOpen={setSettingsModalOpen} toggle={settingsModalToggle} />
      <PaymentModal modal={paymentModalOpen} toggle={paymentModalToggle} />
      <RentModal modal={rentModalOpen} toggle={rentModalToggle} />
      <div className="nav-group">
        <ul>
          <li className="logo">
            <a href="/">
              <img width="90px" height="80px" src={require("./../../assets/logo.png").default} alt="" />
            </a>
          </li>
          {navigationItems.map((item, i) => (
            <NavigationItemView key={i} item={item} tooltipName={"Tooltip-" + i} />
          ))}
          <li className="scissors">
            <a href="/" onClick={(e) => darkSwitcherToggle(e)} className="dark-light-switcher" id="dark-switcher">
              <FeatherIcon.Moon />
            </a>
            <Tooltip placement="right" isOpen={darkSwitcherTooltipOpen} target="dark-switcher" toggle={darkSwitcherTooltipToggle}>
              Dark mode
            </Tooltip>
          </li>
          <li id="user-menu" className="text-center">
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
                <figure className="avatar">
                  <img src={WomenAvatar5} className="rounded-circle" alt="avatar" />
                </figure>
              </DropdownToggle>
              <DropdownMenu className="dropDownMenuNavigation">
                {DropDownItemList.map((item, i) => (
                  <>
                    <DropdownItem
                      key={item.id + i}
                      className="dropDownItemNavigation"
                      onClick={() => {
                        item.options[1]();
                      }}
                      id={item.id}
                    >
                      {item.options[0]}
                    </DropdownItem>
                    <Tooltip placement="right" isOpen={item.options[2]} target={item.id} toggle={item.options[3]}>
                      {item.options[0]}
                    </Tooltip>
                  </>
                ))}
                <DropdownItem className="dropDownItemNavigation" divider />
                <DropdownItem
                  id="Logout"
                  className="text-danger dropDownItemNavigation"
                  onClick={() => {
                    cookies.logout();
                    window.location.href = "/sign-in";
                  }}
                >
                  Logout
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Tooltip placement="right" isOpen={userMenuTooltipOpen} target="user-menu" toggle={userMenuToggle}>
              User menu
            </Tooltip>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navigation;
