const initialState = {
    paymentData : {}
}



const paymentReducer = (state = initialState,{type,payload}) =>
{  
    switch(type) 
    {
        case 'PAYMENT' : return {
            ...state,
            paymentData : payload
        };
        default : return state
    }
}


export default paymentReducer 