import axios from "axios"
import API from "../../../services/api_endpoint"
import { VirtualNumbersAction } from "./VirtualNumbers";

const virtualNumbers = () => {
    return async (dispatch) => {
        const response = await axios.get(`${API.virtualNumbers}`)
        const {data} = response;
      
        dispatch(VirtualNumbersAction({type : 'VIRTUAL_NUMBERS',payload : {...data}}))
    }
}

export {virtualNumbers};