import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as FeatherIcon from "react-feather";
import { Tooltip } from "reactstrap";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import AddGroupModal from "../../Modals/AddGroupModal";
import { sidebarAction } from "../../../../Store/Actions/sidebarAction";
import ChatListView from "./ChatListView"; // Ensure this path is correct
import { selectedChatAction } from "../../../../Store/Actions/SelectedChat/selectedChatAction";
import axios from "axios";
import API from "../../../../services/api_endpoint";
import { userNameFriend } from "../../../../services/helpers/FriendName";
import { newFriendData } from "./Data";

function Index({ socket, userId }) {
  const dispatch = useDispatch();
  const { editProfile } = useSelector((state) => state);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [friendList, setFriendList] = useState(null);
  const [friendListNumber, setFriendListNumber] = useState(null);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const mobileMenuBtn = () => document.body.classList.toggle("navigation-open");

  useEffect(() => {
    socket.on("socket-connected", (res) => {
      console.log("isConnected", res);
      socket.emit("connected", { userId: userId });
    });

    socket.emit("get-conversation", { userId: userId, pageNum: 1 });
    socket.on("conversation-got", (res) => {
      if (res?.conversationData?.allConversations.length > 0) {
        if (window.localStorage.getItem("newFriend")) {
          let chhat;
          chhat = res?.conversationData?.allConversations.filter((chat) => {
            if (
              (chat.initiatedBy === window.localStorage.getItem("newFriend") || chat.participantsIds.includes(window.localStorage.getItem("newFriend"))) &&
              chat.chatType !== 2 &&
              !(chat.participantsIds.length > 1)
            ) {
              return chat;
            }
          });
          if (chhat.length > 0) {
            setFriendList(res?.conversationData?.allConversations);
            dispatch(
              selectedChatAction({
                id: chhat[0].conversationId,
                chat: chhat[0],
              })
            );
            window.localStorage.removeItem("newFriend");
            window.localStorage.removeItem("newFriendName");
          } else {
            setFriendList([
              {
                ...newFriendData,
                participantsIds: [window.localStorage.getItem("newFriend")],
                senderId: userId,
              },
              ...res?.conversationData?.allConversations,
            ]);
            dispatch(selectedChatAction(true));
          }
        } else {
          setFriendList(res?.conversationData?.allConversations);
        }
      } else {
        if (window.localStorage.getItem("newFriend")) {
          setFriendList([
            {
              ...newFriendData,
              participantsIds: [window.localStorage.getItem("newFriend")],
              senderId: userId,
            },
            ...res?.conversationData?.allConversations,
          ]);

          setTimeout(() => {
            window.localStorage.removeItem("newFriend");
            window.localStorage.removeItem("newFriendName");
          }, 44000);
        }
      }
    });

    socket.on("conversation-deleted", (deleteDetails) => {
      setFriendList((prevFriendList) => prevFriendList.filter((chat) => chat.conversationId !== deleteDetails.conversationId));
    });
  }, []);

  useEffect(() => {
    getContactSync();
  }, []);

  const getContactSync = () => {
    axios
      .get(`${API.getContact}?userId=${editProfile?.UserData?.payload?.data[0]?.id}`)
      .then((response) => {
        const { data } = response;
        setFriendListNumber(data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {friendListNumber && (
        <div className="sidebar active">
          <header>
            <div className="d-flex align-items-center">
              <button onClick={mobileMenuBtn} className="btn btn-outline-light mobile-navigation-button mr-3 d-xl-none d-inline">
                <FeatherIcon.Menu />
              </button>
              <span className="sidebar-title">Chats</span>
            </div>
            <ul className="list-inline">
              <li className="list-inline-item">
                <AddGroupModal userId={userId} socket={socket} />
              </li>
              <li className="list-inline-item">
                <button onClick={() => dispatch(sidebarAction("Friends"))} className="btn btn-outline-light" id="Tooltip-New-Chat">
                  <FeatherIcon.PlusCircle />
                </button>
                <Tooltip placement="bottom" isOpen={tooltipOpen} target={"Tooltip-New-Chat"} toggle={toggle}>
                  New chat
                </Tooltip>
              </li>
            </ul>
          </header>
          <br />
          <hr />

          <div className="sidebar-body">
            <PerfectScrollbar>
              <ul className="list-group list-group-flush" style={{ overflow: "hidden" }}>
                {friendList &&
                  friendList.map((chat, i) => (
                    <ChatListView key={i} chat={chat} userId={userId} friendName={userNameFriend(chat, userId, friendListNumber)} socket={socket} setFriendList={setFriendList} />
                  ))}
              </ul>
            </PerfectScrollbar>
          </div>
        </div>
      )}
    </>
  );
}

export default Index;
