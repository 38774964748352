import React, { useState } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';

function ShowContactModal(props) {
    const [isCopy, setIsCopy] = useState(false)
    const [modal, setModal] = useState(true);

    const modalToggle = () => {
        setModal(!modal);
        props.setSentErrorData([!modal])
    }

    const handleCopy = () => {
        navigator.clipboard.writeText("meet.switchtell.com/" + props.data[1])
        setIsCopy(true)
    }

    return (
        <div>
            <Modal className="modal-dialog-zoom" isOpen={modal} toggle={modalToggle} centered>
                <ModalHeader toggle={modalToggle}>
                </ModalHeader>
                <ModalBody>
                    <br /><br />
                    <p><center>Please share link</center></p><br /><br />
                    <p><center> meet.switchtell.com/{props.data[1]}</center></p>
                    <p style={{ cursor: 'pointer' }} onClick={handleCopy}><center>{isCopy ? "Copied" : "Copy"}</center></p>
                    <br /><br /><br />
                    <center><button onClick={() => { props.startConmByShare(props.data[1]); modalToggle(); }} class="btn btn-success">Start</button></center><br /><br />
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default ShowContactModal
