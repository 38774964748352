const initialState = {
    callLogData : {}
}



const callLogReducer = (state = initialState,{type,payload}) =>
{  
    switch(type) 
    {
        case 'CALL_LOGS' : return {
            ...state,
            callLogData : payload
        };
        default : return state
    }
}


export default callLogReducer 