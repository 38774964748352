import React, { Component } from 'react';
import { connect } from 'react-redux';
import { JitsiAction } from '../../Store/Actions/Jitsi/JitsiAction';
import './Style.css'

class JitsiComponent extends Component {

    domain = 'meet.switchtell.com';
    api = {};
   
    constructor(props) {
        super(props);
        this.state = {
            room: this.props.name.replace(/\s/g, ""),
            user: {
                name: ''
            },
            isAudioMuted: false,
            isVideoMuted: false
        }
    }

    startMeet = (videoStatus) => {
        const options = {
            roomName: this.state.room,
            // p2p: {
            //     enabled: true,
            //   },
            width: '100%',
            height: 500,
            configOverwrite: { startWithVideoMuted: !videoStatus },
            interfaceConfigOverwrite: {
                // overwrite interface properties
            },
            parentNode: document.querySelector('#jitsi-iframe'),
            userInfo: {
                displayName: this.props.username
            }
        }
        this.api = new window.JitsiMeetExternalAPI(this.domain, options);

        this.api.addEventListeners({
            readyToClose: this.handleClose,
            participantLeft: this.handleParticipantLeft,
            participantJoined: this.handleParticipantJoined,
            videoConferenceJoined: this.handleVideoConferenceJoined,
            videoConferenceLeft: this.handleVideoConferenceLeft,
            audioMuteStatusChanged: this.handleMuteStatus,
            videoMuteStatusChanged: this.handleVideoStatus
        });
    }

    handleClose = () => {
        this.props.JitsiAction({
            isJistiopen : false,
            callerId : null,
            startWithVideoMuted:false
          })
    }

    handleParticipantLeft = async (participant) => {
        const data = await this.getParticipants();
    }

    handleParticipantJoined = async (participant) => {
        const data = await this.getParticipants();
    }

    handleVideoConferenceJoined = async (participant) => {
        // console.log("handleVideoConferenceJoined", participant); // { roomName: "bwb-bfqi-vmh", id: "8c35a951", displayName: "Akash Verma", formattedDisplayName: "Akash Verma (me)"}
        const data = await this.getParticipants();
    }

    handleVideoConferenceLeft = () => {
        // console.log("handleVideoConferenceLeft");
        // this.props.loading
        // this.props.loading(false)
    }

    handleMuteStatus = (audio) => {
        // console.log("handleMuteStatus", audio); // { muted: true }
    }

    handleChange = (event) => {
        this.setState({
            user: {
                name: event.target.value
            }
        })
    }

    handleVideoStatus = (video) => {
        // console.log("handleVideoStatus", video); // { muted: true }
        
    }

    getParticipants() {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(this.api.getParticipantsInfo()); // get all participants
            }, 500)
        });
    }

    // custom events
    executeCommand(command) {
        this.api.executeCommand(command);;
        if (command === 'hangup') {
            return this.props.history.push('/thank-you');
        }

        if (command === 'toggleAudio') {
            this.setState({ isAudioMuted: !this.state.isAudioMuted });
        }

        if (command === 'toggleVideo') {
            this.setState({ isVideoMuted: !this.state.isVideoMuted });
        }
    }

    componentDidMount() {
        if (window.JitsiMeetExternalAPI) {
            this.startMeet(this.props.jitsiData.startWithVideoMuted);
        } else {
            alert('JitsiMeetExternalAPI not loaded');
        }
    }

    render() {
        const { isAudioMuted, isVideoMuted } = this.state;
        // console.log("Jitsiprops=========>", this.props.modalToggle);
        return (
            <>
            <div>
                {/* {console.log(' jitsi main aa raha hai callerId', this.props)} */}
            </div>
                <header className="nav-bar">
                    
                </header>
                <div id="jitsi-iframe" className='jitsiContainer' ></div>
                {/* <div class="item-center">
                <span>Custom Controls</span>
            </div>
            <div class="item-center">
                <span>&nbsp;&nbsp;</span>
                <i onClick={ () => this.executeCommand('toggleAudio') } className={`fas fa-2x grey-color ${isAudioMuted ? 'fa-microphone-slash' : 'fa-microphone'}`} aria-hidden="true" title="Mute / Unmute"></i>
                <i onClick={ () => this.executeCommand('hangup') } className="fas fa-phone-slash fa-2x red-color" aria-hidden="true" title="Leave"></i>
                <i onClick={ () => this.executeCommand('toggleVideo') } className={`fas fa-2x grey-color ${isVideoMuted ? 'fa-video-slash' : 'fa-video'}`} aria-hidden="true" title="Start / Stop camera"></i>
                <i onClick={ () => this.executeCommand('toggleShareScreen') } className="fas fa-film fa-2x grey-color" aria-hidden="true" title="Share your screen"></i>
            </div> */}

            </>
        );
    }
}

const mapActionToProps = { JitsiAction };
const mapStateToProps = () => ({});
export default connect(mapStateToProps, mapActionToProps)(JitsiComponent);
