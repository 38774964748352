import React, { useEffect, useState } from 'react';
import './Style.css'
import {
    Modal,
    ModalBody,
    TabContent,
    TabPane,
    Button,
    Form,
    ModalHeader,
} from 'reactstrap'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { createTheme, IconButton, MuiThemeProvider } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { paymentDataFetch } from '../../../Store/Actions/Payment/PaymentFetch';

function PaymentModal(props) {
    const [value, setValue] = React.useState('250');
    const dispatch = useDispatch()
    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const payment = useSelector(state => state.payment)
    const [activeTab, setActiveTab] = useState('1');
    const [paymentPage, setPaymentPage] = useState([]);
    const BuyPanal = (e) => {
        e.preventDefault();
        if (value) {
            dispatch(paymentDataFetch(value))
        }
        if (value) {
            toggle('2')
        }
    }

    const theme = createTheme({
        palette: {
            primary: { main: '#4FAEA8' }, // Purple and green play nicely together.
        },
    });

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(() => {
        if (payment) {
            setPaymentPage(payment.paymentData.payload && payment.paymentData.payload)
        }
    }, [payment])

    useEffect(() => {
        if (!props.modal) {
            setActiveTab('1')
        }
    }, [])

    return (
        <Modal fade={false} isOpen={props.modal} toggle={props.toggle} centered className="modal-dialog-zoom">
            <ModalHeader className="modal-head" toggle={props.toggle}>
                <IconButton onClick={() => { activeTab === '2' && toggle('1'); }} style={{ outline: 'none', color: 'white' }}>
                    <ArrowBackIosIcon className="mr-2" />
                </IconButton>
                {activeTab === '1' ? <>Credit Balance</> : <>Paypal</>}
            </ModalHeader>
            <ModalBody >
                <Form>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <div className="modal-body1 mx-auto" style={{ paddingTop: '0px' }}>
                                <h3 className="call_sms"> Call & SMS anywhere at affordable prices</h3>
                            </div>
                            <div className='ml-3'>
                                <FormControl component="fieldset">
                                    <MuiThemeProvider theme={theme}>
                                        <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                                            <FormControlLabel value="250.00" defaultChecked control={<Radio style={{ fontSize: "2.5rem" }} size="medium" color="primary" icon={<CircleUnchecked />} checkedIcon={<CircleCheckedFilled />} />} label="₹ 250.00" />
                                            <FormControlLabel value="500.00" control={<Radio size="medium" color="primary" icon={<CircleUnchecked />} checkedIcon={<CircleCheckedFilled />} />} label="₹ 500.00" />
                                            <FormControlLabel value="2000" control={<Radio size="medium" color="primary" icon={<CircleUnchecked />} checkedIcon={<CircleCheckedFilled />} />} label="₹ 2000.00" />
                                        </RadioGroup>
                                    </MuiThemeProvider>
                                </FormControl>
                            </div>
                            <div>
                                <div className='ml-1'>
                                    <Button id="creditt" onClick={(e) => BuyPanal(e)} color="primary"><span className="mx-auto"> BUY {value} CREDIT  </span></Button>
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tabId="2" >
                            <iframe className="tabPaneIframe" srcdoc={paymentPage && paymentPage.data}></iframe>
                        </TabPane>
                    </TabContent>
                </Form>
            </ModalBody>
        </Modal>
    )
}

export default PaymentModal
