import React, { useRef, useEffect } from "react";
import { Button } from "reactstrap";
import * as FeatherIcon from "react-feather";
import CancelIcon from "@mui/icons-material/Cancel";
import "./imagest.scss";
import { useSelector } from "react-redux";

const ImageST = ({ mediaselected, setMediaSelected, images, setImages, setMediaArray, submitBtnStatus }) => {
  const { selectedChat } = useSelector((state) => state);
  const imageRef = useRef(null);
  // const [images, setImages] = useState(null);
  const mediaHandle = (event) => {
    const newImage = [];
    let numImage = Array.from(Array(event.target.files.length).keys()).map((item) => {
      return event.target.files[item];
    });
    if (images) {
      for (let i = 0; i < numImage.length; i++) {
        if (!images.find((data) => data.name === numImage[i].name)) {
          newImage.push(numImage[i]);
        }
      }
      if (images.length + newImage.length > 10) {
        alert("Please do not select above of 10 images");
      } else {
        setMediaSelected("image");
        setImages((prevState) => [...prevState, ...newImage]);
        setMediaArray((prevState) => [...prevState, ...newImage]);
      }
    } else {
      if (numImage.length > 10) {
        alert("Please do not select above of 10 images");
      } else {
        setMediaSelected("image");
        setImages(numImage);
        setMediaArray(numImage);
      }
    }
  };

  const removeImgHandle = (name) => {
    let newImg = [...images];
    newImg = newImg.filter((img) => img.name !== name);
    if (!(images.length > 1)) {
      setMediaSelected("");
    }
    setImages(newImg);
    setMediaArray(newImg);
  };

  useEffect(() => {
    images && setImages(null);
  }, [selectedChat]);

  return (
    <>
      {images && <MediaPreview media={images} removeImg={removeImgHandle} submitBtnStatus={submitBtnStatus}/>}
      <input
        onChange={(event) => {
          mediaHandle(event);
        }}
        onClick = {(event) =>{
          event.target.value = null;
        }}
        ref={imageRef}
        accept="image/png , image/jpeg, image/gif, image/webp, image/bmp"
        className="comment-file-input"
        id="image-file-input"
        type="file"
        multiple
        hidden
      />
      <Button
        disabled={mediaselected === "video" || mediaselected === "file" || mediaselected === "audio"}
        color="light"
        className={`mr-3 `}
        title="Image"
        onClick={(e) => {
          e.target.blur()
          imageRef.current.click();
        }}
        onKeyDown={(e) => {
          e.target.blur()
        }}
      >
        <FeatherIcon.Image />
      </Button>
    </>
  );
};



const MediaPreview = ({ media, removeImg, submitBtnStatus }) => {
  return (
    <>
      <div className="image-preview-container">
        <div className="image-preview-wrap">
          <div className="image-wrap-media">
            {Array.from(Array(media.length).keys()).map((item, index) => {
              return (
                <div key={media[item].name + index} className="image-container">
                  {!submitBtnStatus && <div
                    onClick={() => {
                      removeImg(media[item].name);
                    }}
                    className="remove-icon"
                  >
                    <CancelIcon />
                  </div>}
                  <img className="uploading-img" src={URL.createObjectURL(media[item])} alt="imgSTFooter" width={160} height={160} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageST;
