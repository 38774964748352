export const getProfileAction = (response) =>{
    return {
        type : 'GET_PROFILE',
        payload : response
    }
}

export const updateProfileAction = (response) =>{
    return {
        type : 'UPDATE_PROFILE',
        payload : response
    }
}

export const updatePasswordAction = (response) => {
    return {
        type : 'UPDATE_PASSWORD',
        payload : response
    }
}

export const deleteProfileAction = (response) => {
    return {
        type : 'DELETE_PROFILE',
       
    }
}