import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SuccessToast = (Data) =>{ 
    toast.success(Data, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });}


const PasswordUpdateToast = () => {
    toast.success('Password is Updated', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
}

const errorInDataSaving = (errorData) => toast.error(errorData, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
});

const AccountDeletionToast = () => {
    toast.error('Account Has been deleated', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
}

const subscriptionInfoToast = (success) => {
    toast.info(success, {
        toastId : "SubscribeId",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
}


export {SuccessToast,PasswordUpdateToast,errorInDataSaving , AccountDeletionToast, subscriptionInfoToast}