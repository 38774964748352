import React, { useState } from "react";
import ManAvatar1 from "../../../assets/img/man_avatar1.jpg";
import ImgModal from "./imgModal";
import MessageType from "./MessageType";

const MessagesView = (props) => {
  const [imgShow, setImgShow] = useState({
    status: false,
    src: "",
  });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const formatToTime = (time) => {
    let unix_timestamp = time;
    const date = new Date(time);
    // Hours part from the timestamp
    const hours = date.getHours();
    // Minutes part from the timestamp
    const minutes = "0" + date.getMinutes();
    // Seconds part from the timestamp
    // const seconds = "0" + date.getUTCSeconds();

    const formattedTime = hours + ":" + minutes.substr(-2);
    return formattedTime;
  };

  const { message, id } = props;


  if (message.type === "divider") {
    return <div className="message-item messages-divider sticky-top" data-label={message.text}></div>;
  } else {
    return (
      <>
        <ImgModal open={open} setOpen={setOpen} handleClose={handleClose} handleOpen={handleOpen} imgShow={imgShow} />
        <div className={`message-item ${id === message.senderId && "outgoing-message"}`}>
          <div className="message-avatar">
            <figure className="avatar avatar-state-success">
              <img src={ManAvatar1} className="rounded-circle" alt="avatar" />
            </figure>
            <div>
              <h5>{message.createdByName}</h5>
              <div className="time">
                {props.message.senderName}
                <br />
                {formatToTime(message.messageTime) + " PM"}
                {/* {message.messageType ? <i className="ti-double-check text-info"></i> : null} */}
              </div>
            </div>
          </div>
          <MessageType message={message} imgShow={imgShow} setImgShow={setImgShow} handleOpen={handleOpen} />
        </div>
      </>
    );
  }
};

export default MessagesView;
