import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap'
import * as FeatherIcon from 'react-feather'
import PerfectScrollbar from "react-perfect-scrollbar"
import {profileAction} from "../../../Store/Actions/Profile/profileAction"
import {mobileProfileAction} from "../../../Store/Actions/Mobile/mobileProfileAction"
import WomenAvatar5 from "../../../assets/img/women_avatar5.jpg"
import classnames from 'classnames';
import axios from 'axios';
import API from '../../../services/api_endpoint';
import { saveAs } from "file-saver"; 

function Profile() {
    const dispatch = useDispatch();

    const {profileSidebar, mobileProfileSidebar} = useSelector(state => state);
    const { editProfile } = useSelector((state) => state);
    const [activeTab, setActiveTab] = useState('1');
    const [shareProfileData, setShareProfileData] = useState('')
    const [data, setData] = useState([]);
    const [ready, setReady] = useState(false);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const profileActions = (e) => {
        e.preventDefault();
        dispatch(profileAction(false));
        dispatch(mobileProfileAction(false))
    };
    useEffect(()=>{
        if(editProfile?.UserData?.payload){
            setData(editProfile?.UserData?.payload);
            setReady(true);
        }
    },[editProfile])

    

    const saveFile = () => {
        saveAs(`${shareProfileData}`, "_blank");
      };
    
    const shareProfile = () => {
    axios.get(`${API.getShareProfile}?userId=${editProfile?.UserData?.payload?.data[0]?.id}`)
    .then((response) => {
      const { data } = response;
      setShareProfileData(data?.data);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {
    if(editProfile?.UserData?.payload?.data[0]?.id>0){
        shareProfile()
    }
  }, [editProfile])
    
    if(ready){
    return (
        <div className={`sidebar-group ${mobileProfileSidebar ? "mobile-open" : ""}`}>
            <div className={profileSidebar ? 'sidebar active' : 'sidebar'}>
                <header>
                    <div className="d-flex align-items-center">
                        <span className="sidebar-title">Profile</span>
                    </div>
                    <ul className="list-inline">
                        <li className="list-inline-item">
                            <a href="#/" onClick={(e) => profileActions(e)}
                               className="btn btn-outline-light text-danger sidebar-close">
                                <FeatherIcon.X/>
                            </a>
                        </li>
                    </ul>
                </header>
                <div className="sidebar-body">
                    <PerfectScrollbar>
                        <div className="pl-4 pr-4">
                            <div className="text-center">
                                <figure className="avatar avatar-xl mb-3">
                                    <img src={WomenAvatar5} className="rounded-circle" alt="avatar"/>
                                    
                                </figure>
                                <h5 className="mb-1">{`${data.data && data.data[0].firstname} ${data.data && data.data[0].lastname}`}</h5>
                                <p>{`${data.data[0].dids[0] ? "+" +data.data[0].dids[0].did : ""}`}</p>

                                <Nav tabs className="justify-content-center mt-5">
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '1'})}
                                            onClick={() => {
                                                toggle('1');
                                            }}
                                        >
                                            About
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <div className="mt-4 mb-4 px-4">
                                        <h6>Mobile Number</h6>
                                        <p className="text-muted">{data.data && data.data[0].phone}</p>
                                    </div>
                                    <div className="mt-4 mb-4 px-4">
                                        <h6>Email</h6>
                                        <p className="text-muted">{data.data && data.data[0].email}</p>
                                    </div>
                                    <div className="mt-4 mb-4 px-4">
                                        <h6>Balance credit</h6>
                                        <p className="text-muted">{data.data && data.data[0].credit}</p>
                                    </div>
                                    <div className="mt-4 mb-4 px-4 d-flex">
                                        <h6 className="profileVcard">VCard</h6>
                                        <FeatherIcon.Download className="downloadIcon" onClick={saveFile} />
                                    </div>
                                </TabPane>
                            </TabContent>
                        </div>
                    </PerfectScrollbar>
                </div>
            </div>
        </div>
    )
    }
    else{
        return(
            <div className={`sidebar-group ${mobileProfileSidebar ? "mobile-open" : ""}`}>Loading...</div>
        );
    }
}

export default Profile
