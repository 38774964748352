export const userNameFriend = (obj, userId, friendListNumber) => {
    let numbered = [obj.initiatedBy, obj.senderId, ...obj.participantsIds];
    let number = [...new Set(numbered)];

    if(obj.chatType == 2){
      return obj?.roomName ? obj.roomName : obj.chatName ? obj.chatName : obj.participantsIds[0]+', '+obj?.participantsIds[1]
    }

    number = number.filter((item) => {
      if (userId != item) {
        return item;
      }
    });

    let nameFilter = friendListNumber?.filter((numbered) => {
      if (numbered.phone == number[0]) {
        return numbered;
      }
    });

    return number?.length > 0 && nameFilter?.length > 0 ? nameFilter[0]?.firstName : number;
  };