import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callLogsFetch } from "../../../../Store/Actions/CallLogs/calllogsfetching";
import * as FeatherIcon from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import ManAvatar4 from "../../../../assets/img/man_avatar5.jpg";
import Cookies from "../../../../services/cookies";
import ContactModal from "../../Modals/ContactModal";

function Index({ client }) {
  const d = new Date();
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const callLogs = useSelector((state) => state.callLog);
  const { editProfile } = useSelector((state) => state);

  const [did, setDid] = useState([]);
  const [selectCalls, setSelectCalls] = useState("All");
  const [callLogsData, setcallLogsData] = useState();
  const mobileMenuBtn = () => document.body.classList.toggle("navigation-open");
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    if (editProfile.UserData.payload) {
      let didArray = [];
      editProfile.UserData.payload.data[0].dids.map((item) => {
        didArray.push(item.did);
      });
      setDid(didArray);
    }
  }, [editProfile]);

  const apiCallLogs = () => {
    dispatch(callLogsFetch());
  };
  useEffect(() => {
    apiCallLogs();
  }, [dispatch]);

  useEffect(() => {
    if (callLogs) {
      setcallLogsData(callLogs.callLogData.payload);
    }
  }, [callLogs]);

  const modalToggler = (item) => {
    setModalData(item);
    setContactModalOpen(!contactModalOpen);
  };

  const CallLogItems = ({ selectCalls, did }) => {
    if (callLogsData) {
      return (
        <ul className="list-group list-group-flush ">
          {callLogsData.data &&
            callLogsData.data.map((item, i) => {
              return selectCalls === "Missed" && // Missed call data and its toggling
                did.includes(item.src) &&
                item.terminatecauseid === 3 ? (
                <li key={i} className="list-group-item">
                  <div className="users-list-body">
                    <figure className="avatar">
                      <img src={ManAvatar4} className="rounded-circle" alt="avatar" />
                    </figure>
                    <div style={{ width: "100%" }}>
                      <h5>{item?.calledstation ? item.calledstation : item.src}</h5>
                      <div className="call_logs_info callLogsInfo">
                        <img src={require("../../../../../src/assets/missedCall.svg").default} alt="info" />

                        <FeatherIcon.Phone className="callLogPhoneIcon" />
                        <p style={{ paddingLeft: 10 }}>{d.toISOString().slice(0, 10) === item.starttime.slice(0, 10) ? "Today" : item.starttime.slice(0, 10)}</p>
                        <p style={{ paddingLeft: 10 }}>{item.starttime.slice(11, 19)}</p>
                        <img src={require("../../../../../src/assets/info.svg").default} alt="info" className="infoCallLog" onClick={() => modalToggler(item)} />
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                selectCalls === "All" && ( // this is for all the call and its toggling
                  <li key={i} className="list-group-item">
                    <div className="users-list-body">
                      <figure className="avatar">
                        <img src={ManAvatar4} className="rounded-circle" alt="avatar" />
                      </figure>
                      <div style={{ width: "100%" }}>
                        <h5>{item?.calledstation ? item.calledstation : item.src}</h5>
                        <div className="call_logs_info callLogsInfo">
                          <div className="d-flex align-items-center">
                            {did.includes(item.src) && // This div will show you the call images based on the conditions
                              item.terminatecauseid === 3 && <img src={require("../../../../../src/assets/missedCall.svg").default} alt="info" />}

                            {did.includes(item.src) && <img src={require("../../../../../src/assets/outgoing.svg").default} alt="info" />}

                            {did.includes(item.dnid) && <img src={require("../../../../../src/assets/recievedCall.svg").default} alt="info" />}

                            <FeatherIcon.Phone className="callLogPhoneIcon" />
                            <p className="callLogText">{d.toISOString().slice(0, 10) === item.starttime.slice(0, 10) ? "Today" : item.starttime.slice(0, 10)}</p>
                            <p className="callLogText">{item.starttime.slice(11, 19)}</p>
                          </div>
                          <img src={require("../../../../../src/assets/info.svg").default} alt="info" className="infoImage" onClick={() => modalToggler(item)} />
                        </div>
                      </div>
                    </div>
                  </li>
                )
              );
            })}
        </ul>
      );
    } else {
      return <ul className="list-group list-group-flush"></ul>;
    }
  };

  return (
    <div className="sidebar active">
      <header>
        <div className="d-flex align-items-center">
          <button onClick={mobileMenuBtn} className="btn btn-outline-light mobile-navigation-button mr-3 d-xl-none d-inline">
            <FeatherIcon.Menu />
          </button>
          <span className="sidebar-title">Call Logs</span>
        </div>
      </header>
      <div className="mb-3 callLogsMain">
        <button className={selectCalls === "All" ? "button togglebtn" : "button"} style={{ marginRight: "-17px" }} onClick={() => setSelectCalls("All")}>
          All
        </button>
        <button className={selectCalls === "Missed" ? "button togglebtn" : "button"} onClick={() => setSelectCalls("Missed")}>
          Missed
        </button>
      </div>

      <div className="sidebar-body">
        <PerfectScrollbar>
          <CallLogItems selectCalls={selectCalls} did={did} setContactModalOpen={setContactModalOpen} contactModalOpen={contactModalOpen} />
          {cookies.getItem("NewKey") && <ContactModal modal={contactModalOpen} toggle={() => setContactModalOpen(!contactModalOpen)} modalData={modalData} client={client} />}
        </PerfectScrollbar>
      </div>
    </div>
  );
}

export default Index;
