const initialState = {
    UserData : {},
    updatedData : {},
    updatedPassword : {},
   
}



const editProfileReducer = (state = initialState,{type,payload}) =>
{  
    switch(type) 
    {
        case 'GET_PROFILE' : return {
            ...state,
            UserData : payload
        };
        case 'UPDATE_PROFILE' : return {
            ...state,
            updatedData : payload
        };
        case 'UPDATE_PASSWORD' : return {
            ...state,
            updatedPassword : payload
        };
        case 'DELETE_ACCOUNT' : return {
            ...state,
            
        };
        default : return state
    }
}


export default editProfileReducer 