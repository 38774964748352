import React,{useRef, useEffect} from 'react'
import {Button} from 'reactstrap'
import * as FeatherIcon from 'react-feather'
import Picker from 'emoji-picker-react';

const EmojiSMS = ({setInputSms, setShowEmojiPicker, showEmojiPicker}) => {
    const emojiRef = useRef()
    const onEmojiClick = (event, emojiObject) => {
        setInputSms((prevState)=>{return `${prevState} ${emojiObject.emoji}`})
    };

    function handleClickOutside(event) {
        if (!emojiRef?.current?.contains(event?.target)){
            setShowEmojiPicker( false );
        }
      };

      function handleClickOutsidePress(event){
        if(event.key === 'Enter'){
          setShowEmojiPicker( false );
        } else if(event.key === 'Backspace'){
          setShowEmojiPicker( false );
          // setInputMsg((prevState)=>{return `${prevState.slice(0,prevState.length -3)}`})
        }
      }

    useEffect(()=>{
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("keydown", handleClickOutsidePress);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
          document.removeEventListener("keydown", handleClickOutsidePress);
        };
    },[])

  return (
    <>
    {showEmojiPicker && 
    <div ref={emojiRef} className="emojiBoxOpen">
        <Picker onEmojiClick={onEmojiClick} />
    </div> }
    <div>
        <Button  color="light" className="mr-3" title="Emoji" onClick={()=>{
            setShowEmojiPicker((prevState)=>{return !prevState})
        }}>
            <FeatherIcon.Smile/>
        </Button>
    </div>
    </>
  )
}

export default EmojiSMS