import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UnselectedChat from "../../assets/img/unselected-chat.svg";
import Tour from "reactour";
import TourModal from "./Modals/TourModal";
import SidebarIndex from "./Sidebars/index";
import Navigation from "./Navigation";
import Profile from "./Sidebars/Profile";
import Chat from "./ChatSection/Chat";
import { pageTourAction } from "../../Store/Actions/pageTourAction";
// import { profileAction } from "../../Store/Actions/Profile/profileAction";
import DisconnectedModal from "./Modals/DisconnectedModal";
import JitsiComponent from "./Jitsi";
import ReactLoading from "react-loading";
// import { JitsiAction } from "../../Store/Actions/Jitsi/JitsiAction";
import { LoadingAction } from "../../Store/Actions/Jitsi/LoadingAction";
import Sms from "./MessageSection/Sms";
// import SipCall from "../../Route/Sipml5";
// import CallInit from "./VoiceCall/CallInit";
import Cookies from "../../services/cookies";
import { didNumberAction } from "../../Store/Actions/DidNumber/didNumberAction";
import didNumberReducer from "../../Store/Reducers/didNumberReducer";

function Chats({ client, socket }) {
  const [callOpenModal, setCallOpenModal] = useState(false);
  const [CallReceived, setCallReceived] = useState("sender");
  const [isTokenFound, setTokenFound] = useState(false);
  const dispatch = useDispatch();
  const { jitsiProfile } = useSelector((state) => state);
  const { loadingProfile } = useSelector((state) => state);
  const { editProfile } = useSelector((state) => state);
  const [didSipCall, setDidSipCall] = useState(null);
  const jitsiToggle = jitsiProfile.isJistiopen;
  const { pageTour } = useSelector((state) => state);
  const { selectedSidebar } = useSelector((state) => state);
  const data = editProfile.UserData.payload;
  const username = data && `${data.data && data.data[0].firstname} ${data.data && data.data[0].lastname}`;
  const callRefButton = useRef(null);
  const answerRefButton = useRef(null);
  const hangUpRefButton = useRef(null);
  // console.log("RefrenceChat --->>>",callRefButton, hangUpRefButton, answerRefButton )
  const cookies = new Cookies();
  // console.log("check--->>>",cookies.getItem("UserDid"))
  useEffect(() => {
    if (cookies.getItem("UserDid")) {
      setDidSipCall(cookies.getItem("UserDid"));
      cookies.removeItem("UserDid");
    }
  }, []);

  // console.log("DidSipCall --->>>", didSipCall);

  useEffect(() => {
    document.body.classList.remove("form-membership");
    document.querySelector("*").addEventListener("click", (e) => {
      if (document.body.classList.contains("navigation-open") && e.target.nodeName === "BODY") {
        document.body.classList.remove("navigation-open");
      }
    });
  }, []);

  const handleClickOpenCallModal = () => {
    setCallOpenModal(true);
  };

  const handleCloseCallModal = () => {
    setCallOpenModal(false);
  };

  useEffect(() => {
    {
      jitsiToggle &&
        jitsiProfile.callerId !== null &&
        setTimeout(() => {
          dispatch(
            LoadingAction({
              loading: true,
            })
          );
        }, 6000);
    }
  }, [jitsiProfile.callerId]);

  return (
    <>
      {jitsiToggle === true ? (
        loadingProfile.loading === false ? (
          <div className="text-center ChatsContainer">
            <ReactLoading type={"bars"} color={"#"} height={100} width={100} />
          </div>
        ) : (
          <JitsiComponent name={jitsiProfile.callerId} username={username} loading={loadingProfile.loading} jitsiData={jitsiProfile} />
        )
      ) : (
        <>
          {/* {didSipCall && <SipCall callRefButton={callRefButton} answerRefButton={answerRefButton} hangUpRefButton={hangUpRefButton} setDidSipCall={setDidSipCall} didSipCall={didSipCall} callOpenModal={callOpenModal} setCallOpenModal={setCallOpenModal} CallReceived={CallReceived} setCallReceived={setCallReceived} />} */}
          {/* <CallInit callOpenModal={callOpenModal} answerRefButton = {answerRefButton} hangUpRefButton = {hangUpRefButton} setCallOpenModal={setCallOpenModal} CallReceived={CallReceived} setCallReceived={setCallReceived} handleCloseCallModal={handleCloseCallModal} handleClickOpenCallModal={handleClickOpenCallModal}/> */}
          <div className="layout">
            {/* <Tour steps={tourSteps} isOpen={pageTour} onRequestClose={() => dispatch(pageTourAction(false))} /> */}
            <div className="content">
              <Navigation />
              {editProfile && editProfile?.UserData?.payload?.data[0] !== undefined && (
                <>
                  <SidebarIndex
                    client={client}
                    callRefButton={callRefButton}
                    setCallOpenModal={setCallOpenModal}
                    socket={socket}
                    userId={editProfile?.UserData?.payload?.data[0]?.phone}
                  />
                  {selectedSidebar === "Messages" && <Sms />}
                  {selectedSidebar === "Chats" && <Chat userId={editProfile?.UserData?.payload?.data[0]?.phone} socket={socket} client={client} />}
                  {selectedSidebar !== "Chats" && selectedSidebar !== "Messages" && <NoChatIndex />}
                  <Profile />
                  <TourModal />
                </>
              )}
              <DisconnectedModal />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Chats;

const NoChatIndex = () => (
  <div className="chat">
    <div className="chat-body no-message">
      <div className="no-message-container">
        <div className="mb-5">
          <img src={UnselectedChat} width={200} className="img-fluid" alt="unselected" />
        </div>
        <p className="lead">Select a chat to read messages</p>
      </div>
    </div>
  </div>
);
