import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import axios from 'axios';
import Validation from '../../validation/validation';
import API from '../../services/api_endpoint';
import Loader from "../../component/load/loader";
import Cookies from '../../services/cookies';

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            error_email: null,
            loading: false
        }
        this.validation = new Validation();
        this.cookies = new Cookies();
    }

    componentDidMount() {
        if (this.cookies.loginStatus() === true)
            this.props.history.push('/')
        else
            this.cookies.logout();
        document.body.classList.add('form-membership');
    }

    inputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setStateData(name, value);
    }

    setData = (data) => {
        this.setStateData(Object.keys(data)[0], data[Object.keys(data)[0]]);
        return data[Object.keys(data)[0]]
    }

    setStateData = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    validate = (event) => {
        let name = event.target.name;
        const Enum = Object.freeze({ 'email': 1 });
        const value = Enum[name];

        switch (value) {
            case 1:
                this.setData(this.validation.validateEmail(this.state.email));
                break;
            default:
                break;
        }
    }

    submit = (event) => {
        event.preventDefault();

        let email = this.setData(this.validation.validateEmail(this.state.email));

        if (email === null) {
            this.setState({
                loading: true
            }, () => this.apiResetPassword());
        }
        else {
            return;
        }
    }

    apiResetPassword = () => {
        axios.post(`${API.reset_password}?email=${this.state.email}`)
            .then((response) => {
                this.setStateData('loading', false);
                // console.log(response.data);
                if (response.data.success === "true") {
                    alert(response.data.result);
                    this.setStateData('email', '');
                }
                else {
                    alert(response.data.result);
                }
            })
            .catch((error) => {
                this.setStateData('loading', false);
                if (error && error.response) {
                    if (error.response.status === 400) {
                        alert(error.response.data.message);
                    }
                    else if (error.response.status === 401) {
                        alert(error.response.data.message);
                    }
                    else if (error.response.status === 404) {
                        alert(error.response.data.message);
                    }
                    else if (error.response.status === 500) {
                        alert("Internal server error");
                    }
                    else {
                        alert("Something went wrong. Please try again later.");
                    }
                }
                else {
                    alert("Something went wrong. Please try again later.");
                }
            })
    }

    render() {
        return (
            <div className="form-wrapper">
                <div className="logo">
                    {/* <Logo/> */}
                    <img width="150px" height="120px" src={require('./../../assets/logo.png').default} alt="logoImg" />
                </div>
                <h5>Retrieve Password</h5>
                <form onSubmit={this.submit}>
                    <div className="form-group">
                        <input type="text" className="form-control" name="email" placeholder="Email Id" onChange={this.inputChange} onBlur={this.validate} value={this.state.email} />
                        {this.state.error_email === null ? <></> : <span className="error_text">{this.state.error_email}</span>}
                    </div>
                    <button className="btn btn-primary btn-block">Send Password</button>
                    <hr />
                    <p className="text-muted">Take a different action.</p>
                    <NavLink to="/sign-up" className="btn btn-sm btn-outline-light mr-1 color1">Register now!</NavLink>
                    or
                    <NavLink to="/sign-in" className="btn btn-sm btn-outline-light ml-1 color1">Login!</NavLink>
                </form>
                <Loader loading={this.state.loading} />
            </div>
        );
    }
}
export default withRouter(ResetPassword);