import * as React from 'react';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import './imgmodal.scss'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  minHeight: '60vh',
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   borderRadius:6,
  p: 1,
  display:'flex',
  outline:'none',
};

function ImgModal({open, handleClose, imgShow}) {
  

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="imgWrapModal">
          <div className='imageContainer'>
            <CloseIcon className='closeWrap' onClick={handleClose} />
            <img src={imgShow.src || ''} alt='image' style={{width:'100%',height:'100%',borderRadius:'6px',margin:'auto'}} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default ImgModal