import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import UnselectedChat from "../../../assets/img/unselected-chat.svg";
import { useSelector } from "react-redux";
import SmsView from "./SmsView";
import axios from "axios";
import Cookies from "../../../services/cookies";
import API from "../../../services/api_endpoint";
import SmsFooter from "./SmsFooter";
import SmsHeader from "./SmsHeader";

function Sms() {
  const cookies = new Cookies();

  const [smsFriendListNumber, setSmsFriendListNumber] = useState(null);
  const { messageSms } = useSelector((state) => state);
  const [messageArray, setMessageArray] = useState(messageSms);
  const { editProfile } = useSelector((state) => state);
  const [payloadMessage, setPayloadMessage] = useState({
    created_at: "",
    num_a: "",
    num_b: "",
    message: "",
    cc_card_id: "",
  });
  const scrollRef = useRef();
  useEffect(() => {
    setMessageArray(messageSms);
  }, [messageSms]);

  const outgoing_msg = (message) => {
    let par = {
      text: message.message,
      to: messageSms[0]?.num_b,
      from: editProfile.UserData.payload.data[0].dids[0].did,
      auth_key: cookies.getItem("KEY"),
    };
    if (cookies.getItem("UserDid")) {
      par = { ...par, to: cookies.getItem("UserDid") };
    }

    axios
      .post(`${API.outgoing_msg}`, null, {
        params: par,
      })
      .then((response) => {
        setPayloadMessage({
          num_a: editProfile.UserData.payload.data[0].dids[0].did,
          num_b: messageArray[0]?.num_b,
          created_at: messageArray[0]?.created_at,
          message: message.message,
          cc_card_id: editProfile.UserData.payload.data[0].id,
        });
        const { data } = response;
        // console.log(data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (newValue) => {
    if (!(editProfile.UserData.payload.data[0].dids.length > 0)) {
      alert("virtual number not found");
    } else {
      outgoing_msg(newValue);
    }
  };

  useEffect(() => {
    if (messageArray.length >= 0 && payloadMessage) {
      const arr = [...messageArray];
      if (payloadMessage && payloadMessage.message) {
        arr.push(payloadMessage);
        let value = [payloadMessage, ...messageArray];
        setMessageArray(value);
        setPayloadMessage(null);
      }
    }
  }, [payloadMessage]);

  useLayoutEffect(() => {
    if (scrollRef?.current) {
      scrollRef.current.scrollIntoView();
    }
  });

  const getContactSync = () => {
    axios
      .get(`${API.getContact}?userId=${editProfile?.UserData?.payload?.data[0]?.id}`)
      .then((response) => {
        const { data } = response;
        setSmsFriendListNumber(data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getContactSync();
  }, []);

  return (
    <div className="sms">
      {messageSms.length > 0 || cookies.getItem("UserDid") ? (
        <React.Fragment>
          <SmsHeader messageSms={messageSms} smsFriendNameHeader={smsFriendListNumber} />
          {/* {console.log("Friendname--->>>", smsFriendListNumber)} */}
          <PerfectScrollbar>
            <div className="sms-body">
              <div className="messages">
                {messageArray?.length > 0 || cookies.getItem("UserDid")
                  ? messageArray?.reverse().map((sms, i) => {
                      return <SmsView sms={sms} key={i} id={editProfile.UserData.payload.data[0].id} />;
                    })
                  : null}
              </div>
              <span ref={scrollRef} />
            </div>
          </PerfectScrollbar>
          <SmsFooter onSubmit={handleSubmit} />

          {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~  uncomment to enable xmpp ~~~~~~~~~~~~~~~~~~~ */}
        </React.Fragment>
      ) : (
        <div className="sms-body no-message">
          <div className="no-message-container">
            <div className="mb-5">
              <img src={UnselectedChat} width={200} className="img-fluid" alt="unselected" />
            </div>
            {editProfile.UserData?.payload?.data[0]?.dids[0]?.did?.length > 0 ? (
              <p className="lead">Select a chat to read messages</p>
            ) : (
              <p className="lead">You need a Virtual Number to use this feature</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Sms;
