
import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import Cookies from './../../services/cookies';

function PrivateRoute({ children, ...rest }) {
    let cookies = new Cookies();
    return (
      <Route
        {...rest}
        render={({ props }) =>
          (cookies.loginStatus() === true) ? (
            children
          ) : (
            // alert('Login to access this section.'),
            <Redirect
              to={{
                pathname: "/sign-in",               
              }}
            />
          )
        }
      />
    );
  }

  export default PrivateRoute;