import React from 'react'
import {Button, Input} from 'reactstrap'
import * as FeatherIcon from 'react-feather'
import WomenAvatar5 from "../../../assets/img/women_avatar5.jpg";
import EmojiSMS from './Extra/emojiSms'
import {useSelector} from "react-redux";
import { useState, useEffect } from 'react';

function SmsFooter(props) {
    // const { sms } = props;
    const [inputSms, setInputSms] = useState('')
    const { messageSms } = useSelector((state) => state);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();
        if(inputSms.length>0) {
            props.onSubmit({
                avatar: <figure className="avatar">
                    <img src={WomenAvatar5} className="rounded-circle" alt="avatar"/>
                </figure>,
                message: inputSms,
                date: 'Now',
                type: 'outgoing-message', 
            })
        }
        setInputSms("")
        setShowEmojiPicker(false)
    };

    const handleChange = (e) => {
        setInputSms(e.target.value)
    };

    useEffect(()=>{
        setInputSms('')
    },[messageSms])

    return (
        <div className="sms-footer">
            <form onSubmit={handleSubmit}>
                <EmojiSMS setInputSms={setInputSms} showEmojiPicker ={showEmojiPicker} setShowEmojiPicker={setShowEmojiPicker} />
                <Input type="text" className="form-control" placeholder="Write a message." value={inputSms} onChange={handleChange}/>
                <div className="form-buttons">
                    <Button color="primary">
                        <FeatherIcon.Send/>
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default SmsFooter
