import React from 'react'
import { useSelector } from 'react-redux';
import ManAvatar1 from "../../../assets/img/women_avatar5.jpg";
const SmsView = (props) => {
    const { editProfile } = useSelector((state) => state);
    const { sms } = props;
    if (sms.type === 'divider') {
        return <div className="message-item messages-divider sticky-top" data-label={sms?.message}></div>
    } else {
        return <div className={`message-item ${sms.num_a === editProfile?.UserData?.payload?.data[0]?.dids[0].did && "outgoing-message"
            }`}>
            <div className="message-avatar">
                <img
                    src={ManAvatar1}
                    alt="avtar"
                    className="rounded-circle profileImageSms"
                />
                <div>
                    <h5>{sms?.num_a}</h5>
                    <div className="time">
                        {sms?.created_at?.slice(11, 16)}
                        {sms.type ? <i className="ti-double-check text-info"></i> : null}
                    </div>
                </div>
            </div>
            {
                sms.media
                    ?
                    (
                        sms.media
                    ) : (
                        <div className="message-content">{sms.message}</div>
                    )
            }
        </div>
    }
}
export default SmsView;