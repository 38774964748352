import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Input } from "reactstrap";
import './textfieldst.scss'

const TextFieldST = ({ userId, socket, receiverId, inputMsg, setInputMsg, roomId, mediaselected, setMediaSelected, setCursorPosition, btnSubmitRef }) => {
  const { selectedSidebar } = useSelector((state) => state);
  const handleChange = (e) => {
    setInputMsg(e.target.value);
    userFocusHandle();
    // const delayDebounceFn = setTimeout(() => {
    //   userBlurHandle()
    // }, 2000)
    // return () => clearTimeout(delayDebounceFn)
  };

  function userBlurHandle() {
    const options = {
      roomId: roomId,
      receiverId: receiverId,
      userId: userId,
      typing: false,
    };
    if(receiverId?.length > 0){
      socket.emit("typing", options);
    }
    // if(receiverId.length == 1 ){
    // } 
  }

  const userFocusHandle = () => {
    const options = {
      roomId: roomId,
      receiverId: receiverId,
      userId: userId,
      typing: true,
    };
    if(receiverId.length == 1 ){
      socket.emit("typing", options);
    }
  };


  useEffect(()=>{
    document.addEventListener('mouseleave', (event) => {
      userBlurHandle()
    });
    
      document.addEventListener('keypress', (event) => {
      if(event.code == 'Enter'){
        if(selectedSidebar == "Chats"){
          if(btnSubmitRef.current){
            btnSubmitRef.current.click();
          }
        }
      }
   
  })
    return () => {
      userBlurHandle();
    }
  },[mediaselected]);

  return (
    <Input
      disabled={["audio", "video", "image", "file"].includes(mediaselected)}
      type="text"
      className="form-control textcontrolinput"
      placeholder="Write a message."
      value={inputMsg}
      onBlur={userBlurHandle}
      onMouseUp={(e) => {
        setCursorPosition(e.target.selectionStart);
      }}
      onChange={handleChange}
      onFocus={userFocusHandle}
    />
  );
};

export default TextFieldST;
