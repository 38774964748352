import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectedChatAction } from "../../../../Store/Actions/SelectedChat/selectedChatAction";
import { formatToTime } from "../../../../services/helpers/ConvertTime";
import "./chatlistview.scss";
import ChatDropdown from "./ChatDropdown";

const ChatListView = ({ chat, userId, friendName, socket, setFriendList }) => {
  // Accept setFriendList as a prop
  const dispatch = useDispatch();
  const { selectedChat } = useSelector((state) => state);

  const chatSelectHandle = (chat) => {
    if (selectedChat?.chat?.conversationId === chat?.conversationId) {
    } else {
      chat.unread_messages = 0;
      dispatch(
        selectedChatAction({
          id: chat.conversationId,
          chat: chat,
          friendName: friendName,
        })
      );
      document.querySelector(".chat").classList.add("open");
    }
  };

  const deleteChatHandle = (chatId) => {
    socket.emit("delete-conversation", { conversationId: chatId, userId });
    setFriendList((prevFriendList) => prevFriendList.filter((chat) => chat.conversationId !== chatId));
  };

  return (
    <li className={"list-group-item " + (chat.conversationId === selectedChat.id ? "open-chat" : "")}>
      {chat.avatar}
      <div className="users-list-body">
        <div onClick={() => chatSelectHandle(chat)}>
          <h5 className={""}>{friendName}</h5>
          <p className="lastmessage">{chat?.message ? chat.message : ["audio", "video", "image", "file"].includes(chat.messageType) ? "send a media" : "..."}</p>
        </div>
        <div className="users-list-action">
          {chat.unread_messages ? <div className="new-message-count">{chat.unread_messages}</div> : ""}

          <div className="action-toggle">
            <ChatDropdown item={chat} deleteChatHandle={() => deleteChatHandle(chat.conversationId)} />
          </div>
          <br />
          {chat.messageTime && <small className={chat.unread_messages ? "text-primary" : "text-muted"}>{formatToTime(chat.messageTime) + " PM"}</small>}
        </div>
      </div>
    </li>
  );
};

export default ChatListView;
