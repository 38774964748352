import React from 'react';
import './style.css';

class Loader extends React.Component{
    render(){
        return(
            <>
                { this.props.loading === true ? <><div className="loader"></div><div className="loading"><div className="spinner-border"></div> Please wait...</div></> : null}
            </>
        );
    }
}
export default Loader;