import React, { useEffect, useState, useRef } from "react";
import { Button } from "reactstrap";
import * as FeatherIcon from "react-feather";
import { useSelector } from "react-redux";
import EmojiST from "./Extra/EmojiST";
import VideoST from "./Extra/VideoST";
import DocumentST from "./Extra/DocumentST";
import ImageST from "./Extra/ImageST";
import AudioST from "./Extra/AudioST";
import TextFieldST from "./Extra/TextFieldST";
import axios from "axios";
import "./chatfooter.scss";

function ChatFooter({ userId, receiverId, socket, chat }) {
  const btnSubmitRef=useRef(null)
  const { selectedChat, editProfile } = useSelector((state) => state);
  const [images, setImages] = useState(null);
  const [document, setdocument] = useState(null);
  const [video, setVideo] = useState(null);
  const [audio, setAudio] = useState(null);
  const [inputMsg, setInputMsg] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [mediaselected, setMediaSelected] = useState("");
  const [mediaArray, setMediaArray] = useState(null);
  const [submitBtnStatus, setSubmitBtnStatus] = useState(false)
  const [progressStatus, setProgressStatus] = useState({
    loading: false,
    completed: false,
  });
  const [cursorPosition, setCursorPosition] = useState(null)
  const { firstname, lastname } = editProfile?.UserData?.payload?.data[0]

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = new FormData();
    // if ((mediaselected !== "audio") || (mediaselected !== "document") || (mediaselected !== "image") || (mediaselected !== "video")) {
    if (!["audio", "video", "image", "file"].includes(mediaselected)) {
      if (inputMsg.length > 0) {
        if(inputMsg.trim().length>0){
          handleSubmitText(inputMsg);
          setInputMsg("");
          setShowEmojiPicker(false);
        }
      }
    } else {
      if(!submitBtnStatus){

      
        setSubmitBtnStatus(true)
      setProgressStatus({
        loading: true,
        completed: false,
      });
      // console.log("channelId console.log",mediaselected)
      // console.log("channelId  - - -- >", chat.channelId);
      // console.log("channelId  - - mediaArray-- >", mediaArray);
      form.append("channelId", chat?.roomId);
      form.append("userId", userId);
      mediaArray.map((item) => {
        // const file = new File([item], `${item.name}`)
        form.append("files", item);
      });

      axios
        .post(`https://hfresh.co:3111/api/sendFile`, form)
        .then((res) => {
          if (res.status === false) {
            alert("Invalid File");
            setProgressStatus({
              loading: false,
              completed: false,
            });
            setImages(null);
            setdocument(null);
            setVideo(null);
            setAudio(null);
            setMediaSelected("");
            setSubmitBtnStatus(false)
          } else {
            setProgressStatus({
              loading: false,
              completed: true,
            });
            mediaSocketSubmit(res.data.data);
            setSubmitBtnStatus(false)
          }
        })
        .catch((e) => {
          alert("Can't send this media. Choose a different media and try again.");
          setMediaSelected("");
          setProgressStatus({
            loading: false,
            completed: false,
          });
          setImages(null);
          setdocument(null);
          setVideo(null);
          setAudio(null);
          setSubmitBtnStatus(false)
        });
      // handleMediaSubmit();
    }
  }
  };

  const mediaSocketSubmit = (media) => {
    for (let index = 0; index < media.length; index++) {
      const now = new Date();
      const utcMilllisecondsSinceEpoch = now.getTime() - now.getTimezoneOffset() * 60 * 1000;
      const utcSecondsSinceEpoch = Math.round(utcMilllisecondsSinceEpoch / 1000);
      const file = media[index]?.filename.split(".");
      let options = {
        // senderId: userId,
        // roomName: "",
        // chatType: receiverId.length > 1 ? 2 : 1,
        // participantIds: receiverId,
        // message: media[index]?.imageUrl || '',
        // messageType: mediaselected,
        // messageTime: utcSecondsSinceEpoch,
        // chatName: chat?.chatName || "",
        // imageUrl: media[index]?.imageUrl || '',
        hasAttachments: true,
        fileName: media[index]?.filename,
        filePath: media[index]?.path,
        fileOriginalName: media[index]?.originalname.length > 40 ? media[index]?.originalname.slice(0,38)+"."+media[index]?.originalname.split('.')[media[index]?.originalname.split('.').length-1] : media[index]?.originalname,
        fileExtension: file[file.length - 1],
        senderId: userId,
        senderName: firstname + " " + lastname,
        roomId:chat?.roomId || "",
        roomName: "",
        chatName: "",

        chatType: chat?.chatType ? chat?.chatType : receiverId?.length > 1 ? 2 : 1,
        participantIds: receiverId,
        message: "",
        // message: media[index]?.imageUrl || "https://dummyimage.com/50x45/000/fff",
        messageType: mediaselected,
        messageTime: utcSecondsSinceEpoch,
      };
      if (window.localStorage.getItem("newFriend")) {
        options = {
          ...options,
          participantIds: [parseInt(window.localStorage.getItem("newFriend"))],
        };
      }
      socket.emit("send-message", options);
      setMediaSelected('')
    }
    setImages(null);
    setdocument(null);
    setVideo(null);
    setAudio(null);
    setShowEmojiPicker(false);
    setProgressStatus({
      loading: false,
      completed: false,
    });
  };

  const handleSubmitText = (newValue) => {
    const now = new Date();
    const utcMilllisecondsSinceEpoch = now.getTime() - now.getTimezoneOffset() * 60 * 1000;
    const utcSecondsSinceEpoch = Math.round(utcMilllisecondsSinceEpoch / 1000);
    let options = {
      senderId: userId,
      senderName: firstname + " " + lastname,
      roomName: "",
      chatName: "",
      roomId:chat?.roomId || "",
      chatType: chat?.chatType ? chat?.chatType : receiverId?.length > 1 ? 2 : 1,
      participantIds:receiverId,
      message: newValue,
      messageType: "text",
      messageTime: utcSecondsSinceEpoch,
    };
    if (window.localStorage.getItem("newFriend")) {
      options = { ...options, participantIds: [(window.localStorage.getItem("newFriend"))] };
    }
    socket.emit("send-message", options);
    setInputMsg("");
  };

  useEffect(() => {
    setInputMsg("");
  }, [selectedChat]);

  return (
    <>
      {(progressStatus.loading === true || progressStatus.completed === true) && (
        <div className="wrapper">
          <progress
            id="bar"
            className={`bar load-progress ${progressStatus.loading && "is-loading"}  ${progressStatus.completed && "is-done"}`}
            style={{ animationDuration: "10s" }}
            max="1"
            value="-1"
          ></progress>
        </div>
      )}
      <div className="chat-footer">
        <form onSubmit={handleSubmit}>
          <EmojiST setInputMsg={setInputMsg} showEmojiPicker={showEmojiPicker} setShowEmojiPicker={setShowEmojiPicker} cursorPosition={cursorPosition} setCursorPosition={setCursorPosition} />
          <TextFieldST
            userId={userId}
            socket={socket}
            receiverId={receiverId}
            inputMsg={inputMsg}
            setInputMsg={setInputMsg}
            mediaselected={mediaselected}
            setMediaSelected={setMediaSelected}
            roomId = {chat?.roomId}
            setCursorPosition={setCursorPosition}
            btnSubmitRef={btnSubmitRef}
          />
          <div className="form-buttons">

            <ImageST
              mediaselected={mediaselected}
              setMediaSelected={setMediaSelected}
              setMediaArray={setMediaArray}
              setImages={setImages}
              images={images}
              submitBtnStatus={submitBtnStatus}
            />

            <DocumentST
              mediaselected={mediaselected}
              setMediaSelected={setMediaSelected}
              mediaArray={mediaArray}
              setMediaArray={setMediaArray}
              setdocument={setdocument}
              document={document}
              submitBtnStatus={submitBtnStatus}
            />

            <VideoST
              mediaselected={mediaselected}
              setMediaSelected={setMediaSelected}
              mediaArray={mediaArray}
              setMediaArray={setMediaArray}
              setVideo={setVideo}
              video={video}
              submitBtnStatus={submitBtnStatus}
            />

            <AudioST
              mediaselected={mediaselected}
              setMediaSelected={setMediaSelected}
              mediaArray={mediaArray}
              setMediaArray={setMediaArray}
              setAudio={setAudio}
              audio={audio}
              submitBtnStatus={submitBtnStatus}
            />

            <Button id="chatmsgid" type="submit" color="primary" innerRef={btnSubmitRef}>
              <FeatherIcon.Send />
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

export default ChatFooter;
