import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import axios from 'axios';
import Validation from '../../validation/validation';
import API from '../../services/api_endpoint';
import Loader from "../../component/load/loader";
import Cookies from '../../services/cookies';

class Signup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            // city: '',
            state: '',
            zipcode: '',
            fax: '',
            address: '',
            company_name: '',
            error_first_name: null,
            error_last_name: null,
            error_email: null,
            error_phone: null,
            error_city: null,
            error_state: null,
            error_zipcode: null,
            error_fax: null,
            error_address: null,
            error_company_name: null,
            error: null,
            loading: false
        }
        this.validation = new Validation();
        this.cookies = new Cookies();
    }

    componentDidMount() {
        if (this.cookies.loginStatus() === true)
            this.props.history.push('/')
        else
            this.cookies.logout();

        if (this.props.location?.state === undefined)
            this.props.history.push('/sign-up');
        else
            this.setState({
                phone: this.props.location?.state
            })
        document.body.classList.add('form-membership');
    }

    inputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setStateData(name, value);
    }

    setData = (data) => {
        this.setStateData(Object.keys(data)[0], data[Object.keys(data)[0]]);
        return data[Object.keys(data)[0]]
    }

    setStateData = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    validate = (event) => {
        let name = event.target.name;
        const Enum = Object.freeze({ 'first_name': 1, 'last_name': 2, 'email': 3, 'phone': 4, 'city': 5, 'state': 6, 'zipcode': 7, 'fax': 8, 'address': 9, 'company_name': 10 });
        const value = Enum[name];

        switch (value) {
            case 1:
                this.setData(this.validation.validateFirstName(this.state.first_name));
                break;
            case 2:
                this.setData(this.validation.validateLastName(this.state.last_name));
                break;
            case 3:
                this.setData(this.validation.validateEmail(this.state.email));
                break;
            case 4:
                this.setData(this.validation.validatePhoneNumber(this.state.phone));
                break;
            case 5:
                this.setData(this.validation.validateCity(this.state.city));
                break;
            case 6:
                this.setData(this.validation.validateState(this.state.state));
                break;
            case 7:
                this.setData(this.validation.validateZipCode(this.state.zipcode));
                break;
            case 8:
                this.setData(this.validation.validateFax(this.state.fax));
                break;
            case 9:
                this.setData(this.validation.validateAddress(this.state.address));
                break;
            case 10:
                this.setData(this.validation.validateCompanyName(this.state.company_name));
                break;
            default:
                break;
        }
    }

    submit = (event) => {
        event.preventDefault();

        let first_name = this.setData(this.validation.validateFirstName(this.state.first_name));
        let last_name = this.setData(this.validation.validateLastName(this.state.last_name));
        let email = this.setData(this.validation.validateEmail(this.state.email));
        let phone = this.setData(this.validation.validatePhoneNumber(this.state.phone));
        if (first_name === null && last_name === null && email === null) {
            this.setState({
                loading: true
            }, () => this.apiSignup());
        }
        else {
            return;
        }
    }

    apiSignup = () => {
        axios.post(`${API.signup}?firstname=${this.state.first_name}&lastname=${this.state.last_name}&email=${this.state.email}&address=${this.state.address}&city=${this.state.city}&state=${this.state.state}&zipcode=${this.state.zipcode}&phone=${this.state.phone}&fax=${this.state.fax}&companyname=${this.state.company_name}`)
            .then((response) => {
                this.setStateData('loading', false);
                if (response.data.success === "true") {
                    console.log("SignUpResponse--->>>", response)
                    alert("Registration completed and your Id and Password have been sent to your email id.");
                    this.props.history.push('/sign-in');
                }
                else {
                    alert(response.data.result);
                }
            })
            .catch((error) => {
                this.setStateData('loading', false);
                if (error && error.response) {
                    if (error.response.status === 400) {
                        alert(error.response.data.message);
                    }
                    else if (error.response.status === 401) {
                        alert(error.response.data.message);
                    }
                    else if (error.response.status === 404) {
                        alert(error.response.data.message);
                    }
                    else if (error.response.status === 500) {
                        alert("Internal server error");
                    }
                    else {
                        alert("Something went wrong. Please try again later.");
                    }
                }
                else {
                    alert("Something went wrong. Please try again later.");
                }
            }
            )
    }

    render() {
        return (
            <div className="container">
                <div className="form-wrapper">
                    <div className="logo">
                        <img width="150px" height="120px" src={require('./../../assets/logo.png').default} alt="logoImg" />
                    </div>
                    <h5>Register</h5>
                    <p>Let's create you SwitchTell profile, enter your details.</p>
                    <form onSubmit={this.submit}>
                        <div className="form-group">
                            <input type="text" className="form-control" name="first_name" value={this.state.first_name} onChange={this.inputChange} onFocus={() => this.setState({ error_first_name: "" })} onBlur={this.validate} placeholder="First Name" />
                            {this.state.error_first_name === null ? <></> : <span className="error_text">{this.state.error_first_name}</span>}
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control" name="last_name" value={this.state.last_name} onChange={this.inputChange} onFocus={() => this.setState({ error_last_name: "" })} onBlur={this.validate} placeholder="Last Name" />
                            {this.state.error_last_name === null ? <></> : <span className="error_text">{this.state.error_last_name}</span>}
                        </div>

                        <div className="form-group">
                            <input type="email" className="form-control" name="email" value={this.state.email} onChange={this.inputChange} onFocus={() => this.setState({ error_email: "" })} onBlur={this.validate} placeholder="Email" />
                            {this.state.error_email === null ? <></> : <span className="error_text">{this.state.error_email}</span>}
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control" name="phone" readOnly value={this.state.phone} placeholder="Phone" />
                        </div>

                        <button className="btn btn-primary btn-block">Register</button>
                        <p className="text-muted mt-3">By register you are agree to our <NavLink className="color1" to="/terms-and-conditions">Terms & conditions</NavLink> <NavLink className="color1" to="/privacy-policy">& Privacy policy</NavLink></p>
                        <hr />
                        <p className="text-muted">Already have an account?</p>
                        <NavLink to="/sign-in" className="btn btn-outline-light btn-sm color1">LOGIN</NavLink>
                    </form>
                </div>
                <Loader loading={this.state.loading} />
            </div>
        );
    }
}
export default withRouter(Signup);
