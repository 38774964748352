import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import * as FeatherIcon from 'react-feather'
import 'react-perfect-scrollbar/dist/css/styles.css'
import PerfectScrollbar from 'react-perfect-scrollbar'
import MessageListView from './MessageListView'
import axios from 'axios'
import API from '../../../../services/api_endpoint'
import Cookies from "../../../../services/cookies"
import { messageAction } from '../../../../Store/Actions/Message/messageAction'
import { userSmsNameFriend } from '../../../../services/helpers/SmsFriendsName'


function Index() {
    const dispatch = useDispatch();
    const cookies = new Cookies()
    const [smsSideBar, setSmsSideBar] = useState([]);
    // const [tooltipOpen, setTooltipOpen] = useState(false);
    const [mappingObj, setMappingObj] = useState('')
    const { editProfile } = useSelector((state) => state);
    const [smsFriendListNumber, setSmsFriendListNumber] = useState(null);
    // const toggle = () => setTooltipOpen(!tooltipOpen);
    const mobileMenuButton = () => document.body.classList.toggle('navigation-open');

    const allMessages = () => {
        axios.post(`${API.inbound_outbound_msg}?auth_key=${cookies.getItem("KEY")}`)
            .then(response => {
                let result = []
                let mapObj = {}
                let userNumber = editProfile.UserData?.payload?.data[0]?.dids[0]?.did
                response?.data?.data.forEach(el => {
                    if ((el.num_b !== userNumber && el.num_a === userNumber)) {
                        if (mapObj[el.num_b]) {
                            console.log(mapObj[el.num_b])
                        } else {
                            result.push(el)
                            mapObj[el.num_b] = true
                        }
                    }
                })
                let userNumb = editProfile.UserData?.payload?.data[0]?.dids[0]?.did
                const filteredArray = response?.data?.data.filter(function (res, eachMsg) {
                    if ((eachMsg.num_a === userNumb) || (eachMsg.num_b === userNumb)) {
                        return eachMsg
                    }
                    return res
                })

                if (cookies.getItem("UserDid")) {
                    const userDiDObj = {
                        num_a: editProfile.UserData.payload.data[0].dids[0].did,
                        num_b: cookies.getItem("UserDid"),
                        created_at: new Date().toLocaleTimeString(),
                        cc_card_id: editProfile.UserData.payload.data[0].id
                    }
                    let number = result.map((item) => {
                        return item.num_b
                    })
                    const filteredArrayInitiate = filteredArray.filter((res) => {
                        if ((res.num_b === cookies.getItem("UserDid")) || (res.num_a === cookies.getItem("UserDid"))) {
                            return res
                        }
                    })
                    if (!number.includes(userDiDObj.num_b)) {
                        setSmsSideBar([userDiDObj, ...result])
                    } else {
                        if (number.includes(userDiDObj.num_b)) {
                            dispatch(messageAction(filteredArrayInitiate))
                            cookies.removeItem("UserDid")
                        }
                        setSmsSideBar([...result]);
                    }
                    // setSmsSideBar([userDiDObj,...result])
                } else {
                    setSmsSideBar(result);
                }
                setMappingObj(filteredArray);
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        allMessages();
    }, [])

    useEffect(() => {
        return () => {
            cookies.removeItem("UserDid")
        };
    }, [])

    const getContactSync = () => {
        axios
            .get(`${API.getContact}?userId=${editProfile?.UserData?.payload?.data[0]?.id}`)
            .then((response) => {
                const { data } = response;
                setSmsFriendListNumber(data?.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        getContactSync();
    }, []);

    return (
        <div className="sidebar active">
            <header>
                <div className="d-flex align-items-center">
                    <button onClick={mobileMenuButton} className="btn btn-outline-light mobile-navigation-button mr-3 d-xl-none d-inline">
                        <FeatherIcon.Menu />
                    </button>
                    <span className="sidebar-title">Messages</span>
                </div>
            </header>
            {/* <form>
                <input type="text" className="form-control" placeholder="Search Messages" />
            </form> */}
            <br/>
            <hr/>
            <div className="sidebar-body" style={{ backgroundColor: '#fff' }}>
                <PerfectScrollbar>
                    <ul className="list-group list-group-flush">
                        {
                            smsSideBar?.map((sms, i) => sms?.num_b !== "" ? (<MessageListView sms={sms} mappingObj={mappingObj} smsFriendName={userSmsNameFriend(sms, mappingObj, smsFriendListNumber)} />) : "")
                        }
                    </ul>
                </PerfectScrollbar>
            </div>
        </div>
    )
}

export default Index
