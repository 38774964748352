import axios from "axios"
import API from "../../../services/api_endpoint"
import Cookies from "../../../services/cookies";
import { subscribeAction } from "./subscribeAction";
import { getProfileData } from "../EditProfile/editProfileFetch";


const cookies = new Cookies();

const subscribeVirtualNumber = (virtualNumber) => {
    return async (dispatch) => {
        const response = await axios.post(`${API.subscribe}?auth_key=${cookies.getItem('KEY')}&did=${virtualNumber}`)
        const {data} = response;
        dispatch(getProfileData())
        dispatch(subscribeAction({type : 'SUBSCRIBE',payload : {...data}}))
    }
}

export {subscribeVirtualNumber};