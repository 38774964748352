

const JitsiReducer = (state =  {
    isJistiopen : false,
    callerId : null,
    room:null,
    startWithVideoMuted: true
},{type,payload}) =>
{  
    switch(type) 
    {   
        case 'Jitsi_Profile' : console.log('hi there bye the',payload); return payload;
    
        default : return state
    }
}

export default JitsiReducer; 