import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Autocomplete, TextField } from "@mui/material";
import EmojiST from "../ChatSection/Extra/EmojiST";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import * as FeatherIcon from "react-feather";

const AddGroupForm = ({ friendList, modalToggle, userId, socket, modal }) => {
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);
  const [inputMsg, setInputMsg] = useState("");
  const [inputInvitationMsg, setInputInvitationMsg] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const [errorStatus, setErrorStatus] = useState({
    name: {
      title: "Please enter the group Name",
      status: true,
    },
    participants: {
      title: "Please select 2 minimum friends",
      status: true,
    },
    message: {
      title: "Please enter the Message",
      status: true,
    },
    status: false,
  });

  const handleSelectChange = (event,value) => {
    // const value = event.target.value;
    console.log('valueAutocomplete',value);

    if (value.length > 1) {
      setErrorStatus((prevStat) => {
        return {
          ...prevStat,
          participants: {
            title: prevStat.participants.title,
            status: false,
          },
        };
      });
    } else {
      setErrorStatus((prevStat) => {
        return {
          ...prevStat,
          participants: {
            title: prevStat.participants.title,
            status: true,
          },
        };
      });
    }
    // setPersonName(
    //   // On autofill we get a stringified value.
    //   typeof value === "string" ? value.split(",") : value
    // );
    let phoneArr = []
    value.map((item)=>{
      phoneArr.push(item?.phone)
    })
    setPersonName([...phoneArr])
  };

  const handleChange = (e) => {
    if (e.target.value.length > 0) {
      setErrorStatus((prevStat) => {
        return {
          ...prevStat,
          name: {
            title: prevStat.name.title,
            status: false,
          },
        };
      });
    } else {
      setErrorStatus((prevStat) => {
        return {
          ...prevStat,
          name: {
            title: prevStat.name.title,
            status: true,
          },
        };
      });
    }
    setInputMsg(e.target.value);
  };

  const handleInvitationChange = (e) => {
    if (e.target.value.length > 0) {
      setErrorStatus((prevStat) => {
        return {
          ...prevStat,
          message: {
            title: prevStat.message.title,
            status: false,
          },
        };
      });
    } else {
      setErrorStatus((prevStat) => {
        return {
          ...prevStat,
          message: {
            title: prevStat.message.title,
            status: true,
          },
        };
      });
    }
    setInputInvitationMsg(e.target.value);
  };

  const groupSubmitHandle = (e) => {
    if (
      errorStatus.name.status === true ||
      errorStatus.participants.status === true ||
      errorStatus.message.status === true
    ) {
      setErrorStatus((prevStat) => {
        return {
          ...prevStat,
          status: true,
        };
      });
    } else {
      let payload = {
        groupName: inputMsg,
        members: personName,
        inputInvitationMsg: inputInvitationMsg,
      };

      const now = new Date();
      const utcMilllisecondsSinceEpoch =
        now.getTime() - now.getTimezoneOffset() * 60 * 1000;
      const utcSecondsSinceEpoch = Math.round(
        utcMilllisecondsSinceEpoch / 1000
      );
      let options = {
        senderId: userId,
        // receiverId: selectedChat.id,
        roomName: "",
        chatName: inputMsg,
        chatType: 2,
        // participantIds: [2504100113],
        participantIds: personName,
        message: inputInvitationMsg,
        messageType: "text",
        messageTime: utcSecondsSinceEpoch,
      };
      socket.emit("send-message", options);
      modalToggle();
    }
  };

  return (
    <>
      {friendList && (
        <>
          <Modal
            className="modal-dialog-zoom"
            isOpen={modal}
            toggle={modalToggle}
            centered
          >
            <ModalHeader toggle={modalToggle}>
              <FeatherIcon.UserPlus className="mr-2" /> Add Group
            </ModalHeader>
            <ModalBody>
              <p>Group name</p>
              <Box className="group-name-input-wrap">
                {/* <InputLabel id="demo-multiple-chip-label">Chip</InputLabel> */}
                <TextField
                  className="group-name-input"
                  id="outlined-basic"
                  variant="outlined"
                  onChange={handleChange}
                  value={inputMsg}
                />
                <EmojiST
                  setInputMsg={setInputMsg}
                  showEmojiPicker={showEmojiPicker}
                  setShowEmojiPicker={setShowEmojiPicker}
                  groupClass={"emojiRight"}
                />
              </Box>
              {errorStatus.status && errorStatus.name.status && (
                <p className="errorgrouperror">{errorStatus.name.title}</p>
              )}
              <p>Group members</p>
           
              <Box className="group-member-input">
                <Autocomplete
                  multiple
                  id="tags-standard"
                  options={friendList}
                  getOptionLabel={(value) =>  value?.fullName ? value?.fullName : (value?.firstName ? value?.firstName : value?.phone)}
                  defaultValue={personName}
                  onChange={handleSelectChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Add Group Members"
                      
                    />
                  )}
                />
              </Box>
              {errorStatus.status && errorStatus.participants.status && (
                <p className="errorgrouperror">
                  {errorStatus.participants.title}
                </p>
              )}
              <p>Invitation message</p>
              <TextField
                className="group-invitation-input"
                id="outlined-basic-invitation"
                // variant="outlined"
                multiline
                rows={2}
                // maxRows={4}
                onChange={handleInvitationChange}
                value={inputInvitationMsg}
              />
              {errorStatus.status && errorStatus.message.status && (
                <p className="errorgrouperror">{errorStatus.message.title}</p>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={groupSubmitHandle}>
                Add
              </Button>
            </ModalFooter>
          </Modal>
        </>
      )}
    </>
  );
};

export default AddGroupForm;
