import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import * as FeatherIcon from "react-feather";

const ChatDropdown = ({ item, deleteChatHandle }) => {
  console.log("item==>", item);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle tag="span">
        <FeatherIcon.MoreHorizontal />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => deleteChatHandle(item.conversationId)}>Delete</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ChatDropdown;
