import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import ChatHeader from "./ChatHeader";
import ChatFooter from "./ChatFooter";
import PerfectScrollbar from "react-perfect-scrollbar";
import UnselectedChat from "../../../assets/img/unselected-chat.svg";
import { useSelector, useDispatch } from "react-redux";
import MessagesView from "./MessagesView";
import { selectedChatAction } from "../../../Store/Actions/SelectedChat/selectedChatAction";
import API from "../../../services/api_endpoint";
import axios from "axios";

let IDCHANGESTATUS = true;

function Chat({ socket, userId, client }) {
  const dispatch = useDispatch();
  const [disableScroll, setDisableScroll] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [isOnline, setIsOnline] = useState(false);
  const [data, setData] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  const [scrollToCheck, setScrollToCheck] = useState(1);
  const scrollRef = useRef();
  const chatContainerRef = useRef();
  const [message, setMessage] = useState([]);
  const { selectedChat, editProfile } = useSelector((state) => state);
  const [scrollEl, setScrollEl] = useState();
  const [participantArray, setParticipantArray] = useState([]);
  const [friendList, setFriendList] = useState([]);

  useEffect(() => {
    if (scrollEl) {
      scrollEl.scrollTop = scrollEl.scrollHeight;
    }
  }, [scrollEl]);

  useEffect(() => {
    socket.on("new-message", (res) => {
      if (res?.senderId == userId) {
        IDCHANGESTATUS = true;
      }
      setMessage((prevState) => {
        if (prevState[0]?.roomId === res?.roomId) {
          return [...prevState, res];
        } else {
          if (prevState[0] === undefined) {
            return [...prevState, res];
          } else {
            return [...prevState];
          }
        }
      });
    });

    return () => {
      setMessage(null);
    };
  }, []);

  const emitGetMessages = (pageNumD) => {
    socket.emit("get-messages", {
      conversationId: selectedChat.id,
      userId: userId,
      pageNum: pageNumD || pageNum,
    });
  };

  useEffect(() => {
    if (message?.length > 0 && data) {
      if (message[0]?.conversationId != selectedChat?.id) {
        setMessage(data);
        setPageNum(2);
      } else {
        setMessage((prevState) => [...prevState, ...data]);
        setPageNum(pageNum + 1);
      }
    } else {
      if (data) {
        setMessage(data);
        setPageNum((prevState) => {
          return prevState + 1;
        });
      }
    }
  }, [data]);

  const onConversationHistory = () => {
    socket.on("conversation-history", (res) => {
      if (res?.messageHistory?.onlineStatus === "Online") {
        setIsOnline(true);
      } else {
        setIsOnline(false);
      }
      let allData = res?.messageHistory?.allConversations;
      setData(allData);
      // setData((prevState)=>{
      //   console.log('history previ  - ->', prevState)
      //   if(allData?.length == 1){
      //     if(prevState){
      //       if(prevState[0].message == allData[0].message){
      //       } else {
      //         return [...allData]
      //       }
      //     } else {
      //       return [...allData]
      //     }
      //   } else {
      //     return [...allData]
      //   }
      // })
      // if(data){
      //   if(allData?.length == 1 && data?.length == 1 && data != null){
      //     if(allData[0].message == data[0].message){
      //     } else {
      //       setData(allData);
      //     }
      //   } else {
      //     setData(allData);
      //   }
      // } else {
      //   setData(allData);
      // }

      // if(allData.length == 1 && data.length == 1){
      //   if(allData[0].message == data[0].message){
      //   } else {
      //     setData(allData);
      //   }
      // } else {
      //   setData(allData);
      // }
    });
  };

  useEffect(() => {
    if (selectedChat.id) {
      if (data) {
        if (message[0]?.conversationId != selectedChat?.id) {
          IDCHANGESTATUS = true;
          setPageNum(1);
          emitGetMessages(1);
        }
      } else {
        emitGetMessages();
      }

      onConversationHistory();

      socket.emit("online", { userId: userId });

      //  ~~~~~~~~~~~~ Creating participantArray from conversation payload ~~~~~~~
      if (selectedChat.chat.initiatedBy === userId) {
        setParticipantArray(selectedChat?.chat?.participantsIds);
      } else {
        const partArray = selectedChat?.chat?.participantsIds.filter((id) => {
          if (id !== userId) {
            return id;
          }
        });
        setParticipantArray([selectedChat.chat.initiatedBy, ...partArray]);
      }
      socket.on("typing", (res) => {
        if (selectedChat?.chat?.roomId === res?.roomId && selectedChat?.chat?.roomId !== undefined) {
          setIsTyping(res.typing);
        }
      });
    } else {
      setMessage([]);
    }
  }, [selectedChat.id, selectedChat?.chat?.participantsIds]);

  const afterStateScroll = () => {
    let com = document.getElementById("perfectScroller");
    let check = com?.scrollHeight - scrollToCheck;
    com?.scrollTo(0, check);
  };

  useLayoutEffect(() => {
    if (scrollRef?.current) {
      if (IDCHANGESTATUS) {
        scrollRef.current.scrollIntoView();
        setTimeout(() => {
          IDCHANGESTATUS = false;
        }, 1000);
      } else {
        afterStateScroll();
      }
      // scrollRef.current.scrollIntoView()
      // if (message?.length>0 && message[0]?.conversationId != selectedChat?.id) {
      //   scrollRef.current.scrollIntoView()
      //   } else {
      //     afterStateScroll()
      //   }
    }
  });

  const getContactSync = () => {
    axios
      .get(`${API.getContact}?userId=${editProfile?.UserData?.payload?.data[0]?.id}`)
      .then((response) => {
        const { data } = response;
        let phones = data?.data.map((phone) => {
          return String(phone.phone);
        });
        setFriendList([...data.data]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getContactSync();
    return () => {
      dispatch(selectedChatAction(true));
      window.localStorage.removeItem("newFriend");
      window.localStorage.removeItem("newFriendName");
    };
  }, []);

  const handleScroll = (event) => {
    if (event.currentTarget.scrollHeight != scrollToCheck) {
      if (event.currentTarget.scrollTop <= 440) {
        // console.log(" offsetHeight : ", event.currentTarget.scrollHeight);
        if (!disableScroll) {
          setScrollToCheck(event.currentTarget.scrollHeight);
          emitGetMessages();
        } else {
          setDisableScroll(false);
        }
      }
    }
  };

  return (
    <>
      {message && (
        <div className="chat">
          {selectedChat?.id || window.localStorage.getItem("newFriend") ? (
            <React.Fragment>
              <ChatHeader
                client={client}
                receiverId={participantArray}
                selectedChat={selectedChat}
                ChatStatus={message.length > 0 && message?.reverse()[0]}
                isTyping={isTyping}
                isOnline={isOnline}
                userId={userId}
                socket={socket}
                friendList={friendList}
              />
              <PerfectScrollbar containerRef={(ref) => setScrollEl(ref)} id="perfectScroller" onScroll={handleScroll}>
                <div className="chat-body" id="chat-body-cont" style={{ opacity: message[0]?.conversationId != selectedChat?.id ? "0" : "1" }}>
                  <div className="messages" ref={chatContainerRef}>
                    {selectedChat.id || window.localStorage.getItem("newFriend")
                      ? message
                          ?.sort((a, b) => (a.messageTime > b.messageTime ? 1 : -1))
                          ?.filter(
                            (value, index, self) =>
                              index ===
                              self.findIndex((t) => {
                                return t.messageTime === value.messageTime && t?.attachmentUrl === value?.attachmentUrl;
                              })
                          )
                          ?.map((message, i) => {
                            return <MessagesView id={userId} message={message} key={i} />;
                          })
                      : null}
                  </div>
                </div>
                <span ref={scrollRef} />
              </PerfectScrollbar>
              <ChatFooter socket={socket} userId={userId} receiverId={participantArray} chat={selectedChat.chat} />

              {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~  uncomment to enable xmpp ~~~~~~~~~~~~~~~~~~~ */}
              {/* <UseChat /> */}
            </React.Fragment>
          ) : (
            <div className="chat-body no-message">
              <div className="no-message-container">
                <div className="mb-5">
                  <img src={UnselectedChat} width={200} className="img-fluid" alt="unselected" />
                </div>
                <p className="lead">Select a chat to read messages</p>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
export default Chat;
