import React, { useEffect, useRef } from "react";
import { Button } from "reactstrap";
import HeadphonesIcon from '@mui/icons-material/Headphones';
import { useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import "./audiost.scss";

const AudioST = ({ mediaselected, setMediaSelected, setMediaArray, audio, setAudio, submitBtnStatus }) => {
  const { selectedChat } = useSelector((state) => state);
  const audioRef = useRef(null);

  const mediaHandle = (event) => {
    if(event?.target?.files[0].size < 10485760){
    if(event?.target?.files[0]){
      setMediaSelected('audio')
      setAudio(event.target.files[0]);
      setMediaArray([event.target.files[0]]);
    } else {
      removeAudioHandle();
    }
  } else {
    alert("Maximum Audio size: 10mb")
  }
  };

  const removeAudioHandle = () => {
    setMediaSelected("")
    setAudio(null);
    setMediaArray(null);
  };

  useEffect(() => {
    audio && setAudio(null);
  }, [selectedChat]);
  return (
    <>
      {audio && <MediaPreview media={audio} removeAudio={removeAudioHandle} submitBtnStatus={submitBtnStatus} />}
      <input
        onChange={(event) => {
          mediaHandle(event);
        }}
        onClick = {(event) =>{
          event.target.value = null;
        }}
        ref={audioRef}
        accept="audio/* audio/mp3,audio/wav,audio/mpeg,.aac"
        className="comment-file-input"
        id="image-file-input"
        type="file"
        hidden
      />
      <Button
        disabled={mediaselected === 'image' || mediaselected === 'file' || mediaselected === 'video'}
        color="light"
        className="mr-3"
        title="audio"
        onClick={(e) => {
          e.target.blur()
          audioRef.current.click();
        }}
        onKeyDown={(e) => {
          e.target.blur()
        }}
      >
        <HeadphonesIcon />
      </Button>
    </>
  );
};

const MediaPreview = ({ media, removeAudio ,submitBtnStatus }) => {
  return (
    <>
      <div className="audio-preview-container">
        <div className="audio-preview-wrap">
          <div className="audio-wrap-media">
            <div className="audio-container">
            {!submitBtnStatus && <div
                onClick={() => {
                  removeAudio();
                }}
                className="remove-icon-audio"
              >
                <CancelIcon />
              </div> }
              <audio controls className="uploading-audio" src={URL.createObjectURL(media)} width={160} height={160} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AudioST;
