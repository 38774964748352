import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { messageAction } from "../../../../Store/Actions/Message/messageAction";
import { selectedChatAction } from "../../../../Store/Actions/SelectedChat/selectedChatAction";
import ManAvatar1 from "../../../../assets/img/women_avatar5.jpg";

const MessageListView = (props) => {
  const { sms, smsFriendName } = props;
  const dispatch = useDispatch();
  const { messageSms } = useSelector((state) => state); //using chat saved in selected chat user

  const smsSelectHandle = (sms) => {
    let newMsgObj = props?.mappingObj
    // sms.unread_messages = 0;
    const filteredArray = newMsgObj?.filter((res) => {
      if ((res.num_b === sms.num_b) || (res.num_a === sms.num_b)) {
        return res
      }
    })
    if (messageSms?.sms?.num_b === sms?.num_b) {

    } else {
      dispatch(messageAction(filteredArray)); //sending data to selectedChat reducer
      dispatch(selectedChatAction(true));
    }
  };
  return (
    <li
      className={
        "list-group-item " + (sms.num_b === messageSms[0]?.num_b ? "open-chat" : "")
      } //opening chat of the selected user
      onClick={() => smsSelectHandle(sms)}
    >
      <img
        src={ManAvatar1}
        alt="avtar"
        className="rounded-circle profileImageSms"
      />
      <div className="users-list-body">
        <div>
          <h5 className={''}>{smsFriendName}</h5>
          {sms?.message}
        </div>
        <div className="users-list-action">
          {sms.unread_messages ? (
            <div className="new-message-count">{sms.unread_messages}</div>
          ) : (
            ""
          )}
          <small
            className={sms.unread_messages ? "text-primary" : "text-muted"}
          >
            {sms?.created_at?.slice(11, 16)}
          </small>
        </div>
      </div>
    </li>
  );
};

export default MessageListView;
