import React, { useState } from "react";
import { Modal, ModalBody, Form } from "reactstrap";
import * as FeatherIcon from "react-feather";
import ManAvatar4 from "../../../assets/img/man_avatar4.jpg";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "../../../services/cookies";
import API from "../../../services/api_endpoint";
import { JitsiAction } from "../../../Store/Actions/Jitsi/JitsiAction";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { sidebarAction } from "../../../Store/Actions/sidebarAction";
import { messageAction } from "../../../Store/Actions/Message/messageAction";

const ContactModal = (props) => {
  const cookies = new Cookies();
  const [sentErrorData, setSentErrorData] = useState(false);
  const { editProfile } = useSelector((state) => state);
  const USER_Number = editProfile.UserData?.payload?.data[0]?.phone;
  const { jitsiProfile } = useSelector((state) => state);
  const jitsiToggle = jitsiProfile.isJistiopen;
  const dispatch = useDispatch();
  const { client } = props;
  const [userFcm, setUserFcm] = useState([]);

  const sendFcmtoClientSocket = (fcm, cameraStatus) => {
    const dataa = {
      ...data,
      fcm: fcm,
      room_id: `${USER_Number}`,
      userIdentifier: `${USER_Number}`,
      isVideo: cameraStatus,
    };
    if (client.readyState === W3CWebSocket.OPEN) {
      client.send(JSON.stringify(dataa));
      dispatch(JitsiAction({ isJistiopen: true, callerId: USER_Number, startWithVideoMuted: cameraStatus }));
    } else {
      alert("something wrong !!!, Connection are not established");
    }
  };

  const dialcallHandler = (number, cameraStatus) => {
    if (number) {
      const calledIdd = (Math.random() + 1)?.toString(36)?.substr(2, 16) + number + (Math.random() + 1)?.toString(36)?.substr(2, 16);
      axios
        .post(`${API.getfcmbynumber}?auth_key=${cookies.getItem("KEY")}&number=${number}`)
        .then((response) => {
          const { data } = response;

          if (data.fcm === false || data.fcm === "null") {
            setSentErrorData([true, calledIdd]);
          } else if (data.fcm.includes("safari") && data.client === "web") {
            sendFcmtoClientSocket(data.fcm, cameraStatus);
            setUserFcm((prev) => {
              return [...prev, data.fcm];
            });
          } else {
            // sendFcmtoClientSocket(data.fcm);
            sendFcmtoClient(data.fcm, calledIdd, data.client, cameraStatus);
            setUserFcm((prev) => {
              return [...prev, data.fcm];
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert("Can't call this number !...");
    }
  };

  const sendFcmtoClient = (fcm, id, client, cameraStatus) => {
    const data = {
      to: fcm,
      priority: "high",
      notification: {
        title: "SwitchTell",
        // "mutable-content" : 1,
        body: cameraStatus ? "Video Conference Call" : "Video Conference Call",
        sound: "alarmfrenzy",
        android_channel_id: "myShadow",
        click_action: "notificationScreen",
        room_id: `${USER_Number}`,
        isVideo: cameraStatus,
      },
      data: {
        messageId: "1234",
        title: "Incoming Call..",
        body: cameraStatus ? "Video Conference Call" : "Video Conference Call",
        sos: "true",
        emergency: "true",
        accept: "accept",
        decline: "decline",
        userIdentifier: `${USER_Number}`,
        room_id: `${USER_Number}`,
        isVideo: cameraStatus,
      },
    };
    client === "android" && delete data.notification["click_action"];

    const header = {
      Authorization: "key=AAAAbR4XOE0:APA91bFd9MWAto33xxg1sjMgH1gfb7Ja0FUhZMeTS6OYd8Bw96WEPAL5XfMp-r1zFK-PTYGD-qKm1Eg5mgD5rnc3PxHxhzM1Ff1ni4YB6wee5FIDMDaGnH7yurkmdDWe7rzIpE37TPFA",
      "Content-Type": "application/json",
    };
    axios
      .post(`https://fcm.googleapis.com/fcm/send`, data, {
        headers: header,
      })
      .then((response) => {
        // if(true){
        if (response.data.success === 1) {
          dispatch(JitsiAction({ isJistiopen: true, callerId: USER_Number, startWithVideoMuted: cameraStatus }));
          // dispatch(JitsiAction({ isJistiopen: true, callerId: (Math.random() + 1).toString(36).substr(2, 16)+number+(Math.random() + 1).toString(36).substr(2, 16) }));
          const { data } = response;
          // console.log(data)
        } else {
          alert(`something wrong,  ${response.data.results[0].error}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const messageInitiate = (item) => {
    cookies.setItem("UserDid", item);
    dispatch(sidebarAction("Messages"));
    dispatch(messageAction([]));
  };
  const callInitiate = (item) => {
    dispatch({ type: "SELECTED_SIP_Participant", payload: item });
    dispatch({ type: "SELECTED_SIP_CallingStatus", payload: "call_connecting" });
  };

  const contactNumber = props?.modalData?.calledstation ? props?.modalData?.calledstation : props?.modalData?.src;

  return (
    <Modal isOpen={props.modal} toggle={props.toggle} centered>
      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <ModalBody className="modal_body">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="pl-4 pr-4">
            <div className="text-center">
              <figure className="avatar avatar-xl mb-3">
                <img src={ManAvatar4} className="rounded-circle" alt="avatar" />
              </figure>
              <h5 className="mb-1">
                {cookies.getItem("NewKey") !== "hello" &&
                  JSON.parse(cookies.getItem("NewKey")).map((item) => {
                    if (item.contact[0].number === props.modalData.src) {
                      return item.name;
                    } else {
                      return;
                    }
                  })}
              </h5>
              <h5>{contactNumber}</h5>

              <div className="mt-4 d-flex justify-content-center">
                <img
                  src={require("../../../../src/assets/message.svg").default}
                  alt="messageImg"
                  className="m-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    messageInitiate();
                  }}
                />
                <img
                  src={require("../../../../src/assets/videocall.svg").default}
                  alt="videocallImg"
                  className="m-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    dialcallHandler(props?.modalData?.calledstation, true);
                  }}
                />
                <img
                  src={require("../../../../src/assets/callhistory.svg").default}
                  alt="callhistoryImg"
                  className="m-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    dialcallHandler(props?.modalData?.calledstation, false);
                  }}
                />
              </div>
              <div className="mt-3">
                <div className="mt-4 mb-4 d-flex ">
                  <FeatherIcon.Phone className="contactModalPhoneIcon" />
                  <div className="mt-1 mb-1 ml-4 text-left">
                    <h6>Mobile Number</h6>
                    <p className="text-muted">{contactNumber}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ContactModal;

const data = {
  title: "Incoming Video Call..",
  body: `Video Conference Call`,
  room_id: `1231231231`,
  userIdentifier: `1231231231`,
  messageId: "1234",
  sound: "alarmfrenzy",
  android_channel_id: "myShadow",
  click_action: "notificationScreen",
  sos: "true",
  emergency: "true",
  accept: "accept",
  decline: "decline",
};
