import React, { useEffect, useState } from "react";
import * as FeatherIcon from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import ManAvatar4 from "../../../../assets/img/man_avatar5.jpg";
import AddContactModal from "../../Modals/AddContactModal";
import ShowContactModal from "../../Modals/ShowContactModal";
import ShareModal from "../../Modals/ShareModal";
import Cookies from "../../../../services/cookies";
import { useSelector, useDispatch } from "react-redux";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import axios from "axios";
import API from "../../../../services/api_endpoint";
// import JitsiComponent from "../../Jitsi";
import { JitsiAction } from "../../../../Store/Actions/Jitsi/JitsiAction";
import { sidebarAction } from "../../../../Store/Actions/sidebarAction";
import { messageAction } from "../../../../Store/Actions/Message/messageAction";
import { didNumberAction } from "../../../../Store/Actions/DidNumber/didNumberAction";
import ReactLoading from "react-loading";

function Index({ client, setCallOpenModal, callRefButton }, props) {
  // const {sms} = props
  const [userFcm, setUserFcm] = useState([]);
  const [sentErrorData, setSentErrorData] = useState(false);
  // const [isFcmError, setIsFcmError] = useState(false);
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const { editProfile } = useSelector((state) => state);
  const [contactDetails, setcontactDetails] = useState([]);
  const [scrollEl, setScrollEl] = useState();
  const mobileMenuBtn = () => document.body.classList.toggle("navigation-open");
  const [updateToggle, setUpdateToggle] = useState(false);
  // const USER_ID = editProfile.UserData?.payload?.data[0]?.id;
  const USER_Number = editProfile.UserData?.payload?.data[0]?.phone;
  const username = "abcdtpz";
  const { jitsiProfile } = useSelector((state) => state);
  const jitsiToggle = jitsiProfile.isJistiopen;
  const [isSync, setIsSync] = useState(false);
  const [searchValue, setSearchValue] = useState([]);
  const [contactArray, setContactArray] = useState([]);
  const [loading, setLoading] = useState(false);

  const startConmByShare = (id) => {
    dispatch(JitsiAction({ isJistiopen: true, callerId: id }));
  };

  const dialcallHandler = (number, cameraStatus) => {
    const calledIdd = (Math.random() + 1)?.toString(36)?.substr(2, 16) + number + (Math.random() + 1)?.toString(36)?.substr(2, 16);

    axios
      .post(`${API.getfcmbynumber}?auth_key=${cookies.getItem("KEY")}&number=${number}`)
      .then((response) => {
        const { data } = response;
        if (data.fcm === false || data.fcm === "null") {
          setSentErrorData([true, calledIdd]);
        } else if (data.fcm.includes("safari") && data.client === "web") {
          sendFcmtoClientSocket(data.fcm, cameraStatus);
          setUserFcm((prev) => {
            return [...prev, data.fcm];
          });
        } else {
          // sendFcmtoClientSocket(data.fcm);
          sendFcmtoClient(data.fcm, calledIdd, data.client, cameraStatus);
          setUserFcm((prev) => {
            return [...prev, data.fcm];
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendFcmtoClientSocket = (fcm, cameraStatus) => {
    const dataa = {
      ...data,
      fcm: fcm,
      room_id: `${USER_Number}`,
      userIdentifier: `${USER_Number}`,
      isVideo: cameraStatus,
    };
    if (client.readyState === W3CWebSocket.OPEN) {
      client.send(JSON.stringify(dataa));
      dispatch(JitsiAction({ isJistiopen: true, callerId: USER_Number, startWithVideoMuted: cameraStatus }));
    } else {
      alert("something wrong !!!, Connection are not established");
    }
  };

  const sendFcmtoClient = (fcm, id, client, cameraStatus) => {
    const data = {
      to: fcm,
      priority: "high",
      notification: {
        title: "SwitchTell",
        // "mutable-content" : 1,
        body: cameraStatus ? "Video Conference Call" : "Video Conference Call",
        sound: "alarmfrenzy",
        android_channel_id: "myShadow",
        click_action: "notificationScreen",
        room_id: `${USER_Number}`,
        isVideo: cameraStatus,
      },
      data: {
        messageId: "1234",
        title: "Incoming Call..",
        body: cameraStatus ? "Video Conference Call" : "Video Conference Call",
        sos: "true",
        emergency: "true",
        accept: "accept",
        decline: "decline",
        userIdentifier: `${USER_Number}`,
        room_id: `${USER_Number}`,
        isVideo: cameraStatus,
      },
    };
    client === "android" && delete data.notification["click_action"];

    const header = {
      Authorization: "key=AAAAbR4XOE0:APA91bFd9MWAto33xxg1sjMgH1gfb7Ja0FUhZMeTS6OYd8Bw96WEPAL5XfMp-r1zFK-PTYGD-qKm1Eg5mgD5rnc3PxHxhzM1Ff1ni4YB6wee5FIDMDaGnH7yurkmdDWe7rzIpE37TPFA",
      "Content-Type": "application/json",
    };
    axios
      .post(`https://fcm.googleapis.com/fcm/send`, data, {
        headers: header,
      })
      .then((response) => {
        // if(true){
        if (response.data.success === 1) {
          dispatch(JitsiAction({ isJistiopen: true, callerId: USER_Number, startWithVideoMuted: cameraStatus }));
          // dispatch(JitsiAction({ isJistiopen: true, callerId: (Math.random() + 1).toString(36).substr(2, 16)+number+(Math.random() + 1).toString(36).substr(2, 16) }));
          const { data } = response;
          // console.log(data)
        } else {
          alert(`something wrong,  ${response.data.results[0].error}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getContactSync = () => {
    setLoading(true);
    axios
      .get(`${API.getContact}?userId=${editProfile?.UserData?.payload?.data[0]?.id}`)
      .then((response) => {
        const { data } = response;
        setcontactDetails(data?.data);
        setContactArray(data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getContactSync();
  }, [isSync]);

  const messageInitiate = (item) => {
    cookies.setItem("UserDid", item);
    dispatch(sidebarAction("Messages"));
    dispatch(messageAction([]));
  };
  // console.log("ContactDetails--->>>", contactDetails)
  const callInitiate = (item) => {
    // console.log("Did Number--->>>", didNumberAction)
    dispatch({ type: "SELECTED_SIP_Participant", payload: item });
    dispatch({ type: "SELECTED_SIP_CallingStatus", payload: "call_connecting" });
    // cookies.setItem("UserDid", item)
    // window. location. reload()
    // dispatch(didNumberAction('DID'))
  };

  useEffect(() => {
    const ContactData = contactDetails?.filter((value) => value?.firstName?.toLowerCase()?.includes(searchValue?.toLowerCase()));
    setContactArray(ContactData);
  }, [searchValue]);

  const CallDetailItems = () => {
    if (contactDetails != null) {
      return (
        <ul className="list-group list-group-flush">
          {!loading ? (
            contactDetails.length > 0 &&
            contactArray?.map((item, i) => {
              return (
                <li key={i} className="list-group-item">
                  <div className="users-list-body">
                    <figure className="avatar">
                      <img src={ManAvatar4} className="rounded-circle" alt="avatar" />
                    </figure>
                    <div>
                      <h5>{item?.firstName}</h5>
                      <div
                        className="call_logs_info d-flex"
                        // style={{ display: "flex" }}
                      >
                        <p>{item?.phone}</p>
                      </div>
                      <div className="contactDetailsBottomIcons">
                        <FeatherIcon.Mail
                          className="contactDetailsBottomSingleIcons"
                          onClick={() => {
                            messageInitiate(item?.dId);
                          }}
                        />
                        <FeatherIcon.PhoneCall
                          className="contactDetailsBottomSingleIcons"
                          onClick={() => {
                            dialcallHandler(item?.phone, false);
                          }}
                        />
                        <FeatherIcon.Video
                          className="contactDetailsBottomSingleIcons"
                          onClick={() => {
                            dialcallHandler(item?.phone, true);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <ul className="list-inline">
                    <li className="list-inline-item">
                      <ShowContactModal items={item} index={i} Data={contactDetails} setData={setcontactDetails} setUpdateToggle={setUpdateToggle} updateToggle={updateToggle} />
                    </li>
                  </ul> */}
                </li>
              );
            })
          ) : (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <ReactLoading type={"spin"} color={"#"} height={50} width={50} />
            </div>
          )}
        </ul>
      );
    } else {
      return (
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            <div className="users-list-body">
              <div>
                <h5>No Data To Show</h5>
              </div>
            </div>
          </li>
        </ul>
      );
    }
  };
  return (
    <div className="sidebar active">
      <header>
        <div className="d-flex align-items-center">
          <button onClick={mobileMenuBtn} className="btn btn-outline-light mobile-navigation-button mr-3 d-xl-none d-inline">
            <FeatherIcon.Menu />
          </button>
          <span className="sidebar-title">Contact Details</span>
        </div>
        <ul className="list-inline">
          <li className="list-inline-item">
            <AddContactModal Data={contactDetails} setData={setcontactDetails} setIsSync={setIsSync} isSync={isSync} />
          </li>
        </ul>
      </header>
      <form
      // onSubmit={e => { e.preventDefault() }}
      >
        <input type="text" className="form-control" placeholder="Search" onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
      </form>
      <div className="sidebar-body">
        <PerfectScrollbar containerRef={(ref) => setScrollEl(ref)}>
          <CallDetailItems />
        </PerfectScrollbar>
      </div>
      {jitsiToggle === true ? userFcm.length > 0 && console.log("current username define = = = =  - -- >", username) : ""}
      {sentErrorData[0] && <ShareModal data={sentErrorData} setSentErrorData={setSentErrorData} startConmByShare={startConmByShare} />}
    </div>
  );
}

export default Index;

const data = {
  title: "Incoming Video Call..",
  body: `Video Conference Call`,
  room_id: `1231231231`,
  userIdentifier: `1231231231`,
  messageId: "1234",
  sound: "alarmfrenzy",
  android_channel_id: "myShadow",
  click_action: "notificationScreen",
  sos: "true",
  emergency: "true",
  accept: "accept",
  decline: "decline",
};
