const initialState = {
    VirtualNumberData : {}
}



const virtualNumberReducer = (state = initialState,{type,payload}) =>
{  
    switch(type) 
    {
        case 'VIRTUAL_NUMBERS' : return {
            ...state,
            VirtualNumberData : payload
        };
        default : return state
    }
}


export default virtualNumberReducer 