import React, { useEffect, useRef } from "react";
import { Button } from "reactstrap";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import { useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import "./videost.scss";

const VideoST = ({ mediaselected, setMediaSelected, setMediaArray, video, setVideo, submitBtnStatus }) => {
  const { selectedChat } = useSelector((state) => state);
  const videoRef = useRef(null);

  const mediaHandle = (event) => {
    if(event?.target?.files[0].size < 10485760){
    if(event?.target?.files[0]){
      setMediaSelected('video')
      setVideo(event.target.files[0]);
      setMediaArray([event.target.files[0]]);
    } else {
      removeVideoHandle();
    }
  } else {
    alert("Maximum Video size: 10mb")
  }
  };

  const removeVideoHandle = () => {
    setMediaSelected("")
    setVideo(null);
    setMediaArray(null);
  };

  useEffect(() => {
    video && setVideo(null);
  }, [selectedChat]);
  return (
    <>
      {video && <MediaPreview media={video} removeVideo={removeVideoHandle} submitBtnStatus={submitBtnStatus} />}
      <input
        onChange={(event) => {
          mediaHandle(event);
        }}
        onClick = {(event) =>{
          event.target.value = null;
        }}
        ref={videoRef}
        // accept="video/mp4,video/x-m4v,video/mkv, .mov, .qt"
        accept="video/*, .mkv video/mp4,video/x-m4v,video/mkv, .mov, .qt"
        className="comment-file-input"
        id="image-file-input"
        type="file"
        hidden
      />
      <Button
        disabled={mediaselected === 'image' || mediaselected === 'file' || mediaselected === 'audio'}
        color="light"
        className="mr-3"
        title="Video"
        onClick={(e) => {
          e.target.blur()
          videoRef.current.click();
        }}
        onKeyDown={(e) => {
          e.target.blur()
        }}
      >
        <VideoCallIcon />
      </Button>
    </>
  );
};

const MediaPreview = ({ media, removeVideo, submitBtnStatus }) => {
  return (
    <>
      <div className="video-preview-container">
        <div className="video-preview-wrap">
          <div className="video-wrap-media">
            <div className="image-container">
            {!submitBtnStatus && <div
                onClick={() => {
                  removeVideo();
                }}
                className="remove-icon-video"
              >
                <CancelIcon />
              </div>}
              <video controls className="uploading-video" src={URL.createObjectURL(media)} width={160} height={160} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoST;
