export const userSmsNameFriend = (userDiDObj, userId, smsFriendListNumber) => {
  let numbered = [userDiDObj?.num_b];
  let number = [...new Set(numbered)];
  number = number.filter((item) => {
    if (userId !== item) {
      return item;
    }
  });

  let nameFilter = smsFriendListNumber?.filter((numbered) => {
    if (numbered?.dId === number[0]) {
      return numbered;
    }
  });

  return number?.length > 0 && nameFilter?.length > 0 ? nameFilter[0]?.firstName : userDiDObj?.num_b;
};