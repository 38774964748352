import React, { useEffect, useState } from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import AddFriendsModal from "../../Modals/AddFriendModal";
import FriendsDropdown from "./FriendsDropdown";
import * as FeatherIcon from "react-feather";
import axios from "axios";
import API from "../../../../services/api_endpoint";
import { useDispatch, useSelector } from "react-redux";
import { sidebarAction } from "../../../../Store/Actions/sidebarAction";
import { mobileSidebarAction } from "../../../../Store/Actions/Mobile/mobileSidebarAction";
import WomenAvatar5 from "../../../../assets/img/women_avatar5.jpg";
import { Link } from "react-router-dom";
import Cookies from "../../../../services/cookies";
// import Cookies from "../../../services/cookies";
import ReactLoading from "react-loading";

function Index() {
  const dispatch = useDispatch();
  const { editProfile } = useSelector((state) => state);
  const [friendList, setFriendList] = useState(null);
  const [searchFriend, setSearchFriend] = useState([]);
  const [searchArray, setSearchArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const cookies = new Cookies();

  const getContactSync = () => {
    setLoading(true);
    axios
      .get(`${API.getContact}?userId=${editProfile?.UserData?.payload?.data[0]?.id}`)
      .then((response) => {
        const { data } = response;
        setFriendList(data?.data);
        setSearchArray(data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getContactSync();
  }, []);

  useEffect(() => {
    const FriendData = friendList?.filter((value) => value?.firstName?.toLowerCase()?.includes(searchFriend?.toLowerCase()));
    setSearchArray(FriendData);
  }, [searchFriend]);

  const mobileMenuBtn = () => document.body.classList.toggle("navigation-open");

  const checkFcmToken = (item) => {
    axios
      .get(`${API.getfcmbynumber}?auth_key=${cookies.getItem("KEY")}&number=${item.phone}`)
      .then((response) => {
        const { fcm } = response.data;
        if (fcm) {
          // Proceed to chat
          dispatch(sidebarAction("Chats"));
          dispatch(mobileSidebarAction(true));
          if (document.querySelector(".chat")?.classList.remove("open")) {
            document.body.classList.toggle("navigation-open");
          }
          window.localStorage.setItem("newFriend", item.phone);
          window.localStorage.setItem("newFriendName", item?.firstName + " " + item?.lastName);
        } else {
          // Alert if no FCM token found
          alert("The number that you have entered is not registered with Switchtell. Kindly check the number or invite the number to register.");
        }
      })
      .catch((err) => {
        console.log(err);
        alert("An error occurred while checking the FCM token.");
      });
  };

  return (
    <div className="sidebar active">
      <header>
        <div className="d-flex align-items-center">
          <button onClick={mobileMenuBtn} className="btn btn-outline-light mobile-navigation-button mr-3 d-xl-none d-inline">
            <FeatherIcon.Menu />
          </button>
          <span className="sidebar-title">Friends</span>
        </div>
      </header>
      <form>
        <input type="text" className="form-control" placeholder="Search friends" onChange={(e) => setSearchFriend(e.target.value)} value={searchFriend} />
      </form>
      <div className="sidebar-body">
        <PerfectScrollbar>
          <ul className="list-group list-group-flush">
            {!loading ? (
              friendList &&
              searchArray?.map((item, i) => {
                return (
                  <li key={i} className="list-group-item">
                    <figure className="avatar">
                      <img src={WomenAvatar5} className="rounded-circle" alt="avatar" />
                    </figure>
                    <div className="users-list-body">
                      <div
                        onClick={() => {
                          checkFcmToken(item);
                        }}
                      >
                        <h5>{item.firstName + " " + item.lastName}</h5>
                        <p>
                          <Link to="/" onClick={(event) => event.preventDefault()}>
                            {item.phone}
                          </Link>
                        </p>
                      </div>
                      <div className="users-list-action">
                        <div className="action-toggle">
                          <FriendsDropdown item={item} clickChat={checkFcmToken} />
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })
            ) : (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <ReactLoading type={"spin"} color={"#"} height={50} width={50} />
              </div>
            )}
          </ul>
        </PerfectScrollbar>
      </div>
    </div>
  );
}

export default Index;
