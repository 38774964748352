import axios from "axios"
import API from "../../../services/api_endpoint"
import Cookies from "../../../services/cookies";
import { paymentAction } from "./PaymentAction";


const cookies = new Cookies();
const paymentDataFetch = (amount) => {
   
    return async (dispatch) => {
        await axios.get(`${API.payment}?sip_id=${cookies.getItem('USERDATA')}&amount=${amount}`).then(
            res => {dispatch(paymentAction({type : 'PAYMENT',payload : res}))}
        )                    
        
        
    }
}

export {paymentDataFetch};