const LoadingReducer = (state =  {
    loading : false
},{type,payload}) =>
{  
    switch(type) 
    {
        case 'Loading' : return payload
    
        default : return state
    }
}

export default LoadingReducer; 