import React from 'react'
import {useSelector} from 'react-redux'
import ChatsIndex from "./Chats"
import FriendsIndex from "./Friends"
import FavoritesIndex from "./Favorites"
import ArchivedIndex from "./Archived"
import CallLogIndex from './Calllogs'
import ContectDetails from './ContactDetails' 
import MessageIndex from './Message'
import Cookies from "../../../services/cookies"

function Index({client,socket, userId, setCallOpenModal, callRefButton }) {
    const {selectedSidebar, mobileSidebar} = useSelector(state => state);
    const cookies = new Cookies()
    const { editProfile } = useSelector((state) => state);
    return (
        <div className={`sidebar-group ${mobileSidebar ? "mobile-open" : ""}`}>
            {
                (() => {
                    if (selectedSidebar === 'Chats' && editProfile && editProfile?.UserData?.payload?.data[0] !== undefined) {
                        return <ChatsIndex socket={socket} userId={userId} />
                    } 
                    else if (selectedSidebar === 'Messages') {
                        return <MessageIndex />
                    }
                    else if (selectedSidebar === 'Friends') {
                        return <FriendsIndex/>
                    } 
                    // else if (selectedSidebar === 'Favorites') {
                    //     return <FavoritesIndex/>
                    // } else if (selectedSidebar === 'Archived') {
                    //     return <ArchivedIndex/>
                    // } 
                    else 
                    if (selectedSidebar === 'Call Logs'){
                        return <CallLogIndex />
                    }else if (selectedSidebar === 'Contact Details'){
                        return <ContectDetails callRefButton = {callRefButton} client={client} setCallOpenModal={setCallOpenModal} />
                    }
                })()
            }
        </div>
    )
}

export default Index
