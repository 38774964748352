import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import axios from "axios";
import Validation from "../../validation/validation";
import API from "../../services/api_endpoint";
import Cookies from "../../services/cookies";
import Loader from "../../component/load/loader";
import { ToastContainer } from "react-toastify";
import { errorInDataSaving } from "../../component/Ui_Components/Toasts";
import firebase from "firebase";

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      loading: false,
      error_username: null,
      error_password: null,
      error: null,
      fcm_token: null,
    };
    this.validation = new Validation();
    this.cookies = new Cookies();
  }

  browser = function () {
    const test = function (regexp) { return regexp.test(window.navigator.userAgent) }
    switch (true) {
      case test(/edg/i): return "Edge";
      case test(/trident/i): return "MIE";
      case test(/firefox|fxios/i): return "Firefox";
      case test(/opr\//i): return "Opera";
      case test(/ucbrowser/i): return "UC";
      case test(/samsungbrowser/i): return "Samsung";
      case test(/chrome|chromium|crios/i): return "Chrome";
      case test(/safari/i): return "Safari";
      default: return "Other";
    }
  };

  componentDidMount() {
    if (this.cookies.loginStatus() === true) this.props.history.push("/");
    else this.cookies.logout();
    document.body.classList.add("form-membership");

    if (this.browser() !== 'Safari') {
      const messaging = firebase.messaging();
      messaging
        .getToken(messaging, {
          vapidKey:
            "key=AAAAbR4XOE0:APA91bFd9MWAto33xxg1sjMgH1gfb7Ja0FUhZMeTS6OYd8Bw96WEPAL5XfMp-r1zFK-PTYGD-qKm1Eg5mgD5rnc3PxHxhzM1Ff1ni4YB6wee5FIDMDaGnH7yurkmdDWe7rzIpE37TPFA",
        })
        .then((currentToken) => {
          console.log("currentToken===>", currentToken);
          this.cookies.setItem("fcm_token", currentToken)
          this.setState({
            fcm_token: currentToken,
          });
        })
        .catch((err) => {
          console.log("error fcm =====>", err);
        });
    } else {
      // this.cookies.setItem("fcm_token", 'notsupport')
      //   this.setState({
      //     fcm_token: 'notsupport',
      //   });
      const fcmge = this.generateRandomFcm(7) + "-safari-is" + this.generateRandomFcm(8)
      this.cookies.setItem("fcm_token", fcmge)
      this.setState({
        fcm_token: fcmge,
      });
    }
  }

  generateRandomFcm(nu) {
    let b = ''
    for (let a = 0; a < nu; a++) {
      b += (Math.random() + 1).toString(36).substr(2, 16)
    }
    return b
  }

  inputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setStateData(name, value);
  };

  setData = (data) => {
    this.setStateData(Object.keys(data)[0], data[Object.keys(data)[0]]);
    return data[Object.keys(data)[0]];
  };

  setStateData = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  validate = (event) => {
    let name = event.target.name;
    const Enum = Object.freeze({ username: 1, password: 2 });
    const value = Enum[name];

    switch (value) {
      case 1:
        this.setData(
          this.validation.validateLoginUsername(this.state.username)
        );
        break;
      case 2:
        this.setData(
          this.validation.validateLoginPassword(this.state.password)
        );
        break;
      default:
        break;
    }
  };

  submit = (event) => {
    event.preventDefault();

    let username = this.setData(
      this.validation.validateLoginUsername(this.state.username)
    );
    let password = this.setData(
      this.validation.validateLoginPassword(this.state.password)
    );

    if (username === null && password === null) {
      this.setState(
        {
          loading: true,
        },
        () => this.apiLogin()
      );
    } else {
      return;
    }
  };

  //   ~~~~~~~~~~~~~~~~~~~~~~~~ fcm initiation ~~~~~~~~~~~~~~~~~~~~~

  fcm = (token) => {

    axios
      .post(
        `${API.push_notification}?auth_key=${token}&key=${this.state.fcm_token}&os=web`
      )
      .then((res) => {
        console.log("fcm push notification success ===>", res);
      })
      .catch((err) => {
        console.log("fcm error ====>", err);
      });
  };

  //   ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  apiLogin = () => {
    axios
      .post(
        `${API.login}?login=${this.state.username}&password=${this.state.password}`
      )
      .then((response) => {
        // console.log("Login data =======>>", response.data);
        this.setStateData("loading", false);
        if (response.data.success === "true") {
          // this.createUser()

          // console.log("signInnnn--->>", this.props, this.state)
          this.cookies.setItem("KEY", response.data.data.key);
          this.cookies.setItem("forcreateuser", true);
          this.cookies.setItem("USERDATA", this.state.username);
          this.cookies.setItem("LOG", "1");
          this.fcm(response.data.data.key);

          if (!this.cookies.getItem("NewKey")) {
            this.cookies.setItem("NewKey", ["hello"]);
          }
          this.props.history.push("/");
          window.location.reload()
        } else {
          errorInDataSaving(response.data.result);
        }
      })
      .catch((error) => {
        this.setStateData("loading", false);
        if (error && error.response) {
          if (error.response.status === 400) {
            alert(error.response.data.message);
          } else if (error.response.status === 401) {
            alert(error.response.data.message);
          } else if (error.response.status === 404) {
            alert(error.response.data.message);
          } else if (error.response.status === 500) {
            alert("Internal server error");
          } else {
            alert("Something went wrong. Please try again later. Signin");
          }
        } else {
          alert("Something went wrong. Please try again later. Signin");
        }
      });
  };

  render() {
    return (
      <div className="form-wrapper">
        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <div className="logo">
          {/* <Logo/> */}
          <img
            width="150px"
            height="120px"
            src={require("./../../assets/logo.png").default}
            alt="logoImg"
          />
        </div>
        <h5>Welcome</h5>
        <p>Please enter your details to login to the account.</p>
        <form onSubmit={this.submit}>
          <div className="form-group">
            <input
              type="text"
              name="username"
              className="form-control"
              value={this.state.username}
              onChange={this.inputChange}
              onFocus={() => this.setState({ error_username: null })}
              onBlur={this.validate}
              placeholder="Username"
            />
            {this.state.error_username === null ? (
              <></>
            ) : (
              <span className="error_text">{this.state.error_username}</span>
            )}
          </div>
          <div className="form-group">
            <input
              type="password"
              name="password"
              className="form-control"
              value={this.state.password}
              onChange={this.inputChange}
              onFocus={() => this.setState({ error_password: null })}
              onBlur={this.validate}
              placeholder="Password"
            />
            {this.state.error_password === null ? (
              <></>
            ) : (
              <label className="error_text">{this.state.error_password}</label>
            )}
          </div>
          <div className="form-group d-flex justify-content-end">
            <NavLink to="/reset-password" className="float-right">
              Retrieve Password
            </NavLink>
          </div>
          <button className="btn btn-primary btn-block">LOGIN</button>
          <hr />
          <p className="text-muted">Don't have an account?</p>
          <NavLink
            to="/sign-up"
            className="btn btn-outline-light btn-sm color1"
          >
            REGISTER
          </NavLink>
        </form>
        <Loader loading={this.state.loading} />
      </div>
    );
  }
}
export default withRouter(SignIn);
