import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import {
//   Dropdown,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem,
// } from "reactstrap";
import * as FeatherIcon from "react-feather";
// import VoiceCallModal from "../Modals/VoiceCallModal"
import VideoCallModal from "../Modals/VideoCallModal";
import { profileAction } from "../../../Store/Actions/Profile/profileAction";
import { mobileProfileAction } from "../../../Store/Actions/Mobile/mobileProfileAction";
import axios from "axios";
import API from "../../../services/api_endpoint";
import Cookies from "../../../services/cookies";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { JitsiAction } from "../../../Store/Actions/Jitsi/JitsiAction";
import EditGroupForm from "../Modals/EditGroupForm";
// import { Link } from "react-router-dom";

function ChatHeader({
  userId,

  isTyping,
  isOnline,
  ChatStatus,
  client,
  receiverId,
  socket,
  friendList,
  chat,
}) {
  const { editProfile, selectedChat } = useSelector((state) => state);
  const { jitsiProfile } = useSelector((state) => state);
  const [userFcmChat, setUserFcmChat] = useState([]);
  const [sentErrorData, setSentErrorData] = useState(false);
  const cookies = new Cookies();
  const [userName, setUserName] = useState(window.localStorage.getItem("newFriendName"));
  const dispatch = useDispatch();
  const USER_NumberChat = editProfile.UserData?.payload?.data[0]?.phone;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [unqiueIdForInitial, setUnqiueIdForInitial] = useState(
    `${Math.floor(100000 + Math.random() * 900000)}-${Math.floor(100000 + Math.random() * 900000)}-${Math.floor(100000 + Math.random() * 900000)}`
  );
  const [participantIdsList, setParticipantIdsList] = useState(null);
  const [ModalOpen, setModalOpen] = useState(false);

  const modalToggle = () => setModalOpen(!ModalOpen);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const roomNameJitsi = selectedChat?.chat?.roomName.replace(/\s/g, "");

  const selectedChatClose = () => document.querySelector(".chat").classList.remove("open");

  const profileActions = () => {
    dispatch(profileAction(true));
    dispatch(mobileProfileAction(true));
  };

  const dialcallHandler = (number, cameraStatus) => {
    const calledIdd = (Math.random() + 1)?.toString(36)?.substr(2, 16) + number + (Math.random() + 1)?.toString(36)?.substr(2, 16);

    axios
      .post(`${API.getfcmbynumber}?auth_key=${cookies.getItem("KEY")}&number=${number}`)
      .then((response) => {
        const { data } = response;
        if (data.fcm === false || data.fcm === "null") {
          setSentErrorData([true, calledIdd]);
        } else if (data.fcm.includes("safari") && data.client === "web") {
          sendFcmtoClientSocket(data.fcm, cameraStatus);
          setUserFcmChat((prev) => {
            return [...prev, data.fcm];
          });
        } else {
          // sendFcmtoClientSocket(data.fcm);
          sendFcmtoClient(data.fcm, calledIdd, data.client, cameraStatus);
          setUserFcmChat((prev) => {
            return [...prev, data.fcm];
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendFcmtoClientSocket = (fcm, cameraStatus) => {
    const dataa = {
      ...data,
      fcm: fcm,
      room_id: `${roomNameJitsi || USER_NumberChat}`,
      userIdentifier: `${USER_NumberChat}`,
      isVideo: cameraStatus,
    };
    if (client.readyState === W3CWebSocket.OPEN) {
      client.send(JSON.stringify(dataa));
      dispatch(
        JitsiAction({
          isJistiopen: true,
          callerId: roomNameJitsi || USER_NumberChat,
          startWithVideoMuted: cameraStatus,
        })
      );
    } else {
      alert("something wrong !!!, Connection are not established");
    }
  };

  const sendFcmtoClient = (fcm, id, client, cameraStatus) => {
    const data = {
      to: fcm,
      priority: "high",
      notification: {
        title: "SwitchTell",
        // "mutable-content" : 1,
        body: cameraStatus ? "Video Conference Call" : "Video Conference Call",
        sound: "alarmfrenzy",
        android_channel_id: "myShadow",
        click_action: "notificationScreen",
        room_id: `${roomNameJitsi || USER_NumberChat}`,
        isVideo: cameraStatus,
      },
      data: {
        messageId: "1234",
        title: "Incoming Call..",
        body: cameraStatus ? "Video Conference Call" : "Video Conference Call",
        sos: "true",
        emergency: "true",
        accept: "accept",
        decline: "decline",
        userIdentifier: `${roomNameJitsi || USER_NumberChat}`,
        room_id: `${roomNameJitsi || USER_NumberChat}`,
        isVideo: cameraStatus,
      },
    };
    client === "android" && delete data.notification["click_action"];

    const header = {
      Authorization: "key=AAAAbR4XOE0:APA91bFd9MWAto33xxg1sjMgH1gfb7Ja0FUhZMeTS6OYd8Bw96WEPAL5XfMp-r1zFK-PTYGD-qKm1Eg5mgD5rnc3PxHxhzM1Ff1ni4YB6wee5FIDMDaGnH7yurkmdDWe7rzIpE37TPFA",
      "Content-Type": "application/json",
    };
    axios
      .post(`https://fcm.googleapis.com/fcm/send`, data, {
        headers: header,
      })
      .then((response) => {
        // if(true){
        if (response.data.success === 1) {
          dispatch(
            JitsiAction({
              isJistiopen: true,
              callerId: roomNameJitsi || USER_NumberChat,
              startWithVideoMuted: cameraStatus,
            })
          );
          // dispatch(JitsiAction({ isJistiopen: true, callerId: (Math.random() + 1).toString(36).substr(2, 16)+number+(Math.random() + 1).toString(36).substr(2, 16) }));
          const { data } = response;
          // console.log(data)
        } else {
          alert(`something wrong,  ${response.data.results[0].error}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const groupVideoCall = () => {
    receiverId.map((item, i) => {
      dialcallHandler(item, true);
    });
  };
  const groupAudioCall = () => {
    receiverId.map((item, i) => {
      dialcallHandler(item, false);
    });
  };

  console.log("testing==>", USER_NumberChat == selectedChat?.chat?.initiatedBy, "hello", receiverId?.length);
  return (
    <div>
      <div className="chat-header" id="st-chat-header">
        <div
          className="chat-header-user"
          style={{
            cursor: receiverId?.length >= 1 ? "pointer" : "default",
            // cursor: USER_NumberChat == selectedChat?.chat?.initiatedBy && receiverId?.length > 1 ? "pointer" : "default",
          }}
          onClick={() => receiverId?.length > 1 && modalToggle()}
          // onClick={() => USER_NumberChat == selectedChat?.chat?.initiatedBy && receiverId?.length > 1 && modalToggle()}
        >
          <img src="https://dummyimage.com/50x45/000/fff" alt="dummyHeaderImg" />
          <div className="infoSection">
            <h5 className="d-flex align-items-center">
              {selectedChat?.friendName || userName}

              {ChatStatus?.chatType == 1 && ChatStatus?.onlineUsers?.length == 2 && <span></span>}
            </h5>
            {ChatStatus?.chatType == 1 && ChatStatus?.onlineUsers?.length == 2 ? (
              <small className="text-muted">
                <i>Active</i>
              </small>
            ) : (
              <>
                {ChatStatus?.chatType == 1 && (
                  <small className="text-muted">
                    <i>Offline</i>
                  </small>
                )}
              </>
            )}
            <p className="text-muted">
              <i>{isTyping && "Typing..."}</i>
            </p>
          </div>
        </div>
        <div className="chat-header-action">
          <ul className="list-inline">
            <li className="list-inline-item d-xl-none d-inline">
              <button onClick={selectedChatClose} className="btn btn-outline-light text-danger mobile-navigation-button">
                <FeatherIcon.X />
              </button>
            </li>
            {receiverId?.length >= 1 && (
              <li className="list-inline-item">
                <div>
                  <button
                    className="btn btn-outline-light text-warning"
                    id="Tooltip-Voice-Call"
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      // receiverId?.length == 1
                      //   ? dialcallHandler(receiverId[0])
                      //   : groupVideoCall();
                      groupVideoCall();
                    }}
                  >
                    <FeatherIcon.Video />
                  </button>
                  <button
                    className="btn btn-outline-light text-warning"
                    id="Tooltip-Voice-Call"
                    onClick={() => {
                      groupAudioCall();
                    }}
                  >
                    <FeatherIcon.Phone />
                  </button>
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
      {ModalOpen && (
        <EditGroupForm
          socket={socket}
          userId={userId}
          modalToggle={modalToggle}
          setParticipantIdsList={setParticipantIdsList}
          participantIdsList={participantIdsList}
          friendList={friendList}
          modal={ModalOpen}
          setModal={setModalOpen}
          chat={selectedChat}
        />
      )}
    </div>
  );
}

export default ChatHeader;

const data = {
  title: "Incoming Video Call..",
  body: `Video Conference Call`,
  room_id: `1231231231`,
  userIdentifier: `1231231231`,
  messageId: "1234",
  sound: "alarmfrenzy",
  android_channel_id: "myShadow",
  click_action: "notificationScreen",
  sos: "true",
  emergency: "true",
  accept: "accept",
  decline: "decline",
};
