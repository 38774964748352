import React, { useEffect, useRef } from "react";
import { Button } from "reactstrap";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import "./documentst.scss";

const DocumentST = ({ mediaselected, setMediaSelected, setMediaArray,document, setdocument, submitBtnStatus }) => {
  const { selectedChat } = useSelector((state) => state);
  const documentRef = useRef(null);

  const mediaHandle = (event) => {
    if(event?.target?.files[0].size < 10485760){
    if(event?.target?.files[0]){
      setMediaSelected('file')
      setdocument(event.target.files[0]);
      setMediaArray([event.target.files[0]]);
    } else {
      removeDocumentHandle();
    }
  } else {
    alert("Maximum file size: 10mb")
  }
  };

  const removeDocumentHandle = () => {
    setMediaSelected("")
    setdocument(null);
    setMediaArray(null);
  };

  useEffect(() => {
    document && setdocument(null);
  }, [selectedChat]);
  return (
    <>
      {document && <MediaPreview media={document} removeDocument={removeDocumentHandle} submitBtnStatus={submitBtnStatus} />}
      <input
        onChange={(event) => {
          mediaHandle(event);
        }}
        onClick = {(event) =>{
          event.target.value = null;
        }}
        ref={documentRef}
        accept=".txt,.pdf,.doc, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        className="comment-file-input"
        id="image-file-input"
        type="file"
        hidden
      />
      <Button
        disabled={mediaselected === 'image' || mediaselected === 'audio' || mediaselected === 'video'}
        color="light"
        className="mr-3"
        title="file"
        onClick={(e) => {
          e.target.blur()
          documentRef.current.click();
        }}
        onKeyDown={(e) => {
          e.target.blur()
        }}
      >
        <AttachFileIcon />
      </Button>
    </>
  );
};

const MediaPreview = ({ media, removeDocument, submitBtnStatus }) => {
  return (
    <>
      <div className="document-preview-container">
        <div className="document-preview-wrap">
          <div className="document-wrap-media">
            <div className="file-container">
            {!submitBtnStatus && <div
                onClick={() => {
                  removeDocument();
                }}
                className="remove-icon-document file"
              >
                <CancelIcon />
              </div>}
              <p className="fileTitle">{media.name}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentST;
