import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "reactstrap";
import { sidebarAction } from "../../Store/Actions/sidebarAction";
import { mobileSidebarAction } from "../../Store/Actions/Mobile/mobileSidebarAction";

const NavigationItemView = (props) => {
  const dispatch = useDispatch();
  const { selectedSidebar } = useSelector((state) => state);

  const { item, tooltipName } = props;

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const linkDispatch = (e, name) => {
    e.preventDefault();
    dispatch(sidebarAction(name));
    dispatch(mobileSidebarAction(true));
  };

  return (
    <li>
      <a onClick={(e) => linkDispatch(e, item.name)} href={item.name} className={`sidebar ${selectedSidebar === item.name ? "active" : ""}`} id={tooltipName}>
        {item.badge && <span className={"badge badge-" + item.badge}>&nbsp;</span>}
        {item.icon}
      </a>
      <Tooltip placement="right" isOpen={tooltipOpen} target={tooltipName} toggle={toggle}>
        {item.name}
      </Tooltip>
    </li>
  );
};

export default NavigationItemView;
