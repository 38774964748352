export const formatToTime = (time) => {
    let unix_timestamp = time;
    const date = new Date(time);
    // Hours part from the timestamp
    const hours = date.getHours();
    // Minutes part from the timestamp
    const minutes = "0" + date.getMinutes();
    // Seconds part from the timestamp
    // const seconds = "0" + date.getUTCSeconds();

    const formattedTime = hours + ":" + minutes.substr(-2);
    return formattedTime;
};
export const formatToTimeexpire = (time) => {
  let unix_timestamp = time;
  const date = new Date(unix_timestamp * 1000);
  // Hours part from the timestamp
  const hours = date.getUTCHours();
  // Minutes part from the timestamp
  const minutes = "0" + date.getUTCMinutes();
  // Seconds part from the timestamp
  // const seconds = "0" + date.getUTCSeconds();

  const formattedTime =
    hours + ":" + minutes.substr(-2)

  return formattedTime;
};