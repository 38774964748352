import React, { useState, useEffect } from "react";
import { Tooltip } from "reactstrap";
import axios from "axios";
import API from "../../../services/api_endpoint";
import { useSelector } from "react-redux";
import "./AddGroupModal.scss";
import * as FeatherIcon from "react-feather";
import AddGroupForm from "./AddGroupForm";

function AddGroupModal({ socket, userId }) {
  const [tooltipOpenGroup, setTooltipOpenGroup] = useState(false);

  const tooltipToggleGroup = () => setTooltipOpenGroup(!tooltipOpenGroup);
  const [modal, setModal] = useState(false);

  const modalToggle = () => setModal(!modal);

  const [participantIdsList, setParticipantIdsList] = useState([]);

  const { editProfile } = useSelector((state) => state);

  const [friendList, setFriendList] = useState(null);

  const getContactSync = () => {
    axios
      .get(`${API.getContact}?userId=${editProfile?.UserData?.payload?.data[0]?.id}`)
      .then((response) => {
        const { data } = response;
        let phones = data?.data.map((phone) => {
          return String(phone.phone);
        });
        const arr = data.data
        setFriendList([...new Set(arr)]);
        console.log('arr',[...new Set(arr)]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getContactSync();
  }, []);

  return (
    <>
      <div>
        <button className="btn btn-outline-light" onClick={modalToggle} id="Tooltip-Add-Group">
          <FeatherIcon.Users />
        </button>
        <Tooltip placement="bottom" isOpen={tooltipOpenGroup} target={"Tooltip-Add-Group"} toggle={tooltipToggleGroup}>
          Add group
        </Tooltip>
        {modal && (
          <AddGroupForm
            socket={socket}
            userId={userId}
            modalToggle={modalToggle}
            setParticipantIdsList={setParticipantIdsList}
            participantIdsList={participantIdsList}
            friendList={friendList}
            modal={modal}
            setModal={setModal}
          />
        )}
      </div>
    </>
  );
}

export default AddGroupModal;
