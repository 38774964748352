class API {
  static login = `https://hypefrezh.com/a2billing/api7dwWGAC53rU7zDUX5E5rQaFJq/v2/auth.php`;
  static signup = `https://hypefrezh.com/a2billing/api7dwWGAC53rU7zDUX5E5rQaFJq/v2/register.php`;
  static reset_password = `https://hypefrezh.com/a2billing/api7dwWGAC53rU7zDUX5E5rQaFJq/v2/forgot.php`;
  static profile_view = `https://hypefrezh.com/a2billing/api7dwWGAC53rU7zDUX5E5rQaFJq/v2/profile.php`;
  static profile_update = `https://hypefrezh.com/a2billing/api7dwWGAC53rU7zDUX5E5rQaFJq/v2/profile.php`;
  static account_delete = `https://hypefrezh.com/a2billing/api7dwWGAC53rU7zDUX5E5rQaFJq/v2/delete.php`;
  static password_update = `https://hypefrezh.com/a2billing/api7dwWGAC53rU7zDUX5E5rQaFJq/v2/profile.php?action=edit`;
  static calllogs = `https://hypefrezh.com/a2billing/api7dwWGAC53rU7zDUX5E5rQaFJq/v2/cdrs.php`;
  static virtualNumbers = `https://hypefrezh.com/a2billing/api7dwWGAC53rU7zDUX5E5rQaFJq/v2/did_list.php`;
  static subscribe = `https://hypefrezh.com/a2billing/api7dwWGAC53rU7zDUX5E5rQaFJq/v2/did_assign.php`;
  static payment = `https://hypefrezh.com/a2billing/api7dwWGAC53rU7zDUX5E5rQaFJq/v2/paypal.php`;
  static outgoing_msg = `https://hypefrezh.com/a2billing/api7dwWGAC53rU7zDUX5E5rQaFJq/v2/send_sms.php`;
  static inbound_outbound_msg = `https://hypefrezh.com/a2billing/api7dwWGAC53rU7zDUX5E5rQaFJq/v2/mdrs.php`;
  static push_notification = `https://hypefrezh.com/a2billing/api7dwWGAC53rU7zDUX5E5rQaFJq/v2/notify_fcm.php`;
  static getfcmbynumber = `https://hypefrezh.com/a2billing/api7dwWGAC53rU7zDUX5E5rQaFJq/v2/id_by_number.php`;
  static contactSync = `https://hfresh.co/switchtell/api/syncContacts.php`;
  static getShareProfile = `https://hfresh.co/switchtell/api/shareProfile.php`;
  static createUser = `https://hfresh.co/switchtell/api/createUser.php`;
  static getContact = `https://hfresh.co/switchtell/api/getContacts.php`;
  static updateDetails = `https://hfresh.co/switchtell/api/updateDetails.php`;
  static allowNotification = `https://hfresh.co/switchtell/api/updateNotificationSettings.php`;
}
export default API;
