import Cookies from "js-cookie";

class Cookie {
    setItem = (key, value) => {
        Cookies.set(key, value, { expires: 90 })
    }

    getItem = (key) => {
        let item = Cookies.get(key);
        return item;
    }

    removeItem = (key) => {
        Cookies.remove(key);
    }

    logout = () => {
        Cookies.remove('LOG');
        Cookies.remove('KEY');
        // Cookies.remove('NewKey');
        Cookies.remove('USERDATA');
        Cookies.remove('dids')
        Cookies.remove('fcm_token')
        return true;
    }

    loginStatus = () => {
        if (this.getItem('LOG') === "1" && this.getItem('KEY') !== "" && this.getItem('KEY') !== undefined && this.getItem('KEY') !== null) {
            return true;
        }
        else {
            return false;
        }
    }
}

export default Cookie;
