const initialState = {
   response : {}
}



const subscribeReducer = (state = initialState,{type,payload}) =>
{  
    switch(type) 
    {
        case 'SUBSCRIBE' : return {
            ...state,
            response : payload
        };
        default : return state
    }
}


export default subscribeReducer;