import axios from "axios"
import API from "../../../services/api_endpoint"
import Cookies from "../../../services/cookies";
import { callLogsAction } from "./CallLogsAction";

const cookies = new Cookies();

const callLogsFetch = () => {
    return async (dispatch) => {
        await axios.get(`${API.calllogs}?limit=20&auth_key=${cookies.getItem('KEY')}`)
            .then(response => {
                const { data } = response;
                
                dispatch(callLogsAction({ type: 'CALL_LOGS', payload: { ...data } }))
            })
            .catch(err => console.log(err))
    }
}



export { callLogsFetch };