import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import * as FeatherIcon from 'react-feather'
import { useDispatch } from 'react-redux'
import { JitsiAction } from "../../../Store/Actions/Jitsi/JitsiAction";

function VoiceCallModal({ show, setShow, number,isVideo }) {

    const dispatch = useDispatch();
    const modalToggle = () => setShow(!show);

    const jisi = () => {
        dispatch(JitsiAction({ isJistiopen: true, callerId: number,startWithVideoMuted:isVideo }));
    }

    return (
        <div>
            <Modal isOpen={show} toggle={modalToggle} centered className="modal-dialog-zoom video-call-wrap" backdrop="static">
                <ModalBody>
                    <div className="call">
                        <div>
                            {/* <figure className="avatar avatar-xl mb-4">
                                <img src={ManAvatar5} className="rounded-circle" alt="avatar"/>
                            </figure> */}
                            <h4 style={{ paddingTop: '1.5rem', color:"#fff" }}>Incoming  <span className="text-success">{isVideo===true ? "Video" : "Audio"} Call</span></h4>
                            <div className="action-button">
                                <button type="button" onClick={modalToggle}
                                    className="btn btn-danger btn-floating btn-lg"
                                    data-dismiss="modal">
                                    <FeatherIcon.X />
                                </button>
                                <button type="button" onClick={() => {
                                    modalToggle();
                                    jisi();
                                }}
                                    className="btn btn-success btn-pulse btn-floating btn-lg">
                                    <FeatherIcon.Phone />
                                </button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default VoiceCallModal
