import React, { useState } from "react";
import { Modal, ModalBody, Tooltip } from "reactstrap";
import * as FeatherIcon from "react-feather";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import Validation from "../../../validation/validation";
import { JitsiAction } from "../../../Store/Actions/Jitsi/JitsiAction";
import { LoadingAction } from "../../../Store/Actions/Jitsi/LoadingAction";
import "./Style.css";

function VideoCallModal() {
  const dispatch = useDispatch();
  const { jitsiProfile } = useSelector((state) => state);
  const [input, setInput] = useState(jitsiProfile.callerId);
  const [errorInput, setErrorInput] = useState(" ");
  const [modal, setModal] = useState(false);
  const [isToggle, setIsToggle] = useState(false);

  const handleIsToggle = () => {
    let input_validate = Validation.validateInput(input, setErrorInput);
    if (input_validate === true) {
      dispatch(JitsiAction({ isJistiopen: true, callerId: input, }));
      dispatch(LoadingAction({ loading: false, }));
      setIsToggle(true);
    }
  };
  const modalToggle = () => {
    setModal(!modal);
    setErrorInput("");
    setInput("");
  };
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const tooltipToggle = () => setTooltipOpen(!tooltipOpen);

  const handleClick = (event) => {
    setInput(event.target.value);
  };

  return (
    <div>
      <button
        className="btn btn-outline-light text-warning"
        onClick={modalToggle}
        id="Tooltip-Video-Call"
      >
        <FeatherIcon.Video />
      </button>
      <Tooltip
        placement="bottom"
        isOpen={tooltipOpen}
        target={"Tooltip-Video-Call"}
        toggle={tooltipToggle}
      >
        Video Call
      </Tooltip>

      <div>
        <Modal isOpen={modal} centered className="modal-dialog-zoom call">
          <ModalBody className="modalBody">
            <>
              <div className="d-flex align-items-center">
                <div className="d-flex text-center textfield mt-4">
                  <input
                    type="text"
                    autocomplete="off"
                    className="form-control inputbox"
                    placeholder="Enter room ID"
                    name="input"
                    value={input}
                    onChange={handleClick}
                    onBlur={() => {
                      Validation.validateInput(input, setErrorInput);
                    }}
                  />

                  <button
                    style={{ marginLeft: "12px" }}
                    onClick={handleIsToggle}
                    className="btn btn-primary "
                  >
                    Start Meeting
                  </button>
                </div>
                <div className="closeIcon">
                  <CloseIcon onClick={modalToggle} />
                </div>
              </div>
              {errorInput === true ? (
                console.log("Error")
              ) : (
                // <div className="textError">{errorInput}</div>
                <input className="textError" value={errorInput} />
              )}
            </>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
}

export default VideoCallModal;
