import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import Validation from '../../validation/validation';
import Loader from "../../component/load/loader";
import firebase from '../../firebase';
import Cookies from '../../services/cookies';
import DropDownCountryCode from '../../component/country_code';

class Phone extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            countrycode: "code",
            username: '',
            otp: '',
            time: '',
            showOtp: false,
            firebaseApi: null,
            resend: false,
            time: 0,
            error: null,
            error_username: null,
            error_country_code: null,
            error_otp: null,
            loading: false
        }
        this.validation = new Validation();
        this.cookies = new Cookies();
    }

    setCountryCode = (countrycode) => {
        this.setState({
            countrycode: countrycode
        })
    }

    componentDidMount() {
        document.body.classList.add('form-membership');
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    inputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setStateData(name, value);
    }

    setData = (data) => {
        this.setStateData(Object.keys(data)[0], data[Object.keys(data)[0]]);
        return data[Object.keys(data)[0]];
    }

    setStateData = (name, value) => {
        this.setState({
            [name]: value
        });
    }

    validate = (event) => {
        let name = event.target.name;
        const Enum = Object.freeze({ 'username': 1, 'countrycode': 2, 'otp': 3 });
        const value = Enum[name];

        switch (value) {
            case 1:
                this.setData(this.validation.validateLoginUsername(this.state.username));
                break;
            case 2:
                this.setData(this.validation.validateCountryCode(this.state.countrycode));
                break;
            case 3:
                this.setData(this.validation.validateOtp(this.state.otp));
                break;

            default:
                break;
        }
    }

    submit = (event) => {
        event.preventDefault();
        let username = this.setData(this.validation.validateLoginUsername(this.state.username));
        let countrycode = this.setData(this.validation.validateCountryCode(this.state.countrycode));
        if (username === null && countrycode === null) {
            this.setState({
                loading: true
            }, () => this.onSignInSubmit());
        }
        else {
            return;
        }
    }


    onSignInSubmit = () => {
        document.getElementById("recaptcha-container").innerHTML = "<div id='recaptcha'></div>";

        let recaptcha = new firebase.auth.RecaptchaVerifier('recaptcha', {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
            }
        });
        let number = `${this.state.countrycode}${this.state.username}`;
        firebase.auth().signInWithPhoneNumber(number, recaptcha)
            .then((result) => {
                this.setState({
                    firebaseApi: result,
                    showOtp: true,
                    loading: false,
                    time: 60
                }, () => {
                    this.tick();
                    alert("OTP (One Time Password) Send.");
                })
            })
            .catch((err) => {
                alert(err);
                window.location.reload();
            });
    }

    submitOtp = (event) => {
        event.preventDefault();
        let validate_otp = this.setData(this.validation.validateOtp(this.state.otp));
        if (validate_otp === null) {
            this.state.firebaseApi.confirm(this.state.otp).then((result) => {
                this.props.history.push('/additional-info', `${this.state.countrycode}${this.state.username}`);
            })
                .catch((err) => {
                    alert("Wrong OTP");
                })
        }
        else {
            return;
        }
    }

    tick = () => {
        this.timerID = setInterval(
            () => {
                if (this.state.time > 0) {
                    this.setState({
                        time: this.state.time - 1
                    })
                }
                else {
                    this.setState({
                        resend: true
                    })
                    clearInterval(this.timerID);
                }
            },
            1000
        );
    }

    render() {
        return (
            <div className="form-wrapper">
                <div className="logo">
                    <img width="150px" height="120px" src={require('./../../assets/logo.png').default} />
                </div>
                {
                    (!this.state.showOtp) ?
                        <>
                            <h5>Register</h5>
                            <p>Let's create you SwitchTell profile, enter your details.</p>
                            <form onSubmit={this.submit}>
                                <div className="form-group">
                                    <DropDownCountryCode countrycode={this.state.countrycode} setCountryCode={this.setCountryCode} onBlur={this.validate} onFocus={() => { this.setState({ error_country_code: null }) }} />
                                    {this.state.error_country_code === null ? <></> : <><span className="error_text">{this.state.error_country_code}</span><br /></>}
                                </div>

                                <div className="form-group">
                                    <input type="text" className="form-control" name="username" placeholder="Mobile Number" onChange={this.inputChange} onFocus={() => this.setState({ error_username: null })} onBlur={this.validate} value={this.state.username} />
                                    {this.state.error_username === null ? <></> : <span className="error_text">{this.state.error_username}</span>}
                                </div>

                                <div ref={ref => this.recaptchaWrapperRef = ref}>
                                    <div id="recaptcha-container"></div>
                                </div>

                                <button className="btn btn-primary btn-block" onClick={this.submit}>NEXT</button>

                                <p className="text-muted mt-3">By register you are agree to our <NavLink className="color1" to="/terms-and-conditions">Terms & conditions</NavLink> <NavLink className="color1" to="/privacy-policy">& Privacy policy</NavLink></p>
                                <hr />
                                <p className="text-muted">Already have an account?</p>
                                <NavLink to="/sign-in" className="btn btn-sm btn-outline-light ml-1 color1">LOGIN</NavLink>
                            </form>
                        </>
                        :
                        <>
                            <h5>Verification</h5>
                            <p>Please enter your 6-digit(OTP) you have received on this number <b>{`${this.state.countrycode}${this.state.username}`}</b></p>
                            <form onSubmit={this.submitOtp}>

                                <div className="form-group">
                                    <input type="text" name="otp" className="form-control" autoComplete="off" value={this.state.otp} onBlur={this.validate} onChange={this.inputChange} onFocus={() => this.setState({ error_otp: null })} placeholder="Enter OTP" />
                                    {this.state.error_otp === null ? <></> : <label className="error_text">{this.state.error_otp}</label>}
                                </div>

                                <div ref={ref => this.recaptchaWrapperRef = ref}>
                                    <div id="recaptcha-container"></div>
                                </div>

                                <button className="btn btn-primary btn-block">SUBMIT</button>

                                <p className="text-muted mt-3">{this.state.time} Sec</p>
                                <p className="text-muted mt-3">Didn't receive? {this.state.resend ? <span className="color1 pointer" onClick={() => { this.setState({ resend: false, error_otp: null }, () => this.onSignInSubmit()) }}> RESEND</span> : <span> RESEND</span>}</p>
                                <hr />
                                <NavLink to="/sign-in" className="btn btn-sm btn-outline-light ml-1 color1">LOGIN</NavLink>
                            </form>
                        </>
                }

                <Loader loading={this.state.loading} />
            </div>
        );
    }
}
export default withRouter(Phone);
