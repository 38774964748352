import * as FeatherIcon from "react-feather";

export const navigationItems = [
    {
      name: "Chats",
      icon: <FeatherIcon.MessageCircle />,
      badge: "warning",
    },
    {
      name: "Messages",
      icon: <FeatherIcon.Mail />,
      badge: "warning",
    },
    {
      name: "Call Logs",
      icon: <FeatherIcon.PhoneCall />,
    },
    {
      name: "Contact Details",
      icon: <FeatherIcon.BookOpen />,
    },

    {
      name: "Friends",
      icon: <FeatherIcon.User />,
      badge: "danger",
    },
    // {
    //   name: "Favorites",
    //   icon: <FeatherIcon.Star />,
    // },
    // {
    //   name: "Archived",
    //   icon: <FeatherIcon.Archive />,
    // },
  ];
