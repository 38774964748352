import React, { useState } from 'react'
import { useDispatch } from "react-redux"
import {
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap'
import * as FeatherIcon from 'react-feather'
import { profileAction } from "../../../Store/Actions/Profile/profileAction"
import { mobileProfileAction } from "../../../Store/Actions/Mobile/mobileProfileAction";

function SmsHeader(props) {
    const { messageSms, smsFriendNameHeader } = props;

    let headerName = smsFriendNameHeader?.filter((item) => {
        return item.dId === messageSms[0]?.num_b
    })

    const dispatch = useDispatch();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);

    const profileActions = () => {
        dispatch(profileAction(true));
        dispatch(mobileProfileAction(true))
    };

    return (
        <div className="sms-header">
            <div className="sms-header-user">
                <div>
                    <h5>{headerName?.length > 0 ? headerName[0]?.firstName : messageSms[0]?.num_b}</h5>
                    {/* <small className="text-muted">
                        <i>5 minutes ago</i>
                    </small> */}
                </div>
            </div>
            <div className="sms-header-action">
                {/* <ul className="list-inline">
                    <li className="list-inline-item" data-toggle="tooltip" title="Video call">
                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle
                                tag="span"
                                data-toggle="dropdown"
                                aria-expanded={dropdownOpen}
                            >
                                <button className="btn btn-outline-light">
                                    <FeatherIcon.MoreHorizontal />
                                </button>
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem onClick={profileActions}>Profile</DropdownItem>
                                <DropdownItem>Add to archive</DropdownItem>
                                <DropdownItem>Delete</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem>Block</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </li>
                </ul> */}
            </div>
        </div>
    )
}

export default SmsHeader
