import {createStore, combineReducers, applyMiddleware, compose} from "redux"

import sidebarReducer from "./Reducers/sidebarReducer"
import mobileSidebarReducer from "./Reducers/mobileSidebarReducer"
import profileSidebarReducer from "./Reducers/profileSidebarReducer"
import mobileProfileSidebarReducer from "./Reducers/mobileProfileSidebarReducer"
import pageTourReducer from "./Reducers/pageTourReducer"
import selectedChatReducer from "./Reducers/selectedChatReducer"
import callLogReducer  from "./Reducers/callLogReducer"
import thunk from "redux-thunk"
import virtualNumberReducer from "./Reducers/VirtualNumberReducer"
import subscribeReducer from "./Reducers/subscribeReducer"
import paymentReducer from "./Reducers/PaymentReducer"
import editProfileReducer from "./Reducers/editProfileReducer"
import JitsiReducer from "./Reducers/JitsiReducer"
import LoadingReducer from "./Reducers/LoadingReducer"
import messageReducer from "./Reducers/messageReducer"
import didNumberReducer from "./Reducers/didNumberReducer"

const reducers = combineReducers({
    selectedSidebar: sidebarReducer,
    mobileSidebar: mobileSidebarReducer,
    profileSidebar: profileSidebarReducer,
    mobileProfileSidebar: mobileProfileSidebarReducer,
    pageTour: pageTourReducer,
    selectedChat: selectedChatReducer,
    messageSms: messageReducer,
    callLog : callLogReducer,
    virtualNumber  : virtualNumberReducer,
    subscribe : subscribeReducer,
    payment : paymentReducer,
    editProfile : editProfileReducer,
    jitsiProfile : JitsiReducer,
    loadingProfile : LoadingReducer,
    didNumber: didNumberReducer,

});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const store = createStore(reducers,composeEnhancer(applyMiddleware(thunk)));

export default store
