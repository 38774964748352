class Validation {
  validateLoginUsername = (text) => {
    text = text.trim();
    let length = text.length;
    let data = { error_username: null };

    if (length === 0) {
      data = { error_username: "Please fill the valid username." };
      return data;
    }

    if (length !== 10) {
      data = { error_username: "Please fill the valid username." };
      return data;
    }

    if (/^\d+$/.test(text) !== true) {
      data = { error_username: "Please fill the valid username." };
      return data;
    }

    return data;
  };

  static validateMobileNumber = (text) => {

    let length = text.length;
    text = text.trim();
    let data = { setError_phone: null };

    if (length === 0) {
      data = { setError_phone: "Please fill the valid number." };
      return data;
    }

    if (!(length === 10 || length === 12)) {
      data = { setError_phone: "Please fill the valid number." };
      return data;
    }

    if (/^\d+$/.test(text) !== true) {
      data = { setError_phone: "Please fill the valid number." };
      return data;
    }
    return data;
}


  validateLoginPassword = (text) => {
    let length = text.length;
    let data = { error_password: null };

    if (length === 0) {
      data = { error_password: "Please fill the valid Password." };
      return data;
    }
    return data;
  };

  validateFirstName = (text) => {
    text = text.trim();
    let length = text.length;
    let data = { error_first_name: null };

    if (length === 0) {
      data = { error_first_name: "Please enter the first name to proceed." };
      return data;
    }

    if (length <= 2) {
      data = { error_first_name: "First name must be 3 character long." };
      return data;
    }

    if (length > 15) {
      data = { error_first_name: "First name not more than 15 character." };
      return data;
    }

    if (/^[a-zA-Z]*$/.test(text) !== true) {
      data = { error_first_name: "First name must contain alphabets." };
      return data;
    }

    return data;
  };

  validateLastName = (text) => {
    text = text.trim();
    let length = text.length;
    let data = { error_last_name: null };

    if (length === 0) {
      data = { error_last_name: "Please enter the last name to proceed." };
      return data;
    }

    if (length <= 2) {
      data = { error_last_name: "Last name must be 3 character long." };
      return data;
    }

    if (length > 15) {
      data = {
        error_last_name: "Last name not more than maximum 15 character.",
      };
      return data;
    }

    if (/^[a-zA-Z ]*$/.test(text) !== true) {
      data = { error_last_name: "Last name must contain alphabet." };
      return data;
    }

    return data;
  };

  validateEmail = (text) => {
    text = text.trim();
    let length = text.length;
    let data = { error_email: null };

    if (length === 0) {
      data = { error_email: "Please enter valid Email." };
      return data;
    }

    if (length > 256) {
      data = { error_email: "Email not more than 256 character." };
      return data;
    }

    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        text
      ) !== true
    ) {
      data = { error_email: "Please enter valid Email." };
      return data;
    }

    return data;
  };

  validatePhoneNumber = (text) => {
    text = text.trim();
    let length = text.length;
    let data = { error_phone: null };

    if (length === 0) {
      data = { error_phone: "Please enter the Mobile number to proceed." };
      return data;
    }

    if (/^\d+$/.test(text) !== true) {
      data = { error_phone: "Mobile number must contain digit." };
      return data;
    }

    return data;
  };

  validateCity = (text) => {
    text = text.trim();
    let length = text.length;
    let data = { error_city: null };

    if (length === 0) {
      data = { error_city: "Please enter the City to proceed." };
      return data;
    }

    if (length <= 2) {
      data = { error_city: "City must be 3 character long." };
      return data;
    }

    if (length > 20) {
      data = { error_city: "City not more than 20 character." };
      return data;
    }

    if (/^[a-zA-Z ]*$/.test(text) !== true) {
      data = { error_city: "City must contain alphabets." };
      return data;
    }
    return data;
  };

  validateState = (text) => {
    text = text.trim();
    let length = text.length;
    let data = { error_state: null };

    if (length === 0) {
      data = { error_state: "Please enter the State to proceed." };
      return data;
    }

    if (length <= 2) {
      data = { error_state: "State must be 3 character long." };
      return data;
    }

    if (length > 20) {
      data = { error_state: "State not more than 20 character." };
      return data;
    }

    if (/^[a-zA-Z ]*$/.test(text) !== true) {
      data = { error_state: "State must contain alphabets." };
      return data;
    }
    return data;
  };

  validateZipCode = (text) => {
    text = text.trim();
    let length = text.length;
    let data = { error_zipcode: null };

    if (length === 0) {
      data = { error_zipcode: "Please enter the Zipcode to proceed." };
      return data;
    }

    return data;
  };

  validateFax = (text) => {
    text = text.trim();
    let length = text.length;
    let data = { error_fax: null };

    if (length === 0) {
      // data = ({'error_fax': 'Fax field is required.'});
      return data;
    }

    return data;
  };

  validateAddress = (text) => {
    text = text.trim();
    let length = text.length;
    let data = { error_address: null };

    if (length === 0) {
      data = { error_address: "Please enter the Address to proceed." };
      return data;
    }

    if (length < 5) {
      data = { error_address: "Address must be atleast 5 character long." };
      return data;
    }

    if (length > 60) {
      data = { error_address: "Address not more than 60 character." };
      return data;
    }

    return data;
  };

  validateCompanyName = (text) => {
    text = text.trim();
    let length = text.length;
    let data = { error_company_name: null };

    if (length === 0) {
      data = { error_company_name: "Please enter the Company to proceed." };
      return data;
    }

    if (length < 5) {
      data = {
        error_company_name: "Company Name must be atleast 5 character long.",
      };
      return data;
    }

    if (length > 30) {
      data = { error_company_name: "Company Name not more than 30 character." };
      return data;
    }

    return data;
  };

  validateOtp = (text) => {
    text = text.trim();
    let length = text.length;
    let data = { error_otp: null };

    if (length === 0) {
      data = { error_otp: "OTP field is required." };
      return data;
    }

    if (length !== 6) {
      data = { error_otp: "OTP must be six digit long." };
      return data;
    }

    if (/^\d+$/.test(text) !== true) {
      data = { error_otp: "OTP must contain digit." };
      return data;
    }

    return data;
  };

  validateNewPassword = (text) => {
    let length = text.length;
    let data = { error_new_password: null };

    if (length === 0) {
      data = {
        error_new_password: "Please enter the new password to proceed.",
      };
      return data;
    }
    return data;
  };

  validateConfirmPassword = (password, confirm_password) => {
    let length = confirm_password.length;
    let data = { error_confirm_password: null };

    if (length === 0) {
      data = {
        error_confirm_password: "Please enter the confirm password to proceed.",
      };
      return data;
    }

    if (password !== confirm_password) {
      data = {
        error_confirm_password: "Password and confirm password does not match.",
      };
    }

    return data;
  };

  validateCountryCode = (text) => {
    text = text.trim();
    let data = { error_country_code: null };

    if (text === "code") {
      data = {
        error_country_code: "Please enter the Country Code to proceed.",
      };
      return data;
    }

    return data;
  };

  static validateEmail = (text, setEmail) => {
    text = text.trim();
    let length = text.length;

    if (length === 0) {
      setEmail("Email field is required.");
      return false;
    }

    if (length > 256) {
      setEmail("Email not more than 256 character.");
      return false;
    }

    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        text
      ) !== true
    ) {
      setEmail("Invalid Email.");
      return false;
    }

    setEmail("");
    return true;
  };

  static validateFullName = (text, setErrorFullName) => {
    text = text.trim();
    let length = text.length;

    if (length === 0) {
      setErrorFullName("Name field is required.");
      return false;
    }

    if (length < 3) {
      setErrorFullName("Name must be 3 character long.");
      return false;
    }

    if (length > 30) {
      setErrorFullName("Name not more than 30 character.");
      return false;
    }

    if (/^[a-zA-Z ]*$/.test(text) !== true) {
      setErrorFullName("Name must contain alphabet.");
      return false;
    }

    setErrorFullName("");
    return true;
  };

  static validateMobileNumber = (text, setErrorMobile) => {
    text = text.trim();
    let length = text.length;

    if (length === 0) {
      setErrorMobile("Mobile number field is required.");
      return false;
    }

    if (/^\d+$/.test(text) !== true) {
      setErrorMobile("Mobile number must contain digit.");
      return false;
    }

    // if (length !== 10) {
    //   setErrorMobile("Mobile number must be 10 digit.");
    //   return false;
    // }

    // return data;
    setErrorMobile("");
    return true;
  };

  static validateInput = (text, setInput) => {
    text = text.trim();
    let length = text.length;

    if (length === 0) {
      setInput("Enter Room Name");
      return false;
    }

    if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(text) === true) {
      setInput("Meeting Name Should not contains special characters");
      return false;
    }

    if (length <= 3) {
      setInput("Room name must be 4 character long");
      return false;
    }

    setInput("");
    return true;
  };
}

export default Validation;
