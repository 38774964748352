import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import firebase from "firebase";
import SignIn from "../pages/signin";
import ResetPassword from "../pages/reset_password";
import Signup from "../pages/signup";
import Phone from "../pages/phone";
import PrivateRoute from "../component/auth/private_route";
import TermsAndConditions from "../pages/terms_and_condition/index";
import PrivacyPolicy from "../pages/privacy_policy/index";
// import NotificationCom from '../component/NotificationComp'
import VoiceCallModal from "../component/chat/Modals/VoiceCallModal";
import Cookies from "../services/cookies";
import { w3cwebsocket as W3CWebSocket } from "websocket";

import Chats from "../component/chat/Chats";




import { io } from "socket.io-client";

const client = new W3CWebSocket('wss://hfresh.co:1337');

const socket = io("https://hfresh.co:3111/");

// import First_ui from '../component/Ui_Components/First_ui';
// import { Collapse } from 'bootstrap';
// import Collapse from '../component/Ui_Components/Collapse'

const browser = () => {
  const test = function (regexp) {
    return regexp.test(window.navigator.userAgent);
  };
  switch (true) {
    case test(/edg/i):
      return "Edge";
    case test(/trident/i):
      return "MIE";
    case test(/firefox|fxios/i):
      return "Firefox";
    case test(/opr\//i):
      return "Opera";
    case test(/ucbrowser/i):
      return "UC";
    case test(/samsungbrowser/i):
      return "Samsung";
    case test(/chrome|chromium|crios/i):
      return "Chrome";
    case test(/safari/i):
      return "Safari";
    default:
      return "Other";
  }
};

const App = () => {
  const cookies = new Cookies();
  
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [notiData, setNotiData] = useState(null);
  const [safariNotiData, setSafariNotiData] = useState(null);
  const messaging = getMsg();

  function getMsg() {
    if (browser() !== "Safari") {
      return firebase.messaging();
    } else {
      console.log("browser  -- - - - - ->", browser());
      return null;
    }
  }

  useEffect(() => {
    client.onopen = (e) => {
      console.log("Connected");
      console.log(
        "WebSocket Client Connected",
        client.readyState,
        W3CWebSocket.OPEN
      );
      if (client.readyState === W3CWebSocket.OPEN) {
        // client.send(JSON.stringify(e));
      }
    };

    client.onmessage = (message) => {
      if (message.type === 'message') {
        console.log("Message received: ", JSON.parse(message.data).data) 
        let pa = JSON.parse(message.data).data
        // console.log("Message received: ", pa) 
        // console.log("Message received: type checking - - - - ", typeof pa.length) 
        if(typeof pa.length !== 'number' && (browser() === "Safari" || browser() === "safari")){
        // if(typeof pa.length !== 'number' ){
          let data = JSON.parse(pa.text)
          // console.log("json 0 0 0 0 - - - -- ",data)
          showModalSafari(data);
        }
      }
    };

    client.onclose = (e) => {
      console.log("Disconnected: " + e.reason);
    };

    client.onerror = () => {
      console.log("Connection Error");
    };
    
    
  },[client])

  const showModalSafari = (data) => {
    // console.log("showModalSafari",data.fcm, data.fcm === cookies.getItem("fcm_token"))
    // if(data.fcm === cookies.getItem("fcm_token")){
    if (data.fcm === cookies.getItem("fcm_token")) {
      // console.log("cheking for minimux and viisible   - -- -", document.hidden)
      if (document.hidden) {
        notifyMeSafari();
      }
      setSafariNotiData(data);
      setShow(true);
    }
  };

  function notifyMeSafari() {
    if (Notification.permission !== "granted") Notification.requestPermission();
    else {
      var notification = new Notification("SwitchTell", {
        body: "Video Conference Call",
      });
      notification.onclick = function () {
        console.log(".");
      };
    }
  }

  if (browser() !== "Safari") {
    messaging
      ?.getToken(messaging, {
        vapidKey:
          "AAAAbR4XOE0:APA91bFd9MWAto33xxg1sjMgH1gfb7Ja0FUhZMeTS6OYd8Bw96WEPAL5XfMp-r1zFK-PTYGD-qKm1Eg5mgD5rnc3PxHxhzM1Ff1ni4YB6wee5FIDMDaGnH7yurkmdDWe7rzIpE37TPFA",
      })
      .then((currentToken) => {
        // console.log("app currentToken===>", currentToken);
      })
      .catch((err) => {
        console.log("app error fcm =====>", err);
      });
  } else {
  }

  const notifyMe = () => {
    window.Notification.requestPermission().then(function (status) {
      if (status === "granted") {
      } else if (status !== "denied") {
        setTimeout(() => {
          alert("Please allow the notification");
        }, 300);
      } else if (status === "denied") {
        setTimeout(() => {
          alert("Please allow the notification");
        }, 300);
      }
      console.log("Perm granted", status);
    });
  };

  const notifyMeSafariNoti = () => {
    if (Notification.permission === "granted") {
    } else if (Notification.permission !== "denied") {
      setTimeout(() => {
        alert("Please allow the notification");
      }, 300);
    } else if (Notification.permission === "denied") {
      setTimeout(() => {
        alert("Please allow the notification");
      }, 300);
    }
  };

  useEffect(() => {
    if (browser() !== "Safari") {
      notifyMe();
    } else if (browser() === "Safari") {
      notifyMeSafariNoti();
    }
  }, [window.Notification.permission]);

  useEffect(() => {
    if (browser() !== "Safari") {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker
          .register("./firebase-messaging-sw.js")
          .then(function (registration) {
            console.log(
              "Registration successful, scope is:",
              registration.scope
            );
            console.log(
              "Registration successful, registration:",
              firebase.messaging.onMessageListener
            );
          })
          .catch(function (err) {
            console.log("Service worker registration failed, error:", err);
          });
        // navigator.serviceWorker.addEventListener('message',(event)=>console.log('event - - -recideiv ->',event))
      }
    }
  }, []);

  const onMessageListener = () =>
    new Promise((resolve) => {
      messaging.onMessage((payload) => {
        resolve(payload);
      });
    });
  if (browser() !== "Safari") {
    onMessageListener()
      .then((payload) => {
        console.log("live notification payload - - - - ", payload);
        setNotiData(payload);
        // number=payload.data['gcm.notification.room_id']
        setShow(true);
        setNotification({
          title: payload.notification.title,
          body: payload.notification.body,
        });
        console.log(payload);
      })
      .catch((err) => console.log("onMessageListener failed: ", err));

    const receiver = new BroadcastChannel("sw-messages");
    receiver.addEventListener("message", function eventListener(event) {
      const notiBgData = JSON.parse(event?.data?.message);
      // console.log("bg - event  - - -->",notiBgData)
      setShow(true);
      setNotiData(notiBgData);
      // window.location.reload();
    });
    
  }

  useEffect(() => {
    if (cookies.getItem("payload")) {
      setShow(true);
      setNotiData(JSON.parse(cookies.getItem("payload")));
    }
  }, [cookies.getItem("payload")]);

  useEffect(() => {
    if (show) {
      cookies.removeItem("payload");
    }
  }, [show]);

  return (
    <>
      {/* <NotificationCom/> */}
      <BrowserRouter>
        {notiData &&  notiData?.data["gcm.notification.room_id"] && (
          <VoiceCallModal
            show={show}
            number={notiData?.data["gcm.notification.room_id"]}
            isVideo={JSON.parse(notiData?.data["isVideo"])}
            setShow={setShow}
          />
        )}

        {safariNotiData && (
          <VoiceCallModal
            show={show}
            number={safariNotiData["userIdentifier"]}
            setShow={setShow}
            isVideo={safariNotiData["isVideo"]}
          />
        )}

        <Switch>
          <Route exact path="/sign-in">
            <SignIn />
          </Route>
          <Route exact path="/reset-password">
            <ResetPassword />
          </Route>
          <Route exact path="/additional-info">
            <Signup />
          </Route>
          <Route exact path="/sign-up">
            <Phone />
          </Route>
          <Route exact path="/terms-and-conditions">
            <TermsAndConditions />
          </Route>
          <Route exact path="/privacy-policy">
            <PrivacyPolicy  />
          </Route>
          {/* <Route exact path='/ui' component={First_ui}/>
              <Route exact path='/ui1' component={Collapse}/> */}

          <PrivateRoute exact path="/">
            <Chats client={client} socket={socket} />
          </PrivateRoute>
          
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default App;
