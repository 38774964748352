import React, { useEffect, useState } from 'react';
import './Style.css';
import {
    Modal,
    ModalBody,
    TabContent,
    TabPane,
    Button,
    Form,
    ModalHeader,
} from 'reactstrap'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CallIcon from '@material-ui/icons/Call';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { virtualNumbers } from '../../../Store/Actions/VirtualNumbers/VirtualNumbersFetch';
import { subscribeVirtualNumber } from '../../../Store/Actions/Subscribe/subscribeVirtualNumber';
import { errorInDataSaving, subscriptionInfoToast } from '../../Ui_Components/Toasts';
import { ToastContainer } from 'react-toastify';

function RentModal(props) {
    const [value, setValue] = React.useState('');
    const [rate, setRate] = useState(null)           // rate of the virtual number
    const [selectedCountry, setselectedCountry] = useState('')
    const virtualNumber = useSelector(state => state.virtualNumber)
    const { editProfile } = useSelector(state => state)
    const [credit, setCredit] = useState(null)    //balance credit of user
    const [virtualNumberData, setvirtualNumberData] = useState()
    const [unique, setunique] = useState()
    const [numbers, setnumbers] = useState()
    const [activeTab, setActiveTab] = useState('1');
    const dispatch = useDispatch()
    const [searchtext, setsearchtext] = useState('')


    useEffect(() => {
        if (editProfile.UserData.payload) {
            setCredit(parseFloat(editProfile.UserData.payload.data[0].credit));
        }
    }, [editProfile])

    const handleValueChange = (event) => {
        setValue(event.target.value);
    };

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const handleChange = (country) => {
        setselectedCountry(country)
        if (numbers) { numbers.length === 1 ? toggle('3') : toggle('2') }
        setsearchtext('')
    }

    const SubscribeNumber = (e) => {
        if (value) {
            dispatch(subscribeVirtualNumber(value))
                .then((response) => {
                    subscriptionInfoToast('Virtual Number Is Subscribed');       //toast id for one time popup
                    window.location.reload();
                })
                .catch(err => errorInDataSaving('something went wrong'))
        }
        e.preventDefault();
    }

    const updateSearch = (e) => {
        e.preventDefault()
        setsearchtext(e.target.value)
    }

    const apiVirtualNumbers = () => {
        dispatch(virtualNumbers())
    }

    useEffect(() => {
        if (props.modal) { apiVirtualNumbers() }
        else { toggle('1') }
    }, [props.modal])

    useEffect(() => {
        setnumbers(virtualNumberData && virtualNumberData.filter((country) => { return country.countryname === selectedCountry }))
    }, [selectedCountry])

    useEffect(() => {
        if (numbers) {
            numbers[0] && setValue(numbers[0].did)
            if (numbers) { numbers.length === 1 ? toggle('3') : toggle('2') }

        }
    }, [numbers])

    useEffect(() => {
        if (virtualNumber.VirtualNumberData.payload) {
            setvirtualNumberData(virtualNumber.VirtualNumberData.payload.data)
            setunique(searchtext === 0 ? [...new Set(virtualNumber.VirtualNumberData.payload.data.map(item => item.countryname))] : [...new Set(virtualNumber.VirtualNumberData.payload.data.filter(country => country.countryname.toLowerCase().includes(searchtext.toLowerCase())).map(item => item.countryname))]);
        }
    }, [virtualNumber, searchtext])

    useEffect(() => {
        if (virtualNumber.VirtualNumberData.payload) {
            const rate = virtualNumber.VirtualNumberData.payload.data.filter((item) => value === item.did)
            setRate(rate)
        }
    }, [value]);

    // const [isOpenDiv, setIsOpenDiv] = useState(false);
    // const toggleDiv = () => setIsOpenDiv(!isOpenDiv);

    return (
        <Modal isOpen={props.modal} toggle={props.toggle} centered className="modal-dialog-zoom">
            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover

            />
            <ModalHeader toggle={props.toggle} className='mdl_header'>
                <IconButton onClick={() => { activeTab === '2' && toggle('1'); activeTab === '3' && numbers.length > 1 ? toggle('2') : toggle('1') }} style={{ outline: 'none', color: 'white' }}>
                    < ArrowBackIosIcon className="mr-2" />
                </IconButton>
                Rent / Subscribe...
            </ModalHeader>
            <ModalBody className="modal_body">
                <Form onSubmit={e => { e.preventDefault() }}>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1" className="rent_modal">
                            <div className='main'>
                                <h5 id="h5" className="text-center " >Select country for buy your new local </h5>
                                <h5 id="h5" className="text-center " >switchtell number.</h5>
                            </div>
                            <div id="searchbox">
                                <div className="input-icons d-flex justify-content-center">
                                    <input id="input_field" placeholder="Search Country" value={searchtext} className="input-field mr-4" type="search" onChange={e => updateSearch(e)} onFocus={e => { e.target.placeholder = '' }} onBlur={e => { e.target.placeholder = 'Search Country' }} />
                                    <i className="fa fa-search icon" id='input_text' />
                                </div>
                            </div>
                            <div className="p-3 text-center mx-auto" id="country_item">
                                <ul className="list-group list-group-flush  " id="list_text">
                                    {unique && unique.map((country, index) => {
                                        return <li key={index} className="list-group-item" style={{ cursor: "pointer" }} onClick={() => { handleChange(country) }}>{country}</li>
                                    })}
                                </ul>
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                            <div className='main'>
                                <h5 id="h5" className="text-center " >Here is your local number </h5>
                                <h5 id="h5" className="text-center " >{selectedCountry}</h5>
                            </div>
                            <div className='pt-4' >
                                <h5 className="text-center" id="number"> {value} </h5>
                            </div>
                            <div className='ml-1'>
                                <Button id="creditt" onClick={(e) => { e.preventDefault(); toggle('3') }} color="primary"><span className="mx-auto"> CONTINUE </span></Button>
                            </div>
                            <div className="mx-auto text-center" id="accordian">
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header">
                                        <Typography component={'span'}>
                                            <div className="d-inline-flex">
                                                <CallIcon />
                                                <div>
                                                    <h5 className="numbert text-center ml-5">Show Other local Number for</h5>
                                                    <h5 className="numbert text-center ml-5">{selectedCountry}</h5>
                                                </div>
                                            </div>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={'div'}>
                                            <div className="p-3 mx-auto">
                                                <FormControl >
                                                    <RadioGroup aria-label="" name="" value={value} onChange={handleValueChange} className="radio">
                                                        {numbers && numbers.map((element) => { return <FormControlLabel key={element.did} value={element.did} control={<Radio />} label={element.did} /> })}
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </TabPane>
                        <TabPane tabId="3" className="rent_modal">
                            <div className='main'>
                                <h5 id="h5" className="text-center " >Here is your local number </h5>
                                <h5 id="h5" className="text-center " >{selectedCountry}</h5>
                            </div>
                            <div className='pt-4' >
                                <h5 className="text-center" id="number"> {value} </h5>
                            </div>
                            <div className='ml-1'>
                                {rate && rate[0]?.fixrate < credit ? <Button id="creditt" onClick={(e) => { SubscribeNumber(e) }} outline color="primary"><span className="mx-auto"> SUBSCRIBE </span></Button> :
                                    <h6 className="text-center mt-3">Your Credit Balance is insufficient. Please recharge first</h6>}
                            </div>
                        </TabPane>
                    </TabContent>
                </Form>
            </ModalBody>
        </Modal>
    )
}

export default RentModal
